<script setup lang="ts">
import { computed, defineCustomElement, h, ref, watch } from 'vue'
import { fetchOneEntry, Content } from '@builder.io/sdk-vue/vue3'
import '@builder.io/sdk-vue/vue3/css'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import ENV from '@/constants/env'

interface Props {
    apiKey?: string
    path?: string
}

const props = withDefaults(defineProps<Props>(), {
    apiKey: ENV.builderIoApiKey,
    path: window.location.pathname
})

const isFetching = ref(false)
const isContentReady = computed(() => !isFetching.value && content.value)
// Builder.io doesn't provide types
// eslint-disable-next-line
const content = ref<any>(null)

const fetchContent = async (urlPath: string) => {
    isFetching.value = true

    try {
        const res = await fetchOneEntry({
            model: 'page',
            apiKey: props.apiKey,
            userAttributes: { urlPath }
        })

        content.value = res
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Error Loading Builder.io Content', err)
    }

    isFetching.value = false
}

const BuilderContentElement = defineCustomElement({
    props: {
        content: Object,
        apiKey: String
    },
    render() {
        return h(Content, {
            model: 'page',
            content: this.content,
            apiKey: this.apiKey,
            class: 'builder-content'
        })
    },
    shadow: true // Enable shadow DOM so styles don't conflict
})

if (!customElements.get('builder-content-element')) {
    customElements.define('builder-content-element', BuilderContentElement)
}

watch(() => props.path, fetchContent, { immediate: true })
</script>

<template>
    <div>
        <LoadingSpinner
            v-if="isFetching"
            class="!my-16 mx-auto h-16" />
        <builder-content-element
            v-else-if="isContentReady"
            :content="content"
            :api-key="apiKey" />
        <slot v-else />
    </div>
</template>
