import showEmailEditor from '@/functions/emailEditor'

export default showEmailEditor()
    ? [
          {
              path: `/tags/view/:tagId`,
              component: () => import('@/pages/tags/index.vue')
          }
      ]
    : []
