<script setup lang="ts">
import { PhX } from '@phosphor-icons/vue'
import { SpButton } from '@tithely/sproutkit-vue'

const emit = defineEmits<{
    close: [void]
}>()
</script>

<template>
    <SpButton
        intent="secondary"
        variant="text"
        icon
        class="hover:shadow-none"
        @click="emit(`close`)">
        <PhX
            weight="bold"
            class="text-xl" />
    </SpButton>
</template>
