import { QUERY_KEYS } from '@/constants/query'
import type { EchoEventConfig } from '@/types/composables/echo'
import type { Ref } from 'vue'

const organizationChannelConfig: EchoEventConfig[] = [
    {
        event: `Messaging\\MessageTemplateCreated`,
        clearQueries: [QUERY_KEYS.messageTemplates]
    },
    {
        event: `Messaging\\MessageTemplateUpdated`,
        clearQueries: [QUERY_KEYS.messageTemplates]
    },
    {
        event: `Features\\FeatureToggleToggled`,
        clearQueries: [QUERY_KEYS.me],
        action: (payload: Record<string, Ref<number>>) => {
            if (payload.integration_id) {
                return [QUERY_KEYS.extensions, QUERY_KEYS.extension(payload.integration_id)]
            }
        }
    },
    {
        event: 'Organization\\OrganizationUpdated',
        clearQueries: [QUERY_KEYS.me]
    }
]

export default organizationChannelConfig
