import ResizeObserver from 'resize-observer-polyfill'
import useUtils from '@/store/utils'
import type { Nullable } from '@/types/utility'
import type { Breakpoint } from '@/types/plugins/utils'

export default () => {
    const utils = useUtils()

    const resize = new ResizeObserver(() => {
        const width = document.body.clientWidth

        const getBreakpoint = (): Nullable<Breakpoint> => {
            switch (true) {
                case width < 576:
                    return null
                case width < 768:
                    return `sm`
                case width < 992:
                    return `md`
                case width < 1200:
                    return `lg`
                default:
                    return `xl`
            }
        }

        if (getBreakpoint() !== utils.breakpoint) {
            utils.setBreakpoint(getBreakpoint())
        }
    })

    resize.observe(document.body)
}
