/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateDemoOrganizationRequest } from '../models/CreateDemoOrganizationRequest';
import type { CreateStandardOrganizationRequest } from '../models/CreateStandardOrganizationRequest';
import type { OrganizationInfoResource } from '../models/OrganizationInfoResource';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OrganizationsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create a new organization.
     * @returns any Created
     * @throws ApiError
     */
    public fb227Aa9E598E8B59176C48Ebea3D102({
        requestBody,
    }: {
        requestBody: (CreateStandardOrganizationRequest | CreateDemoOrganizationRequest | {
            is_demo: boolean;
        }),
    }): CancelablePromise<{
        subdomain: string;
        username: string;
        /**
         * One-time login token. Only returned for normal organizations.
         */
        token: string;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/organizations',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
            },
        });
    }

    /**
     * Cancel your subscription.
     * @returns void
     * @throws ApiError
     */
    public cancelSubscription({
        reason,
        reasonFeatures,
        reasonFeaturesComment,
        comment,
        switchedTo,
        switchedToReason,
    }: {
        /**
         * Reason for cancellation
         */
        reason: string,
        /**
         * Reason for cancellation due to missing features
         */
        reasonFeatures: string,
        /**
         * Comment about cancellation due to missing features
         */
        reasonFeaturesComment: string,
        /**
         * Additional comments
         */
        comment: string,
        /**
         * ChMS switched to
         */
        switchedTo?: string,
        /**
         * Reason ChMS was switched to
         */
        switchedToReason?: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/organizations',
            query: {
                'reason': reason,
                'reason_features': reasonFeatures,
                'reason_features_comment': reasonFeaturesComment,
                'comment': comment,
                'switched_to': switchedTo,
                'switched_to_reason': switchedToReason,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Get very basic organization information.
     * @returns any OK
     * @throws ApiError
     */
    public a8F92D3B95Ac2Ed2C7F3B9010334953A({
        subdomain,
    }: {
        /**
         * Subdomain used by an organization
         */
        subdomain: string,
    }): CancelablePromise<{
        data: OrganizationInfoResource;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/organizations/info/{subdomain}',
            path: {
                'subdomain': subdomain,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }

}
