<script setup lang="ts">
import { cva } from 'class-variance-authority'
import type { SelectOptions } from '@/types/forms'

withDefaults(
    defineProps<{
        options?: SelectOptions
        size?: `sm` | `md` | `lg`
        block?: boolean
    }>(),
    {
        options: () => [],
        size: `md`,
        block: false
    }
)

const model = defineModel<null | undefined | string | number | boolean>({ default: null })

const selectClasses = cva(
    [
        `border`,
        `border-secondary-200`,
        `rounded`,
        `text-secondary-600`,
        `bg-white`,
        `font-normal`,
        `leading-none`,
        `outline-none`,
        `transition-all`,
        `focus:border-primary`,
        `focus:shadow-md`,
        `disabled:bg-secondary-50`,
        `disabled:opacity-50`
    ],
    {
        variants: {
            size: {
                sm: [`!h-8`, `!p-1.5`, `text-sm`],
                md: [`!h-9`, `!p-2`, `text-base`],
                lg: [`!h-10`, `!p-2.5`, `text-lg`]
            },
            block: {
                true: [`flex`, `w-full`],
                false: [`inline-flex`]
            }
        }
    }
)
</script>

<template>
    <select
        v-model="model"
        :class="selectClasses({ block, size })">
        <template v-for="(option, index) in options">
            <optgroup
                v-if="option.children"
                :key="`${index}`"
                :label="option.label">
                <option
                    v-for="(childOption, childIndex) in option.children"
                    :key="`${childIndex}`"
                    :value="childOption.value"
                    :disabled="childOption.disabled">
                    {{ childOption.label }}
                </option>
            </optgroup>
            <option
                v-else
                :key="`op-${index}`"
                :value="option.value"
                :disabled="option.disabled">
                {{ option.label }}
            </option>
        </template>
    </select>
</template>
