<script setup lang="ts">
import { useCookies } from '@vueuse/integrations/useCookies'
import { computed } from 'vue'
import { useQueryClient } from '@tanstack/vue-query'
import BIcon from '@/components/BIcon.vue'
import { COOKIE_OPTIONS } from '@/constants/cookies'

const cookies = useCookies()
const query = useQueryClient()

const uiCookie = computed(() => parseInt(cookies.get('ui_pr')) || false)
const apiCookie = computed(() => parseInt(cookies.get('api_pr')) || false)

const clearUICookie = () => {
    cookies.remove('ui_pr', COOKIE_OPTIONS)
    query.clear()
    location.reload()
}

const clearAPICookie = () => {
    cookies.remove('api_pr', COOKIE_OPTIONS)
    query.invalidateQueries()
}
</script>

<template>
    <div
        v-if="uiCookie || apiCookie"
        class="absolute left-0 top-0 z-40 h-16 w-16">
        <div
            class="absolute right-[-68px] top-8 w-[200px] origin-top -rotate-45 transform bg-yellow-600 py-1 text-center font-semibold text-white opacity-90">
            <template v-if="uiCookie">
                <span>UI PR-{{ uiCookie }}</span>
                <BIcon
                    class="ml-1 inline w-4 cursor-pointer"
                    title="Exit UI pull request"
                    icon="cancel"
                    @click="clearUICookie" />
            </template>
            <br v-if="uiCookie && apiCookie" />
            <template v-if="apiCookie">
                <span>API PR-{{ apiCookie }}</span>
                <BIcon
                    class="ml-1 inline w-4 cursor-pointer"
                    title="Exit API pull request"
                    icon="cancel"
                    @click="clearAPICookie" />
            </template>
        </div>
    </div>
</template>
