/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Country } from './Country';
import type { OrganizationStatus } from './OrganizationStatus';

export type OrganizationUser = {
    /**
     * The name of the organization.
     */
    name?: string;
    /**
     * Subdomain used to access the organization's website.
     */
    subdomain?: string;
    country_id?: number;
    language?: OrganizationUser.language;
    status?: OrganizationStatus;
    customer_id?: string;
    created_on?: string;
    country?: Country;
};

export namespace OrganizationUser {

    export enum language {
        ENGLISH = 'english',
    }


}

