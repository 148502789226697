import { useQuery } from '@tanstack/vue-query'
import { plainToInstance } from 'class-transformer'
import useApi from '@/composables/api'
import { QUERY_KEYS } from '@/constants/query'
import { getSubdomain } from '@/functions/utils'
import LoginConfigResponse from '@/services/api/transformers/LoginConfigResponse'

const useLoginConfig = () => {
    const subdomain = getSubdomain()
    const { api } = useApi()

    return useQuery({
        queryKey: QUERY_KEYS.loginConfig(subdomain),
        queryFn: () =>
            api.authorization
                .getAuthConfig({
                    subdomain
                })
                .then(({ data }) => data),
        select: data => plainToInstance(LoginConfigResponse, data),
        retry: false
    })
}

export default useLoginConfig
