import sha1 from 'sha-1'
import axios from 'axios'
import { includes } from 'lodash'

const checkSafePassword = async (password: string) => {
    const hash = sha1(password)

    const { data } = await axios({
        method: `GET`,
        url: `https://api.pwnedpasswords.com/range/${hash.substring(0, 5)}`
    })

    return !includes(data, hash.substring(5).toUpperCase())
}

export { checkSafePassword }
