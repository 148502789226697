/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AutoReportingRequestBase } from '../models/AutoReportingRequestBase';
import type { PreferenceResource } from '../models/PreferenceResource';
import type { StoreAutoReportingRequest } from '../models/StoreAutoReportingRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SongLibraryAutoReportingService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Retrieve a preference with full details.
     * @returns any OK
     * @throws ApiError
     */
    public getAutoReportings(): CancelablePromise<{
        data: PreferenceResource;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/song-library/auto-reporting',
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Create a new auto reporting preference.
     * @returns any Created
     * @throws ApiError
     */
    public setAutoReporting({
        requestBody,
    }: {
        /**
         * Auto reporting details
         */
        requestBody: StoreAutoReportingRequest,
    }): CancelablePromise<{
        data: PreferenceResource;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/song-library/auto-reporting',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Retrieve a preference with full details.
     * @returns any OK
     * @throws ApiError
     */
    public getAutoReporting({
        id,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
    }): CancelablePromise<{
        data: PreferenceResource;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/song-library/auto-reporting/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Update an existing auto reporting preference.
     * @returns any OK
     * @throws ApiError
     */
    public patchAutoReporting({
        id,
        requestBody,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
        /**
         * Auto reporting details
         */
        requestBody: AutoReportingRequestBase,
    }): CancelablePromise<{
        data: PreferenceResource;
    }> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/song-library/auto-reporting/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

}
