import { ref, watch } from 'vue'
import { stringify } from 'qs'
import { map, forEach, debounce } from 'lodash'
import { addDays, format, subDays } from 'date-fns'
import useApi from '@/composables/api'

const CANCEL_TOKENS = {
    people: `ZEPHYR:CANCEL:SEARCH_PEOPLE`,
    tags: `ZEPHYR:CANCEL:SEARCH_TAGS`,
    events: `ZEPHYR:CANCEL:SEARCH_EVENTS`
}

const useSearch = () => {
    const { call, cancelCall } = useApi()

    const query = ref(``)
    const searching = ref(false)
    const showResults = ref(false)
    const people = ref<any[]>([])
    const tags = ref<any[]>([])
    const events = ref<any[]>([])

    const debounceSearch = debounce(() => {
        searching.value = true
        showResults.value = true

        forEach(CANCEL_TOKENS, cancelCall)

        return Promise.allSettled([fetchPeople(), fetchTags(), fetchEvents()])
            .then(results => {
                forEach(results, result => {
                    if (result.status === `fulfilled`) {
                        switch (result.value.object) {
                            case 'people':
                                people.value = map(result.value.data, person => ({
                                    id: person.id,
                                    name: person.person_details?.name,
                                    avatar: person.person_details?.profile_picture
                                }))
                                break
                            case 'tags':
                                tags.value = map(result.value.data, tag => ({
                                    id: tag.id,
                                    name: tag.name
                                }))
                                break
                            case 'events':
                                events.value = map(result.value.data, event => ({
                                    id: event.id,
                                    name: event.name,
                                    date: event.start
                                }))
                                break
                        }

                        searching.value = false
                    }
                })
            })
            .catch(error => {
                if (error?.message === `canceled`) {
                    return Promise.resolve()
                }
            })
    }, 500)

    watch(query, () => {
        if (query.value.length > 1) {
            debounceSearch()
        }

        if (!query.value.length) {
            reset()
        }
    })

    const fetchPeople = () =>
        call(
            `GET`,
            `/people?${stringify(
                {
                    filter: {
                        'name:name_filter': query.value,
                        'is_archived:eq:boolean': false
                    },
                    sort: `[formalName:asc]`,
                    personNameFormat: `natural`
                },
                { encode: false }
            )}`,
            null,
            { cancel: CANCEL_TOKENS.people }
        )

    const fetchTags = () =>
        call(
            `GET`,
            `/tags?${stringify(
                {
                    filter: {
                        'name:contains:text': query.value
                    },
                    sort: `[name:asc]`
                },
                { encode: false }
            )}`,
            null,
            { cancel: CANCEL_TOKENS.tags }
        ).then(tagsResponses => ({
            ...tagsResponses,
            object: `tags`
        }))

    const fetchEvents = () =>
        call(
            `GET`,
            `/event-instances?${stringify(
                {
                    filter: {
                        'name:contains:text': query.value,
                        'start_datetime:gt:date': format(subDays(new Date(), 8), `yyyy-MM-dd`),
                        'start_datetime:lt:date': format(addDays(new Date(), 16), `yyyy-MM-dd`)
                    },
                    sort: `[start_datetime:asc]`,
                    pageSize: 8
                },
                { encode: false }
            )}`,
            null,
            { cancel: CANCEL_TOKENS.events }
        ).then(eventsResponse => ({
            ...eventsResponse,
            object: `events`
        }))

    const reset = () => {
        query.value = ``
        searching.value = false
        showResults.value = false
        people.value = []
        tags.value = []
        events.value = []
    }

    return {
        query,
        searching,
        showResults,
        people,
        tags,
        events,
        reset
    }
}

export default useSearch
