/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TithelyAuthService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns any OK
     * @throws ApiError
     */
    public tithelySso({
        redirect,
    }: {
        /**
         * Relative URL to redirect to after login
         */
        redirect?: string,
    }): CancelablePromise<{
        data: {
            /**
             * Link to redirect to
             */
            link?: string;
        };
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/tithely/sso',
            query: {
                'redirect': redirect,
            },
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

}
