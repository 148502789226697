import type { EchoEventConfig } from '@/types/composables/echo'

const globalChannelConfig: EchoEventConfig[] = [
    {
        event: `Features\\FeatureUpdated`
        // todo: add action
    }
]

export default globalChannelConfig
