/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ServicePlanTemplateResourceOutline } from './ServicePlanTemplateResourceOutline';

export type ServicePlanTemplateResource = {
    id?: number;
    name?: string;
    created_at?: string;
    type?: ServicePlanTemplateResource.type;
    segments?: Array<ServicePlanTemplateResourceOutline>;
};

export namespace ServicePlanTemplateResource {

    export enum type {
        ORGANIZATION = 'organization',
        PUBLIC = 'public',
    }


}

