<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'
import { SpButton } from '@tithely/sproutkit-vue'
import useExtensions from '@/queries/extensions'
import useExtension from '@/queries/extension'
import { useMe } from '@/queries/me'
import BuilderContent from '@/components/BuilderContent.vue'
import BaseModal from '@/components/base/BaseModal.vue'
import BaseModalHeader from '@/components/base/BaseModalHeader.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import type { Nullable } from '@/types/utility'
import type IntegrationResource from '@/services/api/transformers/IntegrationResource'
import useConfirmModal from '@/composables/confirmModal'
import { integrationDetailsMap } from '@/constants/integrations'
import useTithelySSO from '@/composables/sso'
import { useTithelyBundle } from '@/queries/tithely'
import { includes } from 'lodash'

const { redirect } = useTithelySSO()
const router = useRouter()

const { data: meData, hasFeature } = useMe()
const extensionId = ref<number>(0)
const { data: extensions } = useExtensions()
const { data: extension, isLoading: isExtensionLoading } = useExtension(extensionId)
const { showBundleOffer } = useTithelyBundle()
const toast = useToast()
const loadingStatus = ref(false)

const modalDetails = ref<Nullable<InstanceType<typeof BaseModal>>>(null)

interface Props {
    isDisabled: boolean
}

const props = defineProps<Props>()

const selected = computed(() => {
    return extensions.value?.find(ext => ext.id === extensionId.value)
})
const actionText = computed(() => {
    return integrationDetailsMap[extension.value?.name as keyof typeof integrationDetailsMap] || `Install`
})

const isAccountOwner = computed(() => meData.value?.isPrimary)
const isUnableToPurchase = computed(() => !isAccountOwner.value && selected.value?.tithely && !selected.value?.enabled)
const isAccountLinked = computed(
    () => meData.value?.organization.tithelyOrganizationId || meData.value?.organization.tithelyBilling
)

const isSecondaryButtonVisible = computed(
    () => selected.value && (!selected.value.tithely || showBundleOffer.value || selected.value.enabled)
)
const isDisabled = computed(
    () => (!isAccountLinked.value && !selected.value?.enabled) || props.isDisabled || isUnableToPurchase.value
)

// Install clicked from card or details modal
const open = (extensionResource: IntegrationResource) => {
    extensionId.value = extensionResource.id
    modalDetails.value?.open()
}

const secondaryVariant = computed(() => isSecondaryButtonVisible.value ? `subtle` : `default`)
const secondaryIntent = computed(() => isSecondaryButtonVisible.value ? `secondary` : `primary`)

const close = () => modalDetails.value?.close()

defineExpose({
    open,
    close
})

const emit = defineEmits<{
    bundle: [void]
    install: [IntegrationResource]
    uninstall: [IntegrationResource]
    installTithely: [IntegrationResource]
    manage: [IntegrationResource]
}>()

const primaryDetailAction = async () => {
    if (!selected.value?.enabled && (!selected.value || !extension.value)) {
        return toast.error(`Failed to load extension`)
    }

    if (!selected.value?.enabled) {
        // This is a Tithe.ly integration, kick off flow
        if (selected.value?.tithely) {
            // Check if a user is the account owner, and if they are, do not allow to proceed.
            if (!isAccountOwner.value) {
                modalDetails.value?.close()
                loadingStatus.value = true
                return useConfirmModal({
                    size: `md`,
                    title: `Account Owner Only`,
                    confirmText: `Only the Breeze Account Owner can add or link ${selected.value?.displayName}. Please contact them to install it.`,
                    onConfirm: () => {
                        loadingStatus.value = false
                    }
                }).open()
            } else {
                modalDetails.value?.close()
                loadingStatus.value = false
                return emit('bundle')
            }
        }
        // Install non-tithely extensions that do not require users to be account owner.
        return emit('install', selected.value)
    } else {
        //If already installed, redirect
        switch (selected.value?.name) {
            case 'sites_integration':
                await redirect(`/sites`)
                break
            case 'church_apps_integration':
                await redirect(`/apps`)
                break
            case 'service_plan_integration':
                router.push(`/songs/`)
                break
            default:
                window.open(
                    `${window.location.origin}/extensions/${selected.value?.name?.replace(`_integration`, ``)}`,
                    `_self`
                )
        }
    }
}

const purchaseOrUninstall = async () => {
    switch (true) {
        case selected.value?.tithely && !selected.value?.enabled:
            return emit('installTithely', selected.value)
        case selected.value?.tithely && selected.value?.enabled:
            return emit('manage', selected.value)
        case selected.value !== undefined:
            return emit('uninstall', selected.value)
    }
}

const detailsActionText = computed(() => {
    switch (true) {
        case isExtensionLoading.value:
            return `Loading...`
        case !selected.value:
            return
        // tithely account must be connected to install
        // if already installed, allow access
        case !isAccountLinked.value && !selected.value?.enabled:
            return `Enable Account`
        case !selected.value?.tithely && !selected.value?.enabled:
            return `Install`
        case isUnableToPurchase.value:
            return `Contact Account Owner to Purchase`
        case selected.value?.tithely && !selected.value?.enabled && !showBundleOffer.value:
            return `Purchase or Connect`
        case selected.value?.tithely && selected.value?.enabled:
            return `Manage Subscription`
        case selected.value?.tithely && !selected.value?.enabled:
            return actionText.value
        default:
            return `Uninstall`
    }
})

const secondaryActionText = computed(() => {
    switch (true) {
        case isExtensionLoading.value:
            return `Loading...`
        case !selected.value?.tithely && !selected.value?.enabled:
            return `Install`
        case isUnableToPurchase.value:
            return `Contact Account Owner to Purchase`
        case selected.value?.tithely && !selected.value?.enabled && showBundleOffer.value:
            return `Bundle and Save!`
        case selected.value?.name === 'service_plan_integration':
            return `Go To Song Library`
        default:
            return `Go to ${selected.value?.displayName}`
    }
})

const builderIoUrl = computed(() => {
    const integrationNames = [
        'service_plan_integration',
        'songselect_integration',
        'sites_integration',
        'church_apps_integration'
    ]
    const hasTithelyOrgId = !!meData.value?.organization.tithelyOrganizationId
    const isIntegration = includes(integrationNames, selected.value?.name)

    if (isIntegration && hasFeature('tithely-bundle') && showBundleOffer.value && hasTithelyOrgId) {
        return `${selected.value?.builderIoId}-bundle`
    } else {
        return selected.value?.builderIoId
    }
})
</script>

<template>
    <div>
        <BaseModal
            ref="modalDetails"
            size="lg"
            class="p-6">
            <BaseModalHeader @close="close">
                <div class="flex flex-col">
                    <h2 class="whitespace-nowrap text-2xl font-medium">
                        {{ selected ? selected.displayName : 'Extension' }}
                    </h2>

                    <p class="text-sm text-secondary">Extension Details</p>
                </div>
            </BaseModalHeader>

            <div class="p-4">
                <BuilderContent :path="builderIoUrl!">
                    <div class="mb-4 h-32 bg-primary-100"></div>
                    <h3 class="pb-1">Description</h3>
                    <p class="text-sm text-secondary">{{ selected?.description }}</p>
                </BuilderContent>
            </div>

            <div class="flex w-full flex-row content-around items-center justify-end gap-4 p-4">
                <SpButton
                    v-if="selected?.tithely || selected?.enabled"
                    :disabled="isDisabled"
                    :variant="secondaryVariant"
                    :intent="secondaryIntent"
                    class="m-auto w-full"
                    @click="purchaseOrUninstall">
                    <template
                        v-if="isExtensionLoading"
                        #start>
                        <LoadingSpinner class="!mr-2 h-4" />
                    </template>
                    {{ detailsActionText }}
                </SpButton>

                <SpButton
                    v-if="isSecondaryButtonVisible"
                    :disabled="isDisabled"
                    class="m-auto w-full"
                    @click="primaryDetailAction">
                    <template
                        v-if="isExtensionLoading"
                        #start>
                        <LoadingSpinner class="!mr-2 h-4" />
                    </template>
                    {{ secondaryActionText }}
                </SpButton>
            </div>
        </BaseModal>
    </div>
</template>
