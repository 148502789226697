<script setup lang="ts">
import BaseModalCloseButton from '@/components/base/BaseModalCloseButton.vue'

const emit = defineEmits<{
    close: [void]
}>()
</script>

<template>
    <header class="flex items-center justify-between">
        <div class="flex items-center">
            <slot />
        </div>

        <div class="flex items-center">
            <BaseModalCloseButton @close="emit(`close`)" />

            <slot name="end" />
        </div>
    </header>
</template>
