import { createRouter, createWebHistory } from 'vue-router'
import routes from '@/routes'
import feature from '@/middleware/router/feature'
import amplify from '@/middleware/router/amplify'

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach(feature)
router.beforeEach(amplify)

export default router
