/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FileResource } from '../models/FileResource';
import type { LinkAttachmentRequest } from '../models/LinkAttachmentRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AttachmentsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get attachments for a model
     * @returns any OK
     * @throws ApiError
     */
    public getAttachments({
        attachedTo,
        attachedToId,
    }: {
        /**
         * The model type to get the attachments for.
         */
        attachedTo: 'ServicePlanSegment' | 'EventInstance',
        /**
         * The model id to get the attachments for.
         */
        attachedToId: number,
    }): CancelablePromise<{
        data: Array<FileResource>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/attachments',
            query: {
                'attached_to': attachedTo,
                'attached_to_id': attachedToId,
            },
            errors: {
                400: `Bad request`,
                404: `Not Found`,
                500: `An error occurred`,
            },
        });
    }

    /**
     * Attach files to an available model
     * @returns any Created
     * @throws ApiError
     */
    public attachFile({
        requestBody,
    }: {
        /**
         * The model type to link the file to.
         */
        requestBody: LinkAttachmentRequest,
    }): CancelablePromise<{
        data: FileResource;
    }> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/attachments',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                404: `Not Found`,
            },
        });
    }

}
