<script setup lang="ts">
import { computed, ref } from 'vue'
import { useToast } from 'vue-toastification'
import { useForm } from 'vee-validate'
import { object, ref as yupRef, string } from 'yup'
import { includes } from 'lodash'
import { SpButton, SpInput, SpLabel, SpIconField } from '@tithely/sproutkit-vue'
import { PhEye, PhEyeClosed } from '@phosphor-icons/vue'
import { useMeUpdate } from '@/queries/me'
import { checkSafePassword } from '@/utils/password'
import BaseModal from '@/components/base/BaseModal.vue'
import BaseModalHeader from '@/components/base/BaseModalHeader.vue'
import FormError from '@/components/FormError.vue'

const modal = ref<InstanceType<typeof BaseModal>>()

const { mutate: updateMe, status } = useMeUpdate()
const toast = useToast()

const { defineField, errors, handleSubmit, setFieldError } = useForm({
    initialValues: {
        currentPassword: ``,
        password: ``,
        passwordConfirmation: ``
    },
    validationSchema: object().shape({
        currentPassword: string().required(`Current Password is required`),
        password: string()
            .required(`Password is required`)
            .min(8, `Password must be a minimum of 8 characters`)
            .test(`is-valid`, `Password is not safe`, checkSafePassword),
        passwordConfirmation: string()
            .required()
            .oneOf([yupRef(`password`)], `Passwords do not match`)
    })
})

const [currentPassword, currentPasswordAttrs] = defineField(`currentPassword`)
const [password, passwordAttrs] = defineField(`password`)
const [passwordConfirmation, passwordConfirmationAttrs] = defineField(`passwordConfirmation`)

const isLoading = computed(() => status.value === `pending`)

const close = () => {
    modal.value?.close()
}

const submit = handleSubmit(values => {
    updateMe(
        {
            current_password: values.currentPassword,
            password: values.password,
            password_confirmation: values.passwordConfirmation
        },
        {
            onSuccess: () => {
                close()
                toast.success(`Password was updated successfully`)
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onError: (error: any) => {
                if (error?.body?.error) {
                    if (includes(error.body.error, `current password`)) {
                        setFieldError(`password`, error.body.error)
                    } else {
                        toast.error(error.body.error)
                    }
                }
            }
        }
    )
})

const showCurrentPassword = ref(false)
const showPassword = ref(false)
</script>

<template>
    <BaseModal
        ref="modal"
        size="sm"
        class="flex flex-col gap-4 !p-4">
        <BaseModalHeader @close="close">
            <h2 class="text-2xl font-medium">User Password</h2>
        </BaseModalHeader>

        <form class="!m-0 grid grid-cols-1 gap-4">
            <div class="flex flex-col gap-2">
                <SpLabel required>Current Password</SpLabel>
                <SpIconField>
                    <SpInput
                        v-model="currentPassword"
                        v-bind="currentPasswordAttrs"
                        :type="showCurrentPassword ? `text` : `password`"
                        :disabled="isLoading"
                        required
                        class="w-full" />
                    <template #end>
                        <button
                            type="button"
                            class="flex size-6 items-center justify-center rounded outline-none focus:ring-2 focus:ring-primary/50"
                            @click="showCurrentPassword = !showCurrentPassword">
                            <component
                                :is="showCurrentPassword ? PhEye : PhEyeClosed"
                                weight="bold" />
                        </button>
                    </template>
                </SpIconField>
                <FormError :error="errors.currentPassword" />
            </div>

            <div class="flex flex-col gap-2">
                <SpLabel required>New Password</SpLabel>
                <SpIconField>
                    <SpInput
                        v-model="password"
                        v-bind="passwordAttrs"
                        :type="showPassword ? `text` : `password`"
                        :disabled="isLoading"
                        required
                        class="w-full" />
                    <template #end>
                        <button
                            type="button"
                            class="flex size-6 items-center justify-center rounded outline-none focus:ring-2 focus:ring-primary/50"
                            @click="showPassword = !showPassword">
                            <component
                                :is="showPassword ? PhEye : PhEyeClosed"
                                weight="bold" />
                        </button>
                    </template>
                </SpIconField>
                <FormError :error="errors.password" />
            </div>

            <div class="flex flex-col gap-2">
                <SpLabel required>Confirm New Password</SpLabel>
                <SpIconField>
                    <SpInput
                        v-model="passwordConfirmation"
                        v-bind="passwordConfirmationAttrs"
                        :type="showPassword ? `text` : `password`"
                        :disabled="isLoading"
                        required
                        class="w-full" />
                    <template #end>
                        <button
                            type="button"
                            class="flex size-6 items-center justify-center rounded outline-none focus:ring-2 focus:ring-primary/50"
                            @click="showPassword = !showPassword">
                            <component
                                :is="showPassword ? PhEye : PhEyeClosed"
                                weight="bold" />
                        </button>
                    </template>
                </SpIconField>
                <FormError :error="errors.passwordConfirmation" />
            </div>

            <div class="flex items-center justify-end gap-4">
                <SpButton
                    intent="secondary"
                    variant="subtle"
                    :disabled="isLoading"
                    @click="close">
                    Cancel
                </SpButton>

                <SpButton
                    :loading="isLoading"
                    @click="submit">
                    {{ isLoading ? `Updating` : `Update` }}
                </SpButton>
            </div>
        </form>
    </BaseModal>
</template>
