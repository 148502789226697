/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmailSendRequest } from '../models/EmailSendRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class EmailsTempService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Send an email
     * Handle the incoming request.
     * @returns void
     * @throws ApiError
     */
    public emailSend({
        requestBody,
    }: {
        /**
         * Email payload
         */
        requestBody: EmailSendRequest,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/emails/send',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Update an email
     * @returns void
     * @throws ApiError
     */
    public emailUpdate({
        emailQueue,
        requestBody,
    }: {
        /**
         * ID of the email queue.
         */
        emailQueue: number,
        /**
         * Email payload
         */
        requestBody: EmailSendRequest,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/emails/send/{emailQueue}',
            path: {
                'emailQueue': emailQueue,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

}
