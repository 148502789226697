export default {
    mfa: {
        modal: {
            mfaSetup: `Setup Multi-Factor Authentication`,
            verify: `Verify`,
            enableAppAuth: `App Authenticator`,
            enableEmailAuth: `Email Authentication`,
            setup: `What is your preferred method of authentication? A six digit code will be sent to this method upon login to access your account.`,
            scanQrCode: `Scan the QR code below with an authenticator app (such as Google authenticator or Microsoft authenticator.).`,
            enterCode: `Please enter the 6-digit code provided by the authenticator app to finish setup.`,
            qrCodeSuccess: `Authenticator app successfully enabled.`,
            errorProcessing: `Error processing your request. Please try again.`,
            emailMfaSuccess: `Email MFA authentication successfully enabled.`,
            disableMfa: `Disable %{type} 2FA`,
            checkEmail: `A 6-digit code was sent to your email address.`,
            enterCodeEmail: `Enter the code included in the email to finish your authentication setup.`,
            codeExpires: `This code will expire in 30 minutes.`,
            recoveryCodes: `Account Recovery Codes are used to access your account if you lose access to your authentication app. Each code may only be used once. Download these codes and save them in a safe place.`,
            downloadRecoveryCode: `Download Recovery Codes`,
            hideSecret: `Show QR code.`,
            showSecret: `Can't scan? Show manual code.`
        }
    },
    emailVerify: {
        verified: `Email Verified`,
        error: `Error verifying email. Please try again.`,
        codeSent: `A 6-digit code was sent to your email address.`,
        finishVerify: `Enter the code included in the email verify your email.`,
        expire: `This code will expire in 30 minutes.`,
        validate: `Validate`,
        verify: `Verify`,
        verifiedStatus: `Verified`,
        notVerified: `Email not verified`
    }
}
