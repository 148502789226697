import { clone, includes, isDate, isNumber, join, map, replace, slice, trim } from 'lodash'
import { format, fromUnixTime, parseISO } from 'date-fns'
import FILESIZE from '@/constants/filesize'
import type { paginationMeta } from '@/services/api/client'
import { PUBLIC_SUBDOMAINS } from '@/constants/routes'

/**
 * Format human-readable date
 */
const formatDate = (date?: string | number | Date) => {
    if (!date) {
        return ``
    }

    const formatString = `MMMM do, yyyy`

    if (isNumber(date)) {
        return format(fromUnixTime(date), formatString)
    }

    if (isDate(date)) {
        return format(date, formatString)
    }

    return format(new Date(date), formatString)
}

/**
 * Format human-readable duration
 */
const formatDuration = (seconds = 0, minutes = 0, hours = 0) => {
    const remainingSeconds = seconds % 60

    switch (true) {
        case seconds === 0 && minutes === 0 && hours === 0:
            return ''
        case hours > 0 && minutes > 0:
            return `${hours}h ${minutes}m`
        case hours > 0:
            return `${hours}h`
        case minutes > 0 && remainingSeconds > 0:
            return `${minutes}m ${remainingSeconds}s`
        case minutes > 0:
            return `${minutes}m`
        default:
            return `${remainingSeconds}s`
    }
}

/**
 * Format human-readable file size
 */
const formatFileSize = (bytes: number) => {
    if (!bytes) {
        return '0 Bytes'
    }

    const sizes = [`Bytes`, `KB`, `MB`, `GB`, `TB`]
    const calculated = Math.floor(Math.log(bytes) / Math.log(FILESIZE.Kilobyte()))

    return `${parseFloat((bytes / Math.pow(FILESIZE.Kilobyte(), calculated)).toFixed(2))} ${sizes[calculated]}`
}

/**
 * Insert an item into an array at set index.
 */
const insertAtIndex = (arr: any[] = [], item: any, index: number) => [
    ...slice(arr, 0, index),
    item,
    ...slice(arr, index)
]

/**
 * Joins an array with commas & ampersand (or a custom conjunctive, such as 'and')
 */
const joinList = (list: string[], conjunctive = `&`) => {
    const newList = clone(list)
    const last = newList.pop()

    return newList.length ? `${join(newList, `, `)}, ${conjunctive} ${last}` : last
}

/**
 * Returns a new array with all the trailing/leading whitespace removed from each item.
 * Also replaces multiple spaces with a single space.
 */
const trimArrayWhitespace = (arr: string[]) => map(arr, item => replace(trim(item), /\s+/g, ` `))

/*
 * This is used exclusively by transformers
 */
const transformDate = (value?: string | Date | null) => {
    if (!value) {
        return value
    }

    return typeof value === 'string' ? parseISO(value) : value.toISOString()
}

/**
 * Gets the next page param for useInfiniteQuery
 */
const getNextPageParam = (lastPage: { meta: paginationMeta }): undefined | number => {
    if (lastPage.meta.current_page === lastPage.meta.last_page) {
        return undefined
    }
    return lastPage.meta.current_page + 1
}

const getSubdomain = () => window.location.hostname.split(`.`)[0]

const isPublicSubdomain = () => includes(PUBLIC_SUBDOMAINS, getSubdomain())

/**
 * Encodes filename given a filepath
 */
const encodeFileName = (url: string) => {
    const lastSlashIndex = url.lastIndexOf('/')
    const directoryPath = url.substring(0, lastSlashIndex + 1)
    const filename = url.substring(lastSlashIndex + 1)

    const filenameEncoded = encodeURIComponent(filename)
    return directoryPath + filenameEncoded
}

export {
    encodeFileName,
    formatDate,
    formatDuration,
    formatFileSize,
    getNextPageParam,
    getSubdomain,
    insertAtIndex,
    isPublicSubdomain,
    joinList,
    transformDate,
    trimArrayWhitespace
}
