import showEmailEditor from '@/functions/emailEditor'

export default showEmailEditor()
    ? [
          {
              path: `/forms/entries/:formId`,
              component: () => import('@/pages/forms/index.vue')
          }
      ]
    : []
