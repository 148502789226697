import { Expose } from 'class-transformer'
import type { PersonDetailResource as Model } from '@/services/api/client'
import type { CamelCaseKeys } from '@/types/utility'

export type SerializedPersonDetailResource = Model

export default class PersonDetailResource implements CamelCaseKeys<SerializedPersonDetailResource> {
    id?: Model['id']

    @Expose({ name: 'person_details' })
    personDetails: Model['person_details']

    constructor(values: PersonDetailResource) {
        Object.assign(this, values)
    }
}
