import { QUERY_KEYS } from '@/constants/query'
import type { EchoEventConfig } from '@/types/composables/echo'

const userChannelConfig: EchoEventConfig[] = [
    {
        event: `Features\\FeatureToggleToggled`,
        clearQueries: [QUERY_KEYS.me]
    },
    {
        event: `User\\UserUpdated`,
        clearQueries: [QUERY_KEYS.me]
    }
]

export default userChannelConfig
