<script lang="ts" setup>
import { computed, ref } from 'vue'
import InlineSvg from 'vue-inline-svg'
import useUtils from '@/store/utils'
import logo from '@/assets/images/logo.svg'
import logoIcon from '@/assets/images/logo-icon.svg'
import MainNavMenu from '@/layouts/components/MainNavMenu.vue'
import MainNavMobile from '@/layouts/components/MainNavMobile.vue'
import MainNavSettings from '@/layouts/components/MainNavSettings.vue'
import MainNavSearch from '@/layouts/components/MainNavSearch.vue'
import { useMe } from '@/queries/me'
import PullRequestBanner from '@/layouts/components/PullRequestBanner.vue'

const utils = useUtils()
const { hasFeature } = useMe()
const mobile = computed(() => utils.getMaxWidth(`md`))

const search = ref<InstanceType<typeof MainNavSearch>>()
const searchWidth = computed(() => search.value?.width ?? 1920)
const environment = computed(() => (hasFeature(`qa_test_account`) ? `QA` : ``))
</script>

<template>
    <PullRequestBanner />
    <nav class="flex h-[76px] flex-nowrap items-center bg-primary !px-5 !py-2 print:hidden lg:!px-[30px]">
        <a
            href="/r/dashboard"
            class="relative !mr-5 w-[24px] text-primary-50 hover:text-primary-50 lg:mr-[30px] lg:w-[93px]">
            <InlineSvg
                :src="logoIcon"
                class="visible !mt-[-7px] block h-[35px] w-auto lg:invisible lg:hidden" />
            <InlineSvg
                :src="logo"
                class="!mt-[-7px] hidden h-[35px] w-auto lg:visible lg:block" />
            <div
                v-if="environment"
                class="absolute right-0 top-[25px] text-sm text-yellow-500 lg:top-[20px] lg:text-lg">
                {{ environment }}
            </div>
        </a>

        <MainNavMobile v-if="mobile" />
        <MainNavMenu
            v-else
            :search-width="searchWidth" />

        <MainNavSearch ref="search" />
        <MainNavSettings />
    </nav>
</template>
