import { Expose } from 'class-transformer'
import type { MyCountry as Model } from '@/services/api/client'
import type { CamelCaseKeys } from '@/types/utility'

export type SerializedMyCountry = Model

export default class MyCountry implements CamelCaseKeys<SerializedMyCountry> {
    id!: SerializedMyCountry['id']

    name!: SerializedMyCountry['name']

    abbreviation!: SerializedMyCountry['abbreviation']

    @Expose({ name: 'abbreviation_2' })
    abbreviationTwo!: SerializedMyCountry['abbreviation_2']

    currency!: SerializedMyCountry['currency']

    @Expose({ name: 'currency_symbol' })
    currencySymbol!: SerializedMyCountry['currency_symbol']

    @Expose({ name: 'date_format' })
    dateFormat!: SerializedMyCountry['date_format']

    @Expose({ name: 'sms_prefix' })
    smsPrefix!: SerializedMyCountry['sms_prefix']

    constructor(values: MyCountry) {
        Object.assign(this, values)
    }
}
