/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OnboardingStepRequest } from '../models/OnboardingStepRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OrganizationOnboardingService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns void
     * @throws ApiError
     */
    public updateOnboardingStep({
        requestBody,
    }: {
        /**
         * Onboarding step details
         */
        requestBody: OnboardingStepRequest,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/onboarding',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

}
