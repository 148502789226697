/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { StoreGroupMembershipByPersonIdsRequest } from '../models/StoreGroupMembershipByPersonIdsRequest';
import type { StoreGroupMembershipByTagsRequest } from '../models/StoreGroupMembershipByTagsRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class GroupsMembershipBulkActionsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Add people to the group.
     * @returns void
     * @throws ApiError
     */
    public bulkGroupMembershipByPersonId({
        group,
        requestBody,
    }: {
        /**
         * ID of the group.
         */
        group: number,
        /**
         * Array of Person Ids to add to the group
         */
        requestBody: StoreGroupMembershipByPersonIdsRequest,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/groups/{group}/members',
            path: {
                'group': group,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Add people with these tags to the group.
     * @returns void
     * @throws ApiError
     */
    public bulkGroupMembershipByTag({
        group,
        requestBody,
    }: {
        /**
         * ID of the group.
         */
        group: number,
        /**
         * Array of Tags to add to the group
         */
        requestBody: StoreGroupMembershipByTagsRequest,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/groups/{group}/tags',
            path: {
                'group': group,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

}
