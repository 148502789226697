/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MessageIndexResource } from '../models/MessageIndexResource';
import type { MessageRequestBase } from '../models/MessageRequestBase';
import type { MessageResource } from '../models/MessageResource';
import type { StoreMessageRequest } from '../models/StoreMessageRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class MessagesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Retrieve a list of messages with simplified details.
     * @returns any OK
     * @throws ApiError
     */
    public message(): CancelablePromise<{
        data: Array<MessageIndexResource>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/messages',
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Create a new message
     * @returns any Created
     * @throws ApiError
     */
    public createMessage({
        requestBody,
    }: {
        /**
         * Message object that needs to be added to the database
         */
        requestBody: StoreMessageRequest,
    }): CancelablePromise<{
        data: MessageResource;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/messages',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Retrieve a message with full details and transformed content based on type.
     * @returns any OK
     * @throws ApiError
     */
    public getMessage({
        id,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
    }): CancelablePromise<{
        data: MessageResource;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/messages/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Delete a draft message.
     * @returns void
     * @throws ApiError
     */
    public deleteMessage({
        id,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/messages/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
                409: `Cannot modify a message that is not in draft status.`,
            },
        });
    }

    /**
     * Update an existing message.
     * @returns any OK
     * @throws ApiError
     */
    public patchMessage({
        id,
        requestBody,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
        /**
         * Message object that needs to be updated in the database
         */
        requestBody: MessageRequestBase,
    }): CancelablePromise<{
        data: MessageResource;
    }> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/messages/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
                409: `Cannot modify a message that is not in draft or ready status.`,
            },
        });
    }

}
