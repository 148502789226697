export default [
    {
        path: `/songs`,
        component: () => import('@/pages/songs/index.vue'),
        meta: {
            isVue: true
        }
    },
    {
        path: `/songs/:songId`,
        component: () => import('@/pages/songs/Song.vue'),
        meta: {
            feature: `service_plan_integration`
        }
    }
]
