/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { paginationLinks } from '../models/paginationLinks';
import type { paginationMeta } from '../models/paginationMeta';
import type { SmsQueueResource } from '../models/SmsQueueResource';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class MessagingSmsQueueService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Display all text messages.
     * @returns any OK
     * @throws ApiError
     */
    public getTexts({
        page,
        status,
        personId,
        startDate,
        endDate,
        perPage,
    }: {
        /**
         * The page to retrieve starting with page 1.
         */
        page?: number,
        /**
         * Filter by status.
         */
        status?: string,
        /**
         * Filter by person_id.
         */
        personId?: number,
        /**
         * Filter by start date
         */
        startDate?: string,
        /**
         * Filter by end date
         */
        endDate?: string,
        /**
         * Number of items per page.
         */
        perPage?: number,
    }): CancelablePromise<{
        data: Array<SmsQueueResource>;
        meta: paginationMeta;
        links: paginationLinks;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/history/texts',
            query: {
                'page': page,
                'status': status,
                'person_id': personId,
                'start_date': startDate,
                'end_date': endDate,
                'per_page': perPage,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Display the specified text message.
     * @returns any OK
     * @throws ApiError
     */
    public getText({
        id,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
    }): CancelablePromise<{
        data: SmsQueueResource;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/history/texts/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Remove the specified text message.
     * @returns void
     * @throws ApiError
     */
    public deleteText({
        id,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/history/texts/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

}
