/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GroupResource } from '../models/GroupResource';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class GroupsEventsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Attach an event to a group.
     * @returns any Created
     * @throws ApiError
     */
    public attachGroupEvent({
        group,
        event,
    }: {
        /**
         * ID of the group.
         */
        group: number,
        /**
         * ID of the event.
         */
        event: number,
    }): CancelablePromise<{
        data: GroupResource;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/groups/{group}/events/{event}',
            path: {
                'group': group,
                'event': event,
            },
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Detach an event from a group.
     * @returns void
     * @throws ApiError
     */
    public detachGroupEvent({
        group,
        event,
    }: {
        /**
         * ID of the group.
         */
        group: number,
        /**
         * ID of the event.
         */
        event: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/groups/{group}/events/{event}',
            path: {
                'group': group,
                'event': event,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

}
