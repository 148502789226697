/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { SongVersionSummaryResource } from './SongVersionSummaryResource';

export type CombinedSearchResultResource = {
    /**
     * The source of the song. Either "song_select" or "song_library".
     */
    source?: CombinedSearchResultResource.source;
    /**
     * The CCLI number of the song.
     */
    ccli?: string;
    /**
     * The ID of the song.
     */
    id?: string;
    /**
     * The name of the song.
     */
    name?: string;
    /**
     * The authors of the song.
     */
    authors?: Array<string>;
    /**
     * The summary of the song version.
     */
    version_summary?: Array<SongVersionSummaryResource>;
    /**
     * The key(s) of the song.
     */
    key?: Array<string>;
    /**
     * Describes whether the CCLI song has lyrics/sheets/chord pro and if user is authorized to access them.
     */
    content?: Record<string, any>;
};

export namespace CombinedSearchResultResource {

    /**
     * The source of the song. Either "song_select" or "song_library".
     */
    export enum source {
        SONG_SELECT = 'song_select',
        SONG_LIBRARY = 'song_library',
    }


}

