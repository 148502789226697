/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AuthorizationForgotPasswordService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Process form submission for forgot password.
     * User submission of forgot password form creates an entry and sends out an email.
     * @returns void
     * @throws ApiError
     */
    public forgotPassword({
        requestBody,
    }: {
        /**
         * Forgot password email
         */
        requestBody: {
            email: string;
            /**
             * The subdomain for the organization being logged into.
             */
            subdomain: string;
        },
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/forgot-password',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Check if key is valid for password reset.
     * Counts against password reset rate limit.
     * @returns any OK
     * @throws ApiError
     */
    public verifyPasswordResetKey({
        key,
        subdomain,
    }: {
        /**
         * Key for the password reset request.
         */
        key: string,
        /**
         * The subdomain for the organization being logged into.
         */
        subdomain: string,
    }): CancelablePromise<{
        data: {
            mfa: boolean;
        };
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/forgot-password/{key}',
            path: {
                'key': key,
            },
            query: {
                'subdomain': subdomain,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Reset password based on form submit.
     * Counts against password reset rate limit.
     * @returns void
     * @throws ApiError
     */
    public submitResetPasswordForm({
        key,
        requestBody,
    }: {
        /**
         * Key for the password reset request.
         */
        key: string,
        /**
         * Resets the user's password.
         */
        requestBody: {
            /**
             * The subdomain for the organization being logged into.
             */
            subdomain: string;
            password: string;
            password_confirmation: string;
            /**
             * The two-factor authentication code. Required if the user has TOTP 2FA enabled.
             */
            '2fa_code'?: string;
        },
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/forgot-password/{key}',
            path: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
                428: `Multi-factor authentication required`,
            },
        });
    }

}
