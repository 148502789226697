/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InstrumentResource } from '../models/InstrumentResource';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SongLibraryInstrumentsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Display all instruments in use, or a base list.
     * @returns any OK
     * @throws ApiError
     */
    public getInstruments({
        inUseOnly,
    }: {
        /**
         * Only return instruments that are in use.
         */
        inUseOnly?: boolean,
    }): CancelablePromise<{
        data: Array<InstrumentResource>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/song-library/instruments',
            query: {
                'in-use-only': inUseOnly,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

}
