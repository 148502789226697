<script setup lang="ts">
import { ref } from 'vue'
import { TYPE, useToast } from 'vue-toastification'
import { useI18n } from 'vue-i18n'
import { SpButton } from '@tithely/sproutkit-vue'
import BaseModal from '@/components/base/BaseModal.vue'
import BaseModalHeader from '@/components/base/BaseModalHeader.vue'
import VerificationInput from '@/pages/login/components/VerificationInput.vue'
import useApi from '@/composables/api'
import { useMe } from '@/queries/me'

const { refetch } = useMe()
const { api } = useApi()
const toast = useToast()
const i18n = useI18n()
const modal = ref<InstanceType<typeof BaseModal>>()
const disabled = ref(false)
const error = ref(``)
const code = ref(``)

const emit = defineEmits([`close`])

const verify = () => {
    disabled.value = true
    api.authorizationLoggedInUser
        .verifyEmail({ requestBody: { code: code.value } })
        .then(() => {
            toast(i18n.t(`user.emailVerify.verified`), { type: TYPE.SUCCESS })
            refetch()
            emit(`close`)
            close()
        })
        .catch(err => {
            error.value =
                err?.response?.data?.data?.message || err?.response?.data?.error || i18n.t(`user.emailVerify.error`)

            disabled.value = false
        })
}
const close = () => {
    modal.value?.close()
}

const open = () => {
    modal.value?.open()
}

defineExpose({
    open,
    close
})
</script>

<template>
    <BaseModal
        ref="modal"
        size="md"
        class="flex flex-col gap-4 !p-4">
        <BaseModalHeader @close="close">
            <h2 class="text-2xl font-medium">Email Verification</h2>
        </BaseModalHeader>

        <div class="align-center flex min-h-[250px] flex-col justify-center gap-2 text-center">
            <p class="text-base">{{ $t(`user.emailVerify.codeSent`) }}</p>

            <p class="text-base">{{ $t(`user.emailVerify.finishVerify`) }}</p>

            <p class="text-base">{{ $t(`user.emailVerify.expire`) }}</p>

            <VerificationInput
                ref="verificationInput"
                :disabled="disabled"
                @submit="verify"
                @complete="(newCode: string) => (code = newCode)" />

            <div class="align-center mt-4 flex justify-center gap-2">
                <SpButton
                    :disabled="disabled"
                    type="button"
                    @click="verify"
                    >{{ $t(`user.emailVerify.verify`) }}</SpButton
                >
            </div>

            <div
                v-if="error"
                class="text-center text-danger-600">
                {{ error }}
            </div>
        </div>
    </BaseModal>
</template>
