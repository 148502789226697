/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { paginationLinks } from '../models/paginationLinks';
import type { paginationMeta } from '../models/paginationMeta';
import type { PermissionFormatQuery } from '../models/PermissionFormatQuery';
import type { RoleResource } from '../models/RoleResource';
import type { RoleWrite } from '../models/RoleWrite';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UsersRolesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Display Roles.
     * @returns any OK
     * @throws ApiError
     */
    public getRoles({
        page,
        limit,
    }: {
        /**
         * The page to retrieve starting with page 1.
         */
        page?: number,
        /**
         * The number of roles to return per page
         */
        limit?: number,
    }): CancelablePromise<{
        data: Array<RoleResource>;
        meta: paginationMeta;
        links: paginationLinks;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/roles',
            query: {
                'page': page,
                'limit': limit,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Create new role.
     * @returns any Created
     * @throws ApiError
     */
    public createRole({
        format,
        requestBody,
    }: {
        format?: PermissionFormatQuery,
        requestBody?: RoleWrite,
    }): CancelablePromise<{
        data: RoleResource;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/roles',
            query: {
                'format': format,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Get Role.
     * @returns any OK
     * @throws ApiError
     */
    public getRole({
        id,
        format,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
        format?: PermissionFormatQuery,
    }): CancelablePromise<{
        data: RoleResource;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/roles/{id}',
            path: {
                'id': id,
            },
            query: {
                'format': format,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Delete role.
     * Cannot have any users attached to the role.
     * @returns void
     * @throws ApiError
     */
    public deleteRole({
        id,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/roles/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Update the specified resource in storage.
     * @returns any OK
     * @throws ApiError
     */
    public updateRole({
        id,
        format,
        requestBody,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
        format?: PermissionFormatQuery,
        requestBody?: RoleWrite,
    }): CancelablePromise<{
        data: RoleResource;
    }> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/roles/{id}',
            path: {
                'id': id,
            },
            query: {
                'format': format,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

}
