/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SimpleInvoiceData } from '../models/SimpleInvoiceData';
import type { StripeCard } from '../models/StripeCard';
import type { StripeInvoiceStatus } from '../models/StripeInvoiceStatus';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class BillingService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Cancel your subscription.
     * @returns void
     * @throws ApiError
     */
    public cancelSubscription({
        reason,
        reasonFeatures,
        reasonFeaturesComment,
        comment,
        switchedTo,
        switchedToReason,
    }: {
        /**
         * Reason for cancellation
         */
        reason: string,
        /**
         * Reason for cancellation due to missing features
         */
        reasonFeatures: string,
        /**
         * Comment about cancellation due to missing features
         */
        reasonFeaturesComment: string,
        /**
         * Additional comments
         */
        comment: string,
        /**
         * ChMS switched to
         */
        switchedTo?: string,
        /**
         * Reason ChMS was switched to
         */
        switchedToReason?: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/organizations',
            query: {
                'reason': reason,
                'reason_features': reasonFeatures,
                'reason_features_comment': reasonFeaturesComment,
                'comment': comment,
                'switched_to': switchedTo,
                'switched_to_reason': switchedToReason,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Display a listing of the resource.
     * @returns any OK
     * @throws ApiError
     */
    public getStripeCards(): CancelablePromise<{
        data: Array<StripeCard>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/stripe/cards',
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Create a new card and set it to default for organization
     * @returns any OK
     * @throws ApiError
     */
    public updateStripeCard({
        requestBody,
    }: {
        requestBody?: {
            /**
             * Stripe token
             */
            token?: string;
        },
    }): CancelablePromise<{
        data: StripeCard;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/stripe/cards',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Return a list of invoices.
     * @returns any OK
     * @throws ApiError
     */
    public getStripeInvoices({
        limit = 100,
        startingAfter,
        status,
    }: {
        /**
         * The number of invoices to return
         */
        limit?: number,
        /**
         * Get invoices after this date. Can be anything that can be parsed by Carbon
         */
        startingAfter?: string,
        /**
         * Get invoices with specific statuses
         */
        status?: StripeInvoiceStatus,
    }): CancelablePromise<{
        data: Array<SimpleInvoiceData>;
        /**
         * There are more invoices to fetch
         */
        has_more: boolean;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/stripe/invoices',
            query: {
                'limit': limit,
                'starting_after': startingAfter,
                'status': status,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Charge unpaid invoices up to 7 months back to the default card on file.
     * @returns void
     * @throws ApiError
     */
    public chargeUnpaidInvoices(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/stripe/invoices/charge_unpaid',
            errors: {
                400: `Failed`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Get texts sent in the current invoice period.
     * @returns any OK
     * @throws ApiError
     */
    public getTextsSent(): CancelablePromise<{
        /**
         * The number of texts sent this month
         */
        data?: number;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/stripe/invoices/texts',
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Return a customer's invoice, either with an ID or the upcoming invoice.
     * @returns any OK
     * @throws ApiError
     */
    public getStripeInvoice({
        invoiceId,
        download = false,
    }: {
        /**
         * The ID of the invoice to retrieve. Use "upcoming" to retrieve the upcoming invoice for your organization.
         */
        invoiceId: string,
        /**
         * If exists, will return an invoice. Alternatively, you can set the Accept header to application/pdf to get a PDF.
         */
        download?: boolean,
    }): CancelablePromise<{
        data: SimpleInvoiceData;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/stripe/invoices/{invoiceId}',
            path: {
                'invoiceId': invoiceId,
            },
            query: {
                'download': download,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Apply retention discount. Can only do once per organization.
     * @returns void
     * @throws ApiError
     */
    public applyRetentionDiscount(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/stripe/retention-discount',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

}
