import { useToast } from 'vue-toastification'
import type { ToastOptions } from 'vue-toastification/dist/types/types'
import eventBus from '@/composables/eventBus'

interface Toast {
    message: string
    type?: ToastOptions['type']
    timeout: ToastOptions['timeout']
}

export default () => {
    const { on } = eventBus()
    const toast = useToast()

    on(`toast`, (payload: Toast) => {
        toast(payload.message, {
            type: payload.type,
            timeout: payload.timeout
        })
    })

    window.toastReady = true
}
