/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FundCreate } from '../models/FundCreate';
import type { FundResource } from '../models/FundResource';
import type { FundUpdate } from '../models/FundUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ContributionsFundsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get list of funds.
     * Include year_gt query param to get totals for each fund for each year.
     * @returns any OK
     * @throws ApiError
     */
    public getFunds({
        filter,
    }: {
        filter?: Record<string, any>,
    }): CancelablePromise<{
        data: Array<FundResource>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/contributions/funds',
            query: {
                'filter': filter,
            },
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Create a new fund.
     * @returns any Created
     * @throws ApiError
     */
    public createFund({
        requestBody,
    }: {
        requestBody?: FundCreate,
    }): CancelablePromise<{
        data: FundResource;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/contributions/funds',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Get Fund details.
     * @returns any OK
     * @throws ApiError
     */
    public getFund({
        id,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
    }): CancelablePromise<{
        data: FundResource;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/contributions/funds/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Update a fund.
     * @returns any OK
     * @throws ApiError
     */
    public updateFund({
        id,
        requestBody,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
        requestBody?: FundUpdate,
    }): CancelablePromise<{
        data: FundResource;
    }> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/contributions/funds/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Delete a fund, moving all payments to another fund.
     * @returns void
     * @throws ApiError
     */
    public deleteFund({
        id,
        target,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
        /**
         * The fund to move the contributions to
         */
        target: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/contributions/funds/{id}/{target}',
            path: {
                'id': id,
                'target': target,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

}
