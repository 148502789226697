import { QueryClient, type VueQueryPluginOptions } from '@tanstack/vue-query'
import { persistQueryClient } from '@tanstack/query-persist-client-core'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { CACHE_KEY } from '@/constants/query'
import ENV from '@/constants/env'

const queryClient = new QueryClient()

const vueQueryOptions: VueQueryPluginOptions = {
    queryClient,
    clientPersister: client =>
        persistQueryClient({
            queryClient: client,
            persister: createSyncStoragePersister({
                key: CACHE_KEY,
                storage: localStorage
            }),
            buster: ENV.queryCache
        }),
    enableDevtoolsV6Plugin: true
}

export default queryClient

export { vueQueryOptions }
