/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SongSelectDetailsResource } from '../models/SongSelectDetailsResource';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SongLibrarySongSelectSongDetailsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Song Select song details
     * Return song details from Song Select.
     * @returns any OK
     * @throws ApiError
     */
    public getSongSelectDetails({
        ccliNumber,
    }: {
        /**
         * CCLI number of the song
         */
        ccliNumber: number,
    }): CancelablePromise<{
        data: SongSelectDetailsResource;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/song-library/song-select/{ccliNumber}',
            path: {
                'ccliNumber': ccliNumber,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

}
