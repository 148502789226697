/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { StripeInvoiceStatus } from './StripeInvoiceStatus';

/**
 * Contains only what is needed for invoices. Will not include free items.
 */
export type SimpleInvoiceData = {
    amount_due?: number;
    amount_paid?: number;
    card?: {
        /**
         * Brand of the card used. Enum given is not exhaustive.
         */
        brand?: SimpleInvoiceData.brand | null;
        last4?: number | null;
    };
    charge_id?: string | null;
    /**
     * ISO 4217 currency code, in lowercase.
     */
    currency?: string;
    /**
     * Number of digits after the decimal for the currency.
     */
    currency_digits?: number;
    /**
     * The date the invoice was created.
     */
    date?: string;
    /**
     * Discounts applied to this invoice.
     */
    discounts?: Array<{
        /**
         * Discount ID.
         */
        id?: string;
        /**
         * Description for discount. Displayed on Left side of invoice.
         */
        description?: string;
        /**
         * Percent or fixed amount off
         */
        discount?: string;
        /**
         * Discount amount in the smallest unit.
         */
        amount?: number;
        /**
         * Formatted discount amount.
         */
        amount_string?: string;
    }>;
    /**
     * The date the invoice is due.
     */
    due_date?: string;
    /**
     * Invoice ID.
     */
    id?: string;
    /**
     * Items on the invoice.
     */
    line_items?: Array<{
        /**
         * Line item unique ID.
         */
        id?: string;
        /**
         * Description for line item. Displayed on Left side of invoice.
         */
        description?: string;
        /**
         * Subtext for description. Displayed underneath description.
         */
        subtext?: string;
        /**
         * Line item amount in the smallest unit.
         */
        amount?: number;
        /**
         * Formatted line item amount.
         */
        amount_string?: string;
    }>;
    /**
     * The date the invoice was created.
     */
    organization?: {
        /**
         * Organization ID.
         */
        id?: number;
        /**
         * Organization name.
         */
        name?: string;
    };
    /**
     * The date the invoice was created.
     */
    paid?: boolean;
    /**
     * The unix timestamp the invoice was paid in full
     */
    paid_at?: number;
    /**
     * The formatted time the invoice was paid in full
     */
    paid_at_string?: string;
    /**
     * The formatted time the invoice's period ended
     */
    period_end_string?: string;
    /**
     * The unix timestamp the invoice's period ended
     */
    period_end?: string;
    /**
     * Post-Payment Credit Notes Amount (least unit)
     */
    post_payment_credit_notes_amount?: number;
    /**
     * Post-Payment Credit Notes Amount (formatted)
     */
    post_payment_credit_notes_amount_string?: string;
    /**
     * Pre-Payment Credit Notes Amount (least unit)
     */
    pre_payment_credit_notes_amount?: number;
    /**
     * Pre-Payment Credit Notes Amount (formatted)
     */
    pre_payment_credit_notes_amount_string?: string;
    /**
     * The invoice was fully refunded
     */
    refunded?: boolean;
    /**
     * Refunds for invoice. These are applied after it is paid.
     */
    refunds?: Array<{
        /**
         * Refund ID.
         */
        id?: string;
        /**
         * Description for refund. Displayed on Left side of invoice.
         */
        description?: string;
        /**
         * Refund amount in the smallest unit.
         */
        amount?: number;
        /**
         * Formatted refund amount.
         */
        amount_string?: string;
    }>;
    status?: StripeInvoiceStatus;
    /**
     * Total amount in the smallest unit.
     */
    total?: number;
    /**
     * Total amount formatted.
     */
    total_string?: string;
};

export namespace SimpleInvoiceData {

    /**
     * Brand of the card used. Enum given is not exhaustive.
     */
    export enum brand {
        VISA = 'Visa',
        AMERICAN_EXPRESS = 'American Express',
        MASTER_CARD = 'MasterCard',
        DISCOVER = 'Discover',
    }


}

