export default [
    {
        path: `/cancel_recurring`,
        component: () => import('@/pages/Blank.vue'),
        meta: {
            layout: `blank`
        }
    },
    {
        path: `/give`,
        component: () => import('@/pages/Blank.vue'),
        meta: {
            layout: `blank`
        },
        children: [
            {
                path: `internal`,
                component: () => import('@/pages/Blank.vue'),
                meta: {
                    layout: `default`
                }
            },
            {
                path: `recurring`,
                component: () => import('@/pages/Blank.vue'),
                meta: {
                    layout: `default`
                }
            },
            {
                path: `methods`,
                component: () => import('@/pages/Blank.vue'),
                meta: {
                    layout: `default`
                }
            }
        ]
    }
]
