/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EventInstanceIndexResource } from '../models/EventInstanceIndexResource';
import type { EventInstancePeopleResource } from '../models/EventInstancePeopleResource';
import type { EventInstanceResource } from '../models/EventInstanceResource';
import type { paginationLinks } from '../models/paginationLinks';
import type { paginationMeta } from '../models/paginationMeta';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class EventsInstancesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * List event instances.
     * @returns any OK
     * @throws ApiError
     */
    public getEventInstances({
        page,
        pageSize,
        hasSetList,
        hasServicePlan,
        filter,
        sort,
    }: {
        /**
         * The page to retrieve starting with page 1.
         */
        page?: number,
        /**
         * The number of resources to return. Default: 25
         */
        pageSize?: number,
        /**
         * Filter the event instances by whether they have a service plan with Songs.
         */
        hasSetList?: boolean,
        /**
         * Filter the event instances by whether they have a service plan.
         */
        hasServicePlan?: boolean,
        /**
         *
         * Filtered fields:
         * | Field | Field Type | Description |
         * |--|--|--|
         * |category_id|array|Comma-separated list of calendar IDs.|
         * |start_date|date||
         * |end_date|date||
         * |name|text|Search by event name.|
         *
         * See **Filters** above for more details about using filters.
         */
        filter?: string,
        /**
         * Sortable fields
         * * name
         * * start_datetime
         * * end_datetime
         * * locations
         *
         *
         * See **Sorting** above for more details about sorting.
         */
        sort?: string,
    }): CancelablePromise<{
        data: Array<EventInstanceIndexResource>;
        meta: paginationMeta;
        links: paginationLinks;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/event-instances',
            query: {
                'page': page,
                'pageSize': pageSize,
                'has_set_list': hasSetList,
                'has_service_plan': hasServicePlan,
                'filter': filter,
                'sort': sort,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Get event instance.
     * @returns any OK
     * @throws ApiError
     */
    public getEventInstance({
        id,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
    }): CancelablePromise<{
        data: EventInstanceResource;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/event-instances/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Delete event instance.
     * @returns void
     * @throws ApiError
     */
    public deleteEventInstance({
        id,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/event-instances/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Update event instance.
     * @returns any OK
     * @throws ApiError
     */
    public updateEventInstance({
        id,
        requestBody,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
        /**
         * The event instance to update.
         */
        requestBody: {
            /**
             * Event instance name
             */
            name?: string;
            /**
             * Calendar ID
             */
            calendar_id?: number;
            /**
             * Start date and time of the event instance
             */
            start_datetime?: string;
            /**
             * End date and time of the event instance
             */
            end_datetime?: string;
        },
    }): CancelablePromise<{
        data: EventInstanceResource;
    }> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/event-instances/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Get checked-in people on event instance.
     * @returns any OK
     * @throws ApiError
     */
    public getEventInstancePeople({
        id,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
    }): CancelablePromise<{
        data: Array<EventInstancePeopleResource>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/event-instances/{id}/people',
            path: {
                'id': id,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

}
