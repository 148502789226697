<script setup lang="ts">
import { computed, h, useSlots } from 'vue'
import { flatMap, split } from 'lodash'

const slots = useSlots()

const props = withDefaults(
    defineProps<{
        message: string
        separator?: RegExp
        tag?: string
    }>(),
    { tag: `p` }
)

const parts = computed(() => {
    const separator = props.separator || /[[\]]/

    return split(props.message, separator)
})

const TemplatedMessage = computed(() =>
    h(
        props.tag,
        flatMap(parts.value, (text: string, offset: number) => {
            if (offset % 2 === 0) {
                return text
            }

            const parsed = split(text, `:`, 2)
            const value = parsed[parsed.length - 1]
            const key = parsed[parsed.length - 2] || value

            if (key) {
                const scopedSlot = slots[key]

                if (scopedSlot) {
                    return scopedSlot({ text: value })
                }
            }

            const defaultSlot = slots.default

            if (defaultSlot) {
                return defaultSlot({ text: value })
            }

            return []
        })
    )
)
</script>

<template>
    <TemplatedMessage />
</template>
