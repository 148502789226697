/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiTokenResource } from '../models/ApiTokenResource';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ApiApplicationsTokensService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * List API Token Authorizations.
     * Can optionally filter those owned by the user.
     * This filter is only available to regular users.
     * @returns any OK
     * @throws ApiError
     */
    public listApiTokens({
        user,
    }: {
        /**
         * Filter tokens by user ID
         */
        user?: boolean | null,
    }): CancelablePromise<{
        data: Array<ApiTokenResource>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api-auth/tokens',
            query: {
                'user': user,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Get single API Token
     * @returns void
     * @throws ApiError
     */
    public getApiToken({
        id,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api-auth/tokens/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Revoke API Token.
     * @returns void
     * @throws ApiError
     */
    public revokeApiToken({
        id,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api-auth/tokens/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

}
