import Echo from 'laravel-echo'
import { useCookies } from '@vueuse/integrations/useCookies'
import { computed, watch } from 'vue'
import { each } from 'lodash'
import { useQueryClient } from '@tanstack/vue-query'
import ENV from '@/constants/env'
import organizationChannels from '@/constants/echo/organizationChannels'
import { useMe } from '@/queries/me'
import type MeResource from '@/services/api/transformers/MeResource'
import type { EchoEventConfig } from '@/types/composables/echo'
import globalChannels from '@/constants/echo/globalChannels'
import userChannels from '@/constants/echo/userChannels'
import type { Ref } from 'vue'
import Pusher from 'pusher-js'

declare global {
    interface Window {
        Pusher: typeof Pusher
    }
}

window.Pusher = Pusher

interface EchoConfig {
    broadcaster: string
    key: string
    cluster: string
    forceTLS: boolean
    authEndpoint: string
    wsHost?: string
    encrypted?: boolean
}

const useEcho = () => {
    const { data: me } = useMe()
    const cookies = useCookies()
    const token = computed(() => cookies.get('access_token'))
    const queryClient = useQueryClient()

    const generateEventHandler = (config: EchoEventConfig) => (body: Record<string, Ref<number>>) => {
        if (config.clearQueries) {
            each(config.clearQueries, queryKey => queryClient.invalidateQueries({ queryKey }))
        }
        if (config.clearQueryId && `id` in body) {
            queryClient.invalidateQueries({ queryKey: config.clearQueryId(body.id.value) })
        }
        if (config.action) {
            each(config.action(body) ?? [], queryKey => queryClient.invalidateQueries({ queryKey }))
        }
    }

    watch(
        me,
        (newMe: MeResource | undefined, oldMe: MeResource | undefined) => {
            if (newMe === undefined) {
                return
            }
            if (window.echo === undefined) {
                const echoConfig: EchoConfig = {
                    broadcaster: 'pusher',
                    key: newMe.pusher.key,
                    cluster: newMe.pusher.cluster,
                    forceTLS: true,
                    authEndpoint: `${ENV.apiHost}/broadcasting/auth`,
                    encrypted: true
                }

                if (newMe.pusher?.host) {
                    echoConfig['wsHost'] = newMe.pusher.host
                }

                const echo = new Echo({
                    ...echoConfig,
                    auth: {
                        headers: {
                            Authorization: `${token.value}`
                        }
                    },
                    bearerToken: `${token.value}`
                })
                window.echo = echo

                const globalChannel = echo.channel(`Breeze.Global`)
                each(globalChannels, config => {
                    globalChannel.listen(config.event, generateEventHandler(config))
                })

                const orgChannel = echo.private(`Breeze.Org.${newMe.organization.id}`)
                each(organizationChannels, config => {
                    orgChannel.listen(config.event, generateEventHandler(config))
                })
            }

            // User Channel (Dynamic due to impersonation)
            if (newMe.id !== oldMe?.id) {
                if (oldMe?.id) {
                    window.echo.leave(`Breeze.User.${oldMe.id}`)
                }
                if (newMe.id) {
                    const userChannel = window.echo.private(`Breeze.User.${newMe.id}`)
                    each(userChannels, config => {
                        userChannel.listen(config.event, generateEventHandler(config))
                    })
                }
            }
        },
        {
            immediate: true
        }
    )

    // When the token expires, update the auth header and reconnect the private channels
    watch(token, (newToken, oldToken) => {
        if (newToken === oldToken || !newToken || !me.value) {
            return
        }
        if (window.echo) {
            window.echo.connector.pusher.config.auth.headers['Authorization'] = `Bearer ${newToken}`

            // We skip the global channel since it is public and we can assume it is already connected
            // Redo organization channel
            window.echo.leave(`Breeze.Org.${me.value.organization.id}`)
            const orgChannel = window.echo.private(`Breeze.Org.${me.value.organization.id}`)
            each(organizationChannels, config => {
                orgChannel.listen(config.event, generateEventHandler(config))
            })

            // Redo User Channel
            window.echo.leave(`Breeze.User.${me.value.id}`)
            const userChannel = window.echo.private(`Breeze.User.${me.value.id}`)
            each(userChannels, config => {
                userChannel.listen(config.event, generateEventHandler(config))
            })
        }
    })
}
export default useEcho
