import { ref } from 'vue'
import { useToast } from 'vue-toastification'
import useApi from '@/composables/api'

const useTithelySSO = () => {
    const { api } = useApi()
    const isLoading = ref(false)
    const toast = useToast()

    const redirect = (url: string) => {
        isLoading.value = true

        return api.tithelyAuth
            .tithelySso({
                redirect: url
            })
            .then(({ data }) => {
                window.open(data.link, `_self`)
            })
            .catch(() => {
                toast.error('Redirect error')
            })
            .finally(() => {
                isLoading.value = false
            })
    }

    return { redirect, isLoading }
}

export default useTithelySSO
