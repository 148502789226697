<script setup lang="ts">
import { computed, ref } from 'vue'
import { Dropdown as VDropdown } from 'floating-vue'
import type { Nullable } from '@/types/utility'
import type { Props } from '@/types/components/BDropdown'

const props = withDefaults(defineProps<Props>(), {
    placement: `bottom`,
    distance: 8,
    skidding: 0,
    overflowPadding: 8,
    autoSize: `min`,
    disabled: false
})

const container = ref<Nullable<InstanceType<typeof VDropdown>>>(null)

const dropdownProps = computed(() => ({
    overflowPadding: props.overflowPadding,
    placement: props.placement,
    distance: props.distance,
    skidding: props.skidding,
    container: container.value?.$el,
    autoSize: props.autoSize,
    disabled: props.disabled
}))
</script>

<template>
    <VDropdown
        ref="container"
        v-bind="dropdownProps"
        class="inline-flex">
        <slot />

        <template #popper="{ hide }">
            <slot
                name="content"
                :hide="hide" />
        </template>
    </VDropdown>
</template>

<style lang="scss" scoped>
:deep(.v-popper__popper) {
    @apply focus-visible:outline-none;

    .v-popper {
        &__inner {
            @apply rounded border border-solid border-primary-900 bg-white shadow;
        }

        &__arrow-container {
            @apply hidden;
        }
    }
}
</style>
