/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TagCreateRequest } from '../models/TagCreateRequest';
import type { TagResource } from '../models/TagResource';
import type { TagResourceWithBreadcrumbs } from '../models/TagResourceWithBreadcrumbs';
import type { TagUpdateRequest } from '../models/TagUpdateRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TagsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Retrieve all tags.
     * @returns any OK
     * @throws ApiError
     */
    public getTagsCollection({
        page,
        pageSize,
        filter,
        sort,
    }: {
        /**
         * The page to retrieve starting with page 1.
         */
        page?: number,
        /**
         * The number of resources to return. Default: 25
         */
        pageSize?: number,
        /**
         *
         * Filtered fields:
         * | Field | Field Type | Description |
         * |--|--|--|
         * |tag_category_id|number||
         * |name|text||
         *
         * See **Filters** above for more details about using filters.
         */
        filter?: string,
        /**
         * Sortable fields
         * * name
         * * created_on
         *
         *
         * See **Sorting** above for more details about sorting.
         */
        sort?: string,
    }): CancelablePromise<{
        data: Array<TagResource>;
        pagination: {
            /**
             * Total number of resources.
             */
            total?: number;
            /**
             * Total number of resources on this page.
             */
            count?: number;
            per_page?: number;
            current_page?: number;
            last_page?: number;
        };
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/tags',
            query: {
                'page': page,
                'pageSize': pageSize,
                'filter': filter,
                'sort': sort,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Create a new tag.
     * @returns any Created
     * @throws ApiError
     */
    public createTag({
        requestBody,
    }: {
        /**
         * Tag resource to create.
         */
        requestBody: TagCreateRequest,
    }): CancelablePromise<{
        data: TagResource;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/tags',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Retrieve one tag.
     * @returns any OK
     * @throws ApiError
     */
    public getTag({
        id,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
    }): CancelablePromise<{
        data: Array<TagResourceWithBreadcrumbs>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/tags/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Delete a tag.
     * @returns any Accepted
     * @throws ApiError
     */
    public deleteTag({
        id,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
    }): CancelablePromise<{
        data: TagResource;
    }> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/tags/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Update a tag.
     * @returns any Created
     * @throws ApiError
     */
    public updateTag({
        id,
        requestBody,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
        /**
         * Tag resource updates
         */
        requestBody: TagUpdateRequest,
    }): CancelablePromise<{
        data: TagResource;
    }> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/tags/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

}
