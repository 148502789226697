export default [
    {
        // Example: https://api.breezechms.test/errors/oauth?error=token_not_found
        path: '/errors/oauth',
        component: () => import('@/pages/errors/OAuth.vue'),
        meta: {
            layout: `blank`
        }
    },
    {
        path: '/errors/404',
        component: () => import('@/pages/errors/PageNotFound.vue'),
        meta: {
            layout: `blank`
        }
    },
    {
        path: '/errors/subdomain',
        component: () => import('@/pages/errors/NoChurch.vue'),
        meta: {
            layout: `blank`
        }
    }
]
