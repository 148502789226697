/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { PersonName } from './PersonName';
import type { ProfileFieldData } from './ProfileFieldData';

/**
 * A person to create (no `id`) or update.
 */
export type PersonCreate = {
    /**
     * The ID for the person being updated. If missing, a new person is crated.
     */
    id?: number;
    name?: PersonName;
    family?: {
        /**
         * The ID of an existing family.
         */
        id?: number | null;
        /**
         * Link to an image.
         */
        photo?: string;
        role?: PersonCreate.role;
    };
    profile_fields?: Array<ProfileFieldData>;
};

export namespace PersonCreate {

    export enum role {
        UNASSIGNED = 'Unassigned',
        CHILD = 'Child',
        ADULT = 'Adult',
        HEAD_OF_HOUSEHOLD = 'Head of Household',
        SPOUSE = 'Spouse',
    }


}

