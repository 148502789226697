<script setup lang="ts">
import { ref, watch } from 'vue'
import { useMutation, useQuery } from '@tanstack/vue-query'
import { useToast } from 'vue-toastification'
import { filter, find, includes, map } from 'lodash'
import { plainToInstance } from 'class-transformer'
import { SpButton, SpCheckbox, SpSpinner } from '@tithely/sproutkit-vue'
import useApi from '@/composables/api'
import BaseModal from '@/components/base/BaseModal.vue'
import BaseModalHeader from '@/components/base/BaseModalHeader.vue'
import NotificationResource from '@/services/api/transformers/NotificationResource'
import BSelect from '@/components/BSelect.vue'
import TemplatedMessage from '@/components/TemplatedMessage.vue'
import { type NotificationChange } from '@/services/api/client'
import queryClient from '@/plugins/query'
import { QUERY_KEYS } from '@/constants/query'
import type { SelectOptions } from '@/types/forms'

const { api } = useApi()
const toast = useToast()

const modal = ref<InstanceType<typeof BaseModal>>()

const open = () => {
    modal.value?.open()
}

const close = () => {
    modal.value?.close()
}

const { data: notificationSettings, status } = useQuery({
    queryKey: QUERY_KEYS.userNotifications(),
    queryFn: () => api.notifications.getNotificationSettings(),
    select: ({ data }) => map(data, notification => plainToInstance(NotificationResource, notification))
})

const { mutate, status: mutateStatus } = useMutation({
    mutationFn: (notifications: NotificationChange[]) =>
        api.notifications.enableMultipleNotifications({ requestBody: { notifications } }),
    onSuccess: data => {
        queryClient.setQueryData(QUERY_KEYS.userNotifications(), data)
        close()
        toast.success(`Notifications updated successfully!`)
    }
})

const DAYS = [
    {
        value: 1,
        label: `1st`
    },
    {
        value: 15,
        label: `15th`
    },
    {
        value: 25,
        label: `25th`
    }
]

const WEEKDAYS = [
    {
        value: 1,
        label: `Monday`
    },
    {
        value: 2,
        label: `Tuesday`
    },
    {
        value: 3,
        label: `Wednesday`
    },
    {
        value: 4,
        label: `Thursday`
    },
    {
        value: 5,
        label: `Friday`
    },
    {
        value: 6,
        label: `Saturday`
    },
    {
        value: 7,
        label: `Sunday`
    }
]

const NOTIFICATIONS = [
    {
        id: 1,
        label: `Birthdays`,
        description: `Send me a monthly email (on the [day] of each month) listing upcoming birthdays for the following month.`
    },
    {
        id: 2,
        label: `Follow Ups`,
        description: `Send me a weekly email on [weekday] listing all assigned, completed, and past due follow ups from the past week.`
    },
    {
        id: 3,
        label: `New People`,
        description: `Send me a weekly email on [weekday] listing all people added to the database over the past week.`
    },
    {
        id: 4,
        label: `Attendance`,
        description: `Send me a weekly email on [weekday] showing an overview of attendance from the past week.`
    },
    {
        id: 5,
        label: `Giving`,
        description: `Send me a weekly email on [weekday] showing an overview of giving from the past week.`
    },
    {
        id: 6,
        label: `Anniversaries`,
        description: `Send me a monthly email (on the [day] of each month) listing upcoming [anniversaries] for the following month.`
    }
]

interface NotificationSetting {
    id: number
    checked: boolean
    label: string
    description: string
    runDay: number
    runWeekday: number
    anniversaryFields?: SelectOptions
    anniversaryFieldId?: string
}

const mapData = (settings: NotificationResource[]) =>
    map(
        filter(settings, `hasPermission`),
        notification =>
            ({
                id: notification.id,
                ...find(NOTIFICATIONS, { id: notification.id }),
                checked: notification.checked,
                runDay: notification.runDay,
                runWeekday: notification.runWeekday,
                anniversaryFieldId: notification.anniversaryFieldId,
                anniversaryFields: map(notification.anniversaryFields || [], (label, value) => ({ label, value }))
            } as NotificationSetting)
    )

const settings = ref<NotificationSetting[]>(notificationSettings.value ? mapData(notificationSettings.value) : [])

const hasField = (id: number, field: `day` | `weekday` | `anniversaries`) =>
    includes(find(NOTIFICATIONS, { id })?.description, `[${field}]`)

watch(notificationSettings, newValue => {
    settings.value = newValue ? mapData(newValue) : []
})

const save = () => {
    mutate(
        (map(settings.value, setting => ({
            notification_id: setting.id,
            checked: setting.checked,
            ...(hasField(setting.id, `day`)
                ? {
                      run_day: setting.runDay
                  }
                : {}),
            ...(hasField(setting.id, `weekday`)
                ? {
                      run_weekday: setting.runWeekday
                  }
                : {}),
            ...(hasField(setting.id, `anniversaries`)
                ? {
                      anniversary_field_id: setting.anniversaryFieldId
                  }
                : {})
        })) as NotificationChange[])
    )
}

defineExpose({ open, close })
</script>

<template>
    <BaseModal
        ref="modal"
        size="md"
        class="!p-4">
        <BaseModalHeader @close="close">
            <h2 class="text-2xl font-medium">Notifications</h2>
        </BaseModalHeader>
        <div
            v-if="status === `pending`"
            class="flex items-center justify-center p-4">
            <SpSpinner class="size-6" />
        </div>
        <form
            v-else
            class="m-0"
            @submit.prevent>
            <div
                v-for="notification in settings"
                :key="notification.id"
                class="flex flex-col gap-2 !px-4 !py-2">
                <label class="!mb-0 flex cursor-pointer flex-row items-center gap-3 text-lg font-semibold">
                    <SpCheckbox
                        v-model="notification.checked"
                        size="sm"
                        :disabled="mutateStatus === `pending`" />
                    {{ notification.label }}
                </label>

                <TemplatedMessage
                    :message="notification.description"
                    class="!pl-7 text-base">
                    <template
                        v-if="hasField(notification.id, `day`)"
                        #day>
                        <BSelect
                            v-model.number="notification.runDay"
                            :options="DAYS"
                            :disabled="mutateStatus === `pending`"
                            size="sm"
                            class="w-auto !border-secondary-100" />
                    </template>

                    <template
                        v-if="hasField(notification.id, `weekday`)"
                        #weekday>
                        <BSelect
                            v-model.number="notification.runWeekday"
                            :options="WEEKDAYS"
                            :disabled="mutateStatus === `pending`"
                            size="sm"
                            class="w-auto !border-secondary-100" />
                    </template>

                    <template
                        v-if="hasField(notification.id, `anniversaries`)"
                        #anniversaries>
                        <BSelect
                            v-model.number="notification.anniversaryFieldId"
                            :options="notification.anniversaryFields"
                            :disabled="mutateStatus === `pending`"
                            size="sm"
                            class="w-auto !border-secondary-100" />
                    </template>
                </TemplatedMessage>
            </div>

            <div class="flex items-center justify-end gap-4">
                <SpButton
                    intent="secondary"
                    variant="subtle"
                    :disabled="mutateStatus === `pending`"
                    @click="close">
                    Cancel
                </SpButton>

                <SpButton
                    :loading="mutateStatus === `pending`"
                    @click="save">
                    {{ mutateStatus === `pending` ? `Updating` : `Update` }}
                </SpButton>
            </div>
        </form>
    </BaseModal>
</template>
