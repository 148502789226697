/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type MessageIndexResource = {
    /**
     * Transform the resource into an array.
     */
    id?: number;
    status?: MessageIndexResource.status;
    subject?: string;
    send_at?: string;
    submitted_at?: string;
    processed_at?: string;
    created_at?: string;
    updated_at?: string;
};

export namespace MessageIndexResource {

    export enum status {
        DRAFT = 'draft',
        READY = 'ready',
        RENDERING = 'rendering',
        PROCESSED = 'processed',
        FAILED = 'failed',
        BLOCKED = 'blocked',
        STALE = 'stale',
    }


}

