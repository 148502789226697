import showEmailEditor from '@/functions/emailEditor'

export default [
    {
        path: `/events/blockout_dates`,
        component: () => import('@/pages/Blank.vue'),
        meta: {
            layout: `blank`
        }
    },
    {
        path: `/events/:instanceId/plan`,
        component: () => import('@/pages/events/Plan.vue'),
        meta: {
            isVue: true,
            sideNav: `events`
        }
    },
    {
        path: `/events/:instanceId/attachments`,
        component: () => import('@/pages/events/Attachments.vue'),
        meta: {
            isVue: true,
            sideNav: `events`
        }
    },
    ...(showEmailEditor()
        ? [
              {
                  path: `/events/view/:eventId`,
                  component: () => import('@/pages/events/index.vue')
              },
              {
                  path: `/events/people/:eventId`,
                  component: () => import('@/pages/events/index.vue')
              },
              {
                  path: `/events/people/:eventId/out`,
                  component: () => import('@/pages/events/index.vue')
              },
              {
                  path: `/events/reports/:eventId`,
                  component: () => import('@/pages/events/index.vue')
              },
              {
                  path: `/events/volunteers2/:eventId`,
                  component: () => import('@/pages/events/index.vue')
              }
          ]
        : [])
]
