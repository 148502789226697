import { useModal } from 'vue-final-modal'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'

interface Options {
    title?: string
    cancelText?: string
    confirmText: string
    confirmButton?: string
    onConfirm: () => void
    danger?: boolean
    size?: string
}

const useConfirmModal = ({
    title,
    cancelText,
    confirmText,
    confirmButton,
    onConfirm,
    danger = false,
    size = `sm`
}: Options) => {
    const { open } = useModal({
        component: ConfirmModal,
        attrs: {
            title,
            cancelText,
            confirmText,
            confirmButton,
            onConfirm,
            danger,
            size
        }
    })

    return { open }
}

export default useConfirmModal
