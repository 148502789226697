<script lang="ts" setup>
import { computed, ref } from 'vue'
import { Dropdown as VDropdown } from 'floating-vue'
import InlineSvg from 'vue-inline-svg'
import useNavMain from '@/composables/nav/main'
import BIcon from '@/components/BIcon.vue'
import breezeLogoIcon from '@/assets/images/logo-icon.svg'
import chevronRightIcon from '@/assets/icons/chevron-right.svg'

const { nav } = useNavMain()

const mobileDropdown = ref(null)

const dropdownProps = computed(() => ({
    triggers: [`click`],
    theme: `main-nav-mobile`,
    positioningDisabled: true,
    container: mobileDropdown.value
}))
</script>

<template>
    <div ref="mobileDropdown">
        <VDropdown v-bind="dropdownProps">
            <button
                type="button"
                class="!mr-4">
                <BIcon
                    icon="menu"
                    class="h-auto w-6 text-primary-50" />
            </button>

            <template #popper="{ hide }">
                <div class="fixed inset-0 overflow-x-auto bg-primary text-primary-50">
                    <header class="flex h-[76px] !px-5 !py-5">
                        <InlineSvg
                            :src="breezeLogoIcon"
                            class="!mt-[-3px] h-[35px] w-auto" />

                        <button
                            type="button"
                            class="!ml-auto flex items-center justify-center !p-0"
                            @click="hide">
                            <BIcon
                                icon="times"
                                class="h-4 w-4" />
                        </button>
                    </header>

                    <ul v-if="nav.length">
                        <li
                            v-for="{ label, route } in nav"
                            :key="route">
                            <a
                                :href="route"
                                class="flex items-center !px-5 !py-3 no-underline hover:text-primary-50"
                                @click="hide">
                                <span class="text-lg font-bold">
                                    {{ label }}
                                </span>

                                <InlineSvg
                                    :src="chevronRightIcon"
                                    class="!ml-auto h-4 w-auto" />
                            </a>
                        </li>
                    </ul>
                </div>
            </template>
        </VDropdown>
    </div>
</template>

<style lang="scss">
.v-popper {
    &--some-open--main-nav-mobile {
        @apply overflow-hidden;

        .v-popper--theme-main-nav-search {
            @apply hidden;
        }
    }

    &--theme-main-nav-mobile {
        &.v-popper__popper {
            @apply fixed;
        }
    }
}
</style>
