/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MessageTemplateIndexResource } from '../models/MessageTemplateIndexResource';
import type { MessageTemplateRequestBase } from '../models/MessageTemplateRequestBase';
import type { MessageTemplateResource } from '../models/MessageTemplateResource';
import type { StoreMessageTemplateRequest } from '../models/StoreMessageTemplateRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class MessageTemplatesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Display a list of message templates.
     * @returns any OK
     * @throws ApiError
     */
    public messageTemplates({
        type,
    }: {
        /**
         * Type of message template filter.
         */
        type?: 'sms' | 'unlayer' | 'html' | 'text',
    }): CancelablePromise<{
        data: Array<MessageTemplateIndexResource>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/message-templates',
            query: {
                'type': type,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Create a new message template
     * @returns any Created
     * @throws ApiError
     */
    public createMessageTemplate({
        requestBody,
    }: {
        /**
         * Message template object that needs to be added to the database
         */
        requestBody: StoreMessageTemplateRequest,
    }): CancelablePromise<{
        data: MessageTemplateResource;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/message-templates',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Display specific message template.
     * @returns any OK
     * @throws ApiError
     */
    public getMessageTemplate({
        id,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
    }): CancelablePromise<{
        data: MessageTemplateResource;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/message-templates/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Remove a message template.
     * @returns void
     * @throws ApiError
     */
    public deleteMessageTemplate({
        id,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/message-templates/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Update a message template.
     * @returns void
     * @throws ApiError
     */
    public patchMessageTemplate({
        id,
        requestBody,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
        /**
         * Message template object that needs to be updated in the database
         */
        requestBody: MessageTemplateRequestBase,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/message-templates/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

}
