/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ServicePlanResource } from '../models/ServicePlanResource';
import type { ServicePlanUpdateRequest } from '../models/ServicePlanUpdateRequest';
import type { StoreServicePlanRequest } from '../models/StoreServicePlanRequest';
import type { SuccessfulSortResponse } from '../models/SuccessfulSortResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ServicePlansService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create a new service plan.
     * @returns any Created
     * @throws ApiError
     */
    public createServicePlan({
        requestBody,
    }: {
        /**
         * Service plan object that needs to be added to the database
         */
        requestBody: StoreServicePlanRequest,
    }): CancelablePromise<{
        data: ServicePlanResource;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/service-plans',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Retrieve a service plan.
     * @returns any OK
     * @throws ApiError
     */
    public getServicePlan({
        id,
        setlistView,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
        /**
         * Show set list in PDF
         */
        setlistView?: boolean,
    }): CancelablePromise<{
        data: ServicePlanResource;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/service-plans/{id}',
            path: {
                'id': id,
            },
            query: {
                'setlist_view': setlistView,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Delete a service plan.
     * @returns void
     * @throws ApiError
     */
    public deleteServicePlan({
        id,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/service-plans/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Update a service plan.
     * @returns any OK
     * @throws ApiError
     */
    public updateServicePlan({
        id,
        requestBody,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
        /**
         * Service plan object that needs to be updated in the database
         */
        requestBody: ServicePlanUpdateRequest,
    }): CancelablePromise<{
        data: ServicePlanResource;
    }> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/service-plans/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Sort the segments of a service plan.
     * @returns SuccessfulSortResponse OK
     * @throws ApiError
     */
    public sortServicePlanSegments({
        id,
        requestBody,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
        /**
         * Segment IDs in the desired sort order
         */
        requestBody: {
            /**
             * Segment IDs in the desired sort order
             */
            segments_ids: Array<number>;
        },
    }): CancelablePromise<SuccessfulSortResponse> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/service-plans/{id}/sort',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `The segments_ids array must contain all the segments for the service plan.`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

}
