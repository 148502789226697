import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { findIndex, includes } from 'lodash'
import type { Breakpoint, Breakpoints } from '@/types/plugins/utils'

const WIDTHS: Breakpoint[] = [null, `sm`, `md`, `lg`, `xl`]

const validateBreakpoint = (value: Breakpoint) => {
    if (!includes(WIDTHS, value)) {
        throw new Error(`'${value}' is not a valid breakpoint`)
    }
}

const useUtils = defineStore(`utils`, () => {
    const breakpoint = ref<Breakpoint>(null)

    const setBreakpoint = (value: Breakpoint) => {
        validateBreakpoint(value)

        breakpoint.value = value
    }

    const getMinWidth = computed(() => (minWidth: Breakpoints) => {
        validateBreakpoint(minWidth)

        const wIndex = findIndex(WIDTHS, v => v === minWidth)
        const bIndex = findIndex(WIDTHS, v => v === breakpoint.value)

        return wIndex <= bIndex
    })

    const getMaxWidth = computed(() => (maxWidth: Breakpoints) => {
        validateBreakpoint(maxWidth)

        const wIndex = findIndex(WIDTHS, v => v === maxWidth)
        const bIndex = findIndex(WIDTHS, v => v === breakpoint.value)

        return wIndex >= bIndex
    })

    return {
        breakpoint,
        setBreakpoint,
        getMinWidth,
        getMaxWidth
    }
})

export default useUtils

export { validateBreakpoint }
