/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ServicePlanSegmentResource } from '../models/ServicePlanSegmentResource';
import type { StoreBulkServicePlanSegmentRequest } from '../models/StoreBulkServicePlanSegmentRequest';
import type { StoreServicePlanSegmentRequest } from '../models/StoreServicePlanSegmentRequest';
import type { UpdateServicePlanSegmentRequest } from '../models/UpdateServicePlanSegmentRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ServicePlansSegmentsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Store a new segment in a service plan.
     * The `meta` field in the request will be validated based on the type.
     * The only type being validated is a `song`.
     * For other types the meta field should be null and is optional on a PATCH.
     * A `song` must contain a `song_key` key with a `string` of 1-255 characters or `null`. For example
     * ```
     * "meta": {
         * \t"song_key": "C#"
         * }
         * ```
         * @returns any Created
         * @throws ApiError
         */
        public createServicePlanSegment({
            id,
            requestBody,
        }: {
            /**
             * ID of the retrieved resource.
             */
            id: number,
            /**
             * Segment details
             */
            requestBody: StoreServicePlanSegmentRequest,
        }): CancelablePromise<{
            data: ServicePlanSegmentResource;
        }> {
            return this.httpRequest.request({
                method: 'POST',
                url: '/service-plans/{id}/segments',
                path: {
                    'id': id,
                },
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    400: `Bad request`,
                    401: `User is unauthorized.`,
                    403: `Access Denied`,
                    404: `Not Found`,
                },
            });
        }

        /**
         * @returns any OK
         * @throws ApiError
         */
        public createBulkServicePlanSegments({
            id,
            requestBody,
        }: {
            /**
             * ID of the retrieved resource.
             */
            id: number,
            /**
             * Segment details
             */
            requestBody: StoreBulkServicePlanSegmentRequest,
        }): CancelablePromise<{
            data: Array<ServicePlanSegmentResource>;
        }> {
            return this.httpRequest.request({
                method: 'POST',
                url: '/service-plans/{id}/segments/bulk-add',
                path: {
                    'id': id,
                },
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    400: `Bad request`,
                    401: `User is unauthorized.`,
                    403: `Access Denied`,
                    404: `Not Found`,
                },
            });
        }

        /**
         * Retrieve a specific segment of a service plan.
         * @returns any OK
         * @throws ApiError
         */
        public getServicePlanSegment({
            planId,
            id,
        }: {
            /**
             * ID of the plan.
             */
            planId: number,
            /**
             * ID of the retrieved resource.
             */
            id: number,
        }): CancelablePromise<{
            data: ServicePlanSegmentResource;
        }> {
            return this.httpRequest.request({
                method: 'GET',
                url: '/service-plans/{planId}/segments/{id}',
                path: {
                    'planId': planId,
                    'id': id,
                },
                errors: {
                    401: `User is unauthorized.`,
                    403: `Access Denied`,
                    404: `Not Found`,
                },
            });
        }

        /**
         * Delete a segment from a service plan.
         * @returns void
         * @throws ApiError
         */
        public deleteServicePlanSegment({
            planId,
            id,
        }: {
            /**
             * ID of the plan.
             */
            planId: number,
            /**
             * ID of the retrieved resource.
             */
            id: number,
        }): CancelablePromise<void> {
            return this.httpRequest.request({
                method: 'DELETE',
                url: '/service-plans/{planId}/segments/{id}',
                path: {
                    'planId': planId,
                    'id': id,
                },
                errors: {
                    401: `User is unauthorized.`,
                    403: `Access Denied`,
                    404: `Not Found`,
                },
            });
        }

        /**
         * Update a segment in a service plan.
         * See POST for details about the `meta` field.
         * @returns any OK
         * @throws ApiError
         */
        public updateServicePlanSegment({
            planId,
            id,
            requestBody,
        }: {
            /**
             * ID of the plan.
             */
            planId: number,
            /**
             * ID of the retrieved resource.
             */
            id: number,
            /**
             * Segment details
             */
            requestBody: UpdateServicePlanSegmentRequest,
        }): CancelablePromise<{
            data: ServicePlanSegmentResource;
        }> {
            return this.httpRequest.request({
                method: 'PATCH',
                url: '/service-plans/{planId}/segments/{id}',
                path: {
                    'planId': planId,
                    'id': id,
                },
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    400: `Bad request`,
                    401: `User is unauthorized.`,
                    403: `Access Denied`,
                    404: `Not Found`,
                },
            });
        }

        /**
         * Delete an attachment on a segment from a service plan.
         * @returns void
         * @throws ApiError
         */
        public deleteServicePlanSegmentAttachment({
            planId,
            segmentId,
            id,
        }: {
            /**
             * ID of the plan
             */
            planId: number,
            /**
             * ID of the segment
             */
            segmentId: number,
            /**
             * ID of the retrieved resource.
             */
            id: number,
        }): CancelablePromise<void> {
            return this.httpRequest.request({
                method: 'DELETE',
                url: '/service-plans/{planId}/segments/{segmentId}/attachments/{id}',
                path: {
                    'planId': planId,
                    'segmentId': segmentId,
                    'id': id,
                },
                errors: {
                    401: `User is unauthorized.`,
                    403: `Access Denied`,
                    404: `Not Found`,
                },
            });
        }

    }
