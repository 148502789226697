/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SongLibraryImportRequest } from '../models/SongLibraryImportRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SongLibraryImportFromSongLibraryService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Handle the incoming request.
     * @returns void
     * @throws ApiError
     */
    public songLibraryImport({
        requestBody,
        importFiles,
    }: {
        /**
         * The file to import.
         */
        requestBody: SongLibraryImportRequest,
        /**
         * Import files from Song Select for songs with a CCLI number.
         */
        importFiles?: boolean,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/song-library/import',
            query: {
                'import_files': importFiles,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

}
