/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmailHistoryResource } from '../models/EmailHistoryResource';
import type { paginationLinks } from '../models/paginationLinks';
import type { paginationMeta } from '../models/paginationMeta';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class EmailsEmailHistoryService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getEmailHistory({
        page,
        recipientsPersonIds,
        toEmail,
        startDate,
        endDate,
        status,
        priority,
        sort,
        order,
    }: {
        /**
         * The page to retrieve starting with page 1.
         */
        page?: number,
        /**
         * Filter by recipients person ids.
         */
        recipientsPersonIds?: Array<number>,
        /**
         * Filter by to email.
         */
        toEmail?: string,
        /**
         * Filter by start date.
         */
        startDate?: string,
        /**
         * Filter by end date.
         */
        endDate?: string,
        /**
         * Filter by status.
         */
        status?: string,
        /**
         * Filter by priority.
         */
        priority?: string,
        /**
         * Sort by field.
         */
        sort?: string,
        /**
         * Sort order.
         */
        order?: string,
    }): CancelablePromise<{
        data: Array<EmailHistoryResource>;
        meta: paginationMeta;
        links: paginationLinks;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/emails/history',
            query: {
                'page': page,
                'recipients_person_ids': recipientsPersonIds,
                'to_email': toEmail,
                'start_date': startDate,
                'end_date': endDate,
                'status': status,
                'priority': priority,
                'sort': sort,
                'order': order,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getEmailHistoryById({
        id,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
    }): CancelablePromise<{
        data: EmailHistoryResource;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/emails/history/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

}
