/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PersonDetailResource } from '../models/PersonDetailResource';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TagsPeopleService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Add people to a tag.
     * @returns any Created
     * @throws ApiError
     */
    public addPeopleToTag({
        requestBody,
    }: {
        /**
         * Tag folder resource to create.
         */
        requestBody: {
            data: {
                /**
                 * Array of tag IDs to add people to
                 */
                tags?: Array<number>;
                /**
                 * Array of people IDs to add to tag(s)
                 */
                people_ids?: Array<number>;
                /**
                 * Array of people IDs to not remove from tags
                 */
                uncheckedPeople?: Array<number>;
            };
        },
    }): CancelablePromise<{
        data: Array<number>;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/tags/people',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Remove people from a tag.
     * @returns any Created
     * @throws ApiError
     */
    public deletePeopleFromTag({
        requestBody,
    }: {
        /**
         * Tag folder resource to create.
         */
        requestBody: {
            data?: {
                /**
                 * Array of tag IDs to remove people from
                 */
                tags?: Array<number>;
            };
            /**
             * Array of people IDs to remove from tags
             */
            checkedPeople?: Array<number>;
            /**
             * Array of people IDs to not remove from tags
             */
            uncheckedPeople?: Array<number>;
        },
    }): CancelablePromise<{
        data: Array<number>;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/tags/people/delete',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Retrieve one person tag.
     * @returns any OK
     * @throws ApiError
     */
    public getPeopleTag({
        id,
        peopleIdsOnly,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
        /**
         * Return an array of people IDs instead of full details.
         */
        peopleIdsOnly?: number,
    }): CancelablePromise<{
        data: (PersonDetailResource | Array<number>);
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/tags/people/{id}',
            path: {
                'id': id,
            },
            query: {
                'people_ids_only': peopleIdsOnly,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

}
