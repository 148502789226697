/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { paginationLinks } from '../models/paginationLinks';
import type { paginationMeta } from '../models/paginationMeta';
import type { SongResource } from '../models/SongResource';
import type { StoreSongRequest } from '../models/StoreSongRequest';
import type { UpdateSongRequest } from '../models/UpdateSongRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SongLibrarySongsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Display all songs.
     * @returns any OK
     * @throws ApiError
     */
    public getSongs({
        page,
        themes,
        songtags,
        instruments,
        keys,
        startDate,
        endDate,
        exclude,
        search,
        perPage,
        sort,
        direction,
    }: {
        /**
         * The page to retrieve starting with page 1.
         */
        page?: number,
        /**
         * Filter by themes. Name Or Id.
         */
        themes?: Array<string>,
        /**
         * Filter by tags.
         */
        songtags?: Array<string>,
        /**
         * Filter by instruments.
         */
        instruments?: Array<string>,
        /**
         * Filter by keys.
         */
        keys?: Array<string>,
        /**
         * Filter by start date last scheduled window
         */
        startDate?: string,
        /**
         * Filter by end date for last scheduled window
         */
        endDate?: string,
        /**
         * Filter by songs outside the date range. Default is false.
         */
        exclude?: boolean,
        /**
         * Search songs by name, author, theme, and songtags
         */
        search?: string,
        /**
         * Number of items per page.
         */
        perPage?: number,
        /**
         * Sort by column.
         */
        sort?: 'name' | 'ccli' | 'last_scheduled' | 'created_on',
        /**
         * Sort direction.
         */
        direction?: 'asc' | 'desc',
    }): CancelablePromise<{
        data: Array<SongResource>;
        meta: paginationMeta;
        links: paginationLinks;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/song-library/songs',
            query: {
                'page': page,
                'themes': themes,
                'songtags': songtags,
                'instruments': instruments,
                'keys': keys,
                'start_date': startDate,
                'end_date': endDate,
                'exclude': exclude,
                'search': search,
                'per_page': perPage,
                'sort': sort,
                'direction': direction,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Store a newly created song.
     * @returns any Created
     * @throws ApiError
     */
    public createSong({
        requestBody,
    }: {
        /**
         * Song details
         */
        requestBody: StoreSongRequest,
    }): CancelablePromise<{
        data: SongResource;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/song-library/songs',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Display a specific song.
     * @returns any OK
     * @throws ApiError
     */
    public getSong({
        id,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
    }): CancelablePromise<{
        data: SongResource;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/song-library/songs/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Remove a specific song.
     * @returns void
     * @throws ApiError
     */
    public deleteSong({
        id,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/song-library/songs/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Update a specific song.
     * @returns any OK
     * @throws ApiError
     */
    public updateSong({
        id,
        requestBody,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
        /**
         * Song details
         */
        requestBody: UpdateSongRequest,
    }): CancelablePromise<{
        data: SongResource;
    }> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/song-library/songs/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

}
