import { every, filter, includes, some, trimStart } from 'lodash'
import { type Ref } from 'vue'
import type { NavItemBase } from '@/types/nav'

const filterNavPermissions = <NavItem extends NavItemBase>(
    items: NavItem[],
    features: Ref<string[]>,
    roles: Ref<string[]>,
    isTithelyGivingEnabled?: boolean
): NavItem[] =>
    filter(items, (item: NavItem) => {
        if (!item.route && !item.action) {
            return false
        }

        if (item.isTithelyGivingEnabled !== undefined && item.isTithelyGivingEnabled !== isTithelyGivingEnabled) {
            return false
        }

        const negativeFeatures = filter(item.features ?? [], feature => feature[0] === `!`)
        if (some(negativeFeatures, feature => includes(features.value, trimStart(feature, `!`)))) {
            return false
        }

        const positiveFeatures = filter(item.features ?? [], feature => feature[0] !== `!`)
        if (item.features && !every(positiveFeatures, feature => includes(features.value, feature))) {
            return false
        }

        const negativeRoles = filter(item.roles ?? [], role => role[0] === `!`)
        if (some(negativeRoles, role => includes(roles.value, trimStart(role, `!`)))) {
            return false
        }

        const positiveRoles = filter(item.roles ?? [], role => role[0] !== `!`)
        const roleCheck = item.orRoles ? some : every
        return !(item.roles && !roleCheck(positiveRoles ?? [], role => includes(roles.value, role)))
    })

export { filterNavPermissions }
