import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { isArray } from 'lodash'
import { useCookies } from '@vueuse/integrations/useCookies'
import ENV from '@/constants/env'
import { COOKIE_OPTIONS } from '@/constants/cookies'

const cookies = useCookies()

export default async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    const { ui_pr, api_pr } = to.query
    if (ENV.helmDomain() === `breezeqa.com` && (ui_pr || api_pr)) {
        if (ui_pr && !isArray(ui_pr) && !isNaN(parseInt(ui_pr))) {
            cookies.set(`ui_pr`, ui_pr, COOKIE_OPTIONS)
        }
        if (api_pr && !isArray(api_pr) && !isNaN(parseInt(api_pr))) {
            cookies.set(`api_pr`, api_pr, COOKIE_OPTIONS)
        }
        // Remove ui_pr and api_pr query params manually, then reload
        // Vue router is too slow to update the URL before reloading
        const location = window.location.href
            .replace(/([?&])ui_pr=\d+(&|$)/, '$1')
            .replace(/([?&])api_pr=\d+(&|$)/, '$1')
            .replace(/[?&]$/, '')
        window.history.replaceState(null, '', location)
        window.location.reload()
    } else {
        next()
    }
}
