const ENV = {
    app: import.meta.env.VITE_APP,
    apiHost: import.meta.env.VITE_API_HOST,
    breezeWeb: import.meta.env.VITE_BREEZE_WEB,
    builderIoApiKey: import.meta.env.VITE_BUILDER_IO_API_KEY,
    environment: import.meta.env.VITE_ENVIRONMENT,
    fileHost: import.meta.env.VITE_FILE_HOST,
    googleTagManager: import.meta.env.VITE_GOOGLE_TAG_MANAGER,
    helmHost: import.meta.env.VITE_HELM_HOST,
    helmDomain: () => {
        const domain = new URL(import.meta.env.VITE_HELM_HOST)

        return domain ? domain.hostname.split(`.`).slice(1).join(`.`) : ``
    },
    queryCache: import.meta.env.VITE_QUERY_CACHE,
    sentryDSN: import.meta.env.VITE_SENTRY_DSN,
    sentryReplay: import.meta.env.VITE_SENTRY_REPLAY,
    stripePublishableKey: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY,
    unlayerProjectId: import.meta.env.VITE_UNLAYER_PROJECT_ID
}

export default ENV
