export default {
    tithely: {
        connected: `Your Breeze and Tithe.ly accounts are connected!`,
        continue: `Click to continue to your Tithe.ly account.`,
        link: `If you are both a Breeze customer and a Tithe.ly customer you can connect your accounts. This will allow you to take advantage of new features we are building to integrate Breeze &Tithe.ly.`,
        linking: `Linking to Tithe.ly...`,
        linkingErrorMessage: `There was an error linking your account to Tithe.ly.`,
        getStarted: `Get Started`
    }
}
