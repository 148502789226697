/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type MessageTemplateResource = {
    /**
     * Transform the resource into an array.
     */
    id?: number;
    name?: string;
    content?: string;
    tags?: Array<string>;
    type?: MessageTemplateResource.type;
    subject?: string;
};

export namespace MessageTemplateResource {

    export enum type {
        SMS = 'sms',
        UNLAYER = 'unlayer',
        HTML = 'html',
        TEXT = 'text',
    }


}

