<script lang="ts" setup>
import { computed, ref } from 'vue'
import { isEmpty } from 'lodash'
import usePerson from '@/queries/people/person'
import ENV from '@/constants/env'

interface Props {
    id: number
    name: string
    avatar: string
}

const props = defineProps<Props>()

const { data: person } = usePerson(ref(props.id))

const tooltip = computed(() => ({
    content: `
<div>
    <div>${person.value?.personDetails?.address?.street_address}</div>
    <div>${person.value?.personDetails?.address?.city},
        ${person.value?.personDetails?.address?.state} ${person.value?.personDetails?.address?.zip}</div>
    ${person.value?.personDetails?.email?.address ? `<div>${person.value?.personDetails?.email?.address}</div>` : ``}
    ${
        person.value?.personDetails?.phone?.home?.number
            ? `<div>${person.value?.personDetails?.phone?.home?.number} (H)</div>`
            : ``
    }
    ${
        person.value?.personDetails?.phone?.mobile?.number
            ? `<div>${person.value?.personDetails?.phone?.mobile?.number} (M)</div>`
            : ``
    }
    ${
        person.value?.personDetails?.phone?.work?.number
            ? `<div>${person.value?.personDetails?.phone?.work?.number} (W)</div>`
            : ``
    }
</div>
`,
    html: true,
    disabled: isEmpty(person.value)
}))
</script>

<template>
    <a
        :href="`/people/view/${id}`"
        class="flex items-center gap-4 !p-4 hover:bg-secondary-50 hover:no-underline focus:bg-neutral-100 focus:outline-none">
        <div>
            <img
                v-tooltip.left="tooltip"
                :src="`${ENV.fileHost}/${avatar || `/img/profiles/generic/gray.png`}`"
                alt=""
                class="w-[55px]" />
        </div>
        <div>
            <span class="text-sm font-medium text-primary">
                {{ name }}
            </span>
        </div>
    </a>
</template>
