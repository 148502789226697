import { Expose } from 'class-transformer'
import type { LoginConfigResponse as Model } from '@/services/api/client'
import type { CamelCaseKeys } from '@/types/utility'

export type SerializedLoginConfigResponse = Model

export default class LoginConfigResponse implements CamelCaseKeys<SerializedLoginConfigResponse> {
    name?: Model['name']

    tithely!: Model['tithely']

    logo?: Model['logo']

    demo?: Model['demo']

    cancelled?: Model['cancelled']

    @Expose({ name: 'can_create_user' })
    canCreateUser?: Model['can_create_user']

    @Expose({ name: 'vue_login' })
    vueLogin?: Model['vue_login']

    constructor(values: LoginConfigResponse) {
        Object.assign(this, values)
    }
}
