/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProfileResource } from '../models/ProfileResource';
import type { StoreProfileFieldRequest } from '../models/StoreProfileFieldRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ProfileFieldsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Display all profile fields.
     * @returns any OK
     * @throws ApiError
     */
    public getProfileFields(): CancelablePromise<{
        data: Array<ProfileResource>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/profilefields',
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Create new profile sections with profile fields.
     * @returns any Created
     * @throws ApiError
     */
    public createProfileFields({
        requestBody,
    }: {
        /**
         * Sections and fields details
         */
        requestBody: StoreProfileFieldRequest,
    }): CancelablePromise<{
        data: Array<ProfileResource>;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/profilefields',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

}
