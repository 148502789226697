import { instanceToPlain, plainToInstance, Transform } from 'class-transformer'
import TithelySubscriptionPaymentMethod from '@/services/api/transformers/TithelySubscriptionPaymentMethod'
import type { TithelySubscription as Model } from '@/services/api/client'
import type { CamelCaseKeys } from '@/types/utility'

export type SerializedTithelySubscription = Model

export default class TithelySubscription implements CamelCaseKeys<SerializedTithelySubscription> {
    organizationId!: Model['organizationId']

    allAccessCustomer!: Model['allAccessCustomer']

    stripeCustomerId!: Model['stripeCustomerId']

    currencyCode!: Model['currencyCode']

    status!: Model['status']

    plan!: Model['plan']

    plans?: Array<Record<string, any>>

    paymentMethodId!: Model['paymentMethodId']

    billedFrom!: Model['billedFrom']

    currentBillingPeriod!: Model['currentBillingPeriod']

    referralBenefits!: Model['referralBenefits']

    products!: Model['products']

    discounts!: Model['discounts']

    outstanding!: Model['outstanding']

    @Transform(({ value }) => plainToInstance(TithelySubscriptionPaymentMethod, value), {
        toClassOnly: true
    })
    @Transform(({ value }) => instanceToPlain(value), { toPlainOnly: true })
    paymentMethod!: TithelySubscriptionPaymentMethod

    constructor(values: TithelySubscription) {
        Object.assign(this, values)
    }
}
