/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EventInstanceResource } from '../models/EventInstanceResource';
import type { EventResource } from '../models/EventResource';
import type { EventTagResource } from '../models/EventTagResource';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class EventsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create a new event.
     * @returns any Created
     * @throws ApiError
     */
    public createEvent({
        requestBody,
    }: {
        requestBody?: {
            /**
             * The name of the event
             */
            name?: string;
            /**
             * Calendar ID. Use 0 for the main calendar.
             */
            category_id?: number;
            /**
             * Start date and time of the event
             */
            start_datetime?: string;
            /**
             * End date and time of the event
             */
            end_datetime?: string;
            /**
             * Check-in mode
             */
            check_in?: 'everyone' | 'tags' | 'forms' | 'none';
            /**
             * When the mode is tags or forms, this is a list of ID's that will be used
             */
            check_in_array?: Array<number>;
            /**
             * Key/Value array of the fields used for the event instances. Please see event instances.
             */
            event_fields?: Array<{
                /**
                 * The name of the field
                 */
                name?: string;
                /**
                 * The value of the field
                 */
                value?: any;
            }>;
        },
    }): CancelablePromise<{
        data: EventInstanceResource;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/events',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Get a tag for an event.
     * @returns any OK
     * @throws ApiError
     */
    public getEventTag({
        eventId,
        id,
    }: {
        /**
         * Event ID
         */
        eventId: number,
        /**
         * ID of the retrieved resource.
         */
        id: number,
    }): CancelablePromise<{
        data: Array<EventTagResource>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/events/{eventId}/tags/{id}',
            path: {
                'eventId': eventId,
                'id': id,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Get event.
     * @returns any OK
     * @throws ApiError
     */
    public getEvent({
        id,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
    }): CancelablePromise<{
        data: EventResource;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/events/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Delete event.
     * @returns any Accepted
     * @throws ApiError
     */
    public deleteEvent({
        id,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
    }): CancelablePromise<{
        data: EventResource;
    }> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/events/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Get all tags for an event.
     * @returns any OK
     * @throws ApiError
     */
    public getEventTags({
        id,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
    }): CancelablePromise<{
        data: Array<EventTagResource>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/events/{id}/tags',
            path: {
                'id': id,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

}
