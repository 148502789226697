/**
 * Paths that do not require authentication or GTM.
 */
const PUBLIC_ROUTES = [
    `/ajax/check_subdomain_exists`,
    `/app/connect`,
    `/cancel_recurring`,
    `/events/blockout_dates`,
    `/events/volunteer_rsvp`,
    `/events/volunteer_sign_up_sheet`,
    `/form/`,
    `/give`,
    `/invite`,
    `/login`,
    `/signup`,
    `/s/`,
    `/disabled`,
    `/errors/`
]

/**
 * Subdomains that we need to disable authentication for.
 * This is a subset of the reserved subdomains.
 */
const PUBLIC_SUBDOMAINS = [`api`, `app`, `www`]

export { PUBLIC_ROUTES, PUBLIC_SUBDOMAINS }
