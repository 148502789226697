/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { PeopleMetaEmail } from './PeopleMetaEmail';

export type MessageResource = {
    /**
     * Transform the resource into an array.
     */
    id?: number;
    oid?: number;
    message_template_id?: number;
    from_address?: string;
    from_person_id?: number;
    private?: number;
    type?: MessageResource.type;
    subject?: string;
    content?: string;
    tags?: Array<string>;
    status?: MessageResource.status;
    incoming?: boolean;
    send_at?: string;
    delayed_job_id?: string;
    files?: Array<string>;
    submitted_at?: string;
    processed_at?: string;
    created_at?: string;
    updated_at?: string;
    people?: Array<{
        id?: number;
        delivery_status?: string;
        delivered_at?: string;
        opened_at?: string;
        clicked_at?: string;
    }>;
    from_person?: {
        id?: number;
        first_name?: string;
        last_name?: string;
        people_meta_email?: PeopleMetaEmail;
    };
};

export namespace MessageResource {

    export enum type {
        SMS = 'sms',
        UNLAYER = 'unlayer',
        HTML = 'html',
        TEXT = 'text',
    }

    export enum status {
        DRAFT = 'draft',
        READY = 'ready',
        RENDERING = 'rendering',
        PROCESSED = 'processed',
        FAILED = 'failed',
        BLOCKED = 'blocked',
        STALE = 'stale',
    }


}

