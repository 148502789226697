<script setup lang="ts">
import { ref } from 'vue'
import { SpButton } from '@tithely/sproutkit-vue'
import BaseModal from '@/components/base/BaseModal.vue'
import BaseModalHeader from '@/components/base/BaseModalHeader.vue'

withDefaults(
    defineProps<{
        cancelButton?: string
        confirmButton?: string
        confirmText: string
        danger?: boolean
        title?: string
    }>(),
    {
        cancelButton: `Cancel`,
        confirmButton: `Confirm`,
        danger: false,
        title: `Confirm`
    }
)

const emit = defineEmits<{
    confirm: [void]
}>()

const modal = ref<InstanceType<typeof BaseModal>>()

const close = () => {
    modal.value?.close()
}

const confirmFn = () => {
    emit(`confirm`)

    close()
}
</script>

<template>
    <BaseModal
        ref="modal"
        class="flex flex-col gap-4 p-4">
        <BaseModalHeader @close="close">
            <h2 class="text-2xl font-medium">{{ title }}</h2>
        </BaseModalHeader>

        <p class="text-base">{{ confirmText }}</p>

        <footer class="flex items-center justify-end gap-4">
            <SpButton
                intent="secondary"
                variant="subtle"
                @click="close">
                {{ cancelButton }}
            </SpButton>

            <SpButton
                :intent="danger ? `danger` : `primary`"
                @click="confirmFn">
                {{ confirmButton }}
            </SpButton>
        </footer>
    </BaseModal>
</template>
