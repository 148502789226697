/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiApplicationRequestResource } from '../models/ApiApplicationRequestResource';
import type { WildcardPermissions } from '../models/WildcardPermissions';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ApiApplicationsRequestsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get list of user's application requests.
     * @returns any OK
     * @throws ApiError
     */
    public listApiApplicationRequests(): CancelablePromise<{
        data: Array<ApiApplicationRequestResource>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api-auth/application-requests',
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Submit a new application request.
     * @returns any Created
     * @throws ApiError
     */
    public createApiApplicationRequest({
        requestBody,
    }: {
        requestBody?: {
            name: string;
            description: string;
            user_scoped: boolean;
            organization_limited: boolean;
            permissions: WildcardPermissions;
            contact_name: string;
            contact_email: string;
            contact_relation: string;
            request_comments: string;
        },
    }): CancelablePromise<{
        data: ApiApplicationRequestResource;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api-auth/application-requests',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Get a single API application.
     * @returns any OK
     * @throws ApiError
     */
    public getApiApplicationRequest({
        uuid,
    }: {
        /**
         * UUID of the retrieved resource.
         */
        uuid: string,
    }): CancelablePromise<{
        data: ApiApplicationRequestResource;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api-auth/application-requests/{uuid}',
            path: {
                'uuid': uuid,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Withdraw an application request.
     * If the application has been approved,
     * a ticket will be opened with CX and a conflict status will be returned.
     * @returns void
     * @throws ApiError
     */
    public deleteApiApplicationRequest({
        uuid,
    }: {
        /**
         * UUID of the retrieved resource.
         */
        uuid: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api-auth/application-requests/{uuid}',
            path: {
                'uuid': uuid,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
                409: `Conflict`,
            },
        });
    }

}
