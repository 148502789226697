/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SavedSearchResource } from '../models/SavedSearchResource';
import type { StoreSavedSearchRequest } from '../models/StoreSavedSearchRequest';
import type { UpdateSavedSearchRequest } from '../models/UpdateSavedSearchRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SavedSearchesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Display all saved searches filtering out fields that the user does not have permission to view.
     * @returns any OK
     * @throws ApiError
     */
    public getSavedSearches(): CancelablePromise<{
        data: Array<SavedSearchResource>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/savedsearches',
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Display the specified saved search.
     * @returns any OK
     * @throws ApiError
     */
    public getSavedSearch({
        id,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
    }): CancelablePromise<{
        data: SavedSearchResource;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/savedsearches/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Create a new saved search.
     * @returns any OK
     * @throws ApiError
     */
    public createSavedSearch({
        id,
        requestBody,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
        /**
         * Saved Search details.
         */
        requestBody: StoreSavedSearchRequest,
    }): CancelablePromise<{
        data: SavedSearchResource;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/savedsearches/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Delete the specified saved search.
     * @returns void
     * @throws ApiError
     */
    public deleteSavedSearch({
        id,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/savedsearches/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Update the specified saved search.
     * @returns any OK
     * @throws ApiError
     */
    public updateSavedSearch({
        id,
        requestBody,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
        /**
         * Saved Search details.
         */
        requestBody: UpdateSavedSearchRequest,
    }): CancelablePromise<{
        data: SavedSearchResource;
    }> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/savedsearches/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

}
