export default [
    {
        path: `/groups`,
        component: () => import('@/pages/groups/index.vue'),
        meta: {
            isVue: true
        }
    },
    {
        path: `/groups/:groupId`,
        component: () => import('@/pages/groups/Group.vue'),
        meta: {
            feature: `breeze-groups-admin`
        }
    }
]
