/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ServicePlanResource } from '../models/ServicePlanResource';
import type { ServicePlanTemplateResource } from '../models/ServicePlanTemplateResource';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ServicePlansTemplatesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Retrieve service plan templates.
     * @returns any OK
     * @throws ApiError
     */
    public getServicePlanTemplates(): CancelablePromise<{
        data: Array<ServicePlanTemplateResource>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/service-plan-templates',
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Retrieve a service plan template
     * @returns any OK
     * @throws ApiError
     */
    public getServicePlanTemplate({
        id,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
    }): CancelablePromise<{
        data: ServicePlanResource;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/service-plan-templates/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

}
