export default [
    {
        path: `/dashboard`,
        component: () => import('@/pages/dashboard/index.vue'),
        meta: {
            isLegacyRoute: true,
            feature: `vue-dashboard`
        }
    }
]
