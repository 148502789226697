import type { RouteLocationNormalized } from 'vue-router'

export default [
    {
        path: `/invite/:key`,
        meta: {
            layout: `blank`
        },
        redirect: (to: RouteLocationNormalized) => {
            return { path: `/login/invite/${to.params.key}` }
        }
    },
    {
        path: `/login/invite/:key`,
        meta: {
            layout: `auth`
        },
        component: () => import('@/pages/login/CreateUser.vue')
    },
    {
        path: `/disabled`,
        meta: {
            layout: `blank`
        },
        component: () => import('@/pages/login/Disabled.vue')
    },
    {
        path: `/login`,
        meta: {
            layout: `auth`
        },
        children: [
            {
                path: ``,
                component: () => import('@/pages/login/Login.vue')
            },
            {
                path: `password`,
                component: () => import('@/pages/login/Password.vue')
            },
            {
                path: `change/:key?`,
                component: () => import('@/pages/login/Change.vue')
            },
            {
                path: `change_success`,
                redirect: {
                    path: `/login`,
                    query: {
                        message: `password_changed`
                    }
                }
            },
            {
                path: `create`,
                component: () => import('@/pages/login/CreateRequest.vue')
            },
            {
                path: `logout`,
                component: () => import('@/pages/login/Logout.vue')
            }
        ]
    },
    {
        path: `/oauth/authorize`,
        component: () => import(`@/pages/login/OAuthAuthorize.vue`),
        meta: {
            layout: `auth`
        }
    }
]
