/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { OnboardingInviteUsersRequest } from './OnboardingInviteUsersRequest';

export type OnboardingStepRequest = (OnboardingInviteUsersRequest | {
    /**
     * The type of onboarding step.
     */
    type?: OnboardingStepRequest.type;
});

export namespace OnboardingStepRequest {

    /**
     * The type of onboarding step.
     */
    export enum type {
        INVITE_USERS = 'invite_users',
    }


}

