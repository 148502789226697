import axiosInstance from '@/services/api/wrapper/axiosInstance'
import { request as __request } from '@/services/api/client/core/request'
import type { CancelablePromise } from '@/services/api/client'
import { BaseHttpRequest } from '@/services/api/client'
import type { ApiRequestOptions } from '@/services/api/client/core/ApiRequestOptions'
import type { OpenAPIConfig } from '@/services/api/client'

export class AxiosHttpWrapper extends BaseHttpRequest {
    constructor(config: OpenAPIConfig) {
        super(config)
    }

    public override request<T>(options: ApiRequestOptions): CancelablePromise<T> {
        return __request(this.config, options, axiosInstance)
    }
}
