import { useQuery } from '@tanstack/vue-query'
import { plainToInstance } from 'class-transformer'
import { computed, type Ref } from 'vue'
import { QUERY_KEYS } from '@/constants/query'
import useApi from '@/composables/api'
import PersonDetailResource from '@/services/api/transformers/PersonDetailResource'
import { TIME } from '@/constants/time'

const usePerson = (id: Ref, enabled?: Ref) => {
    const { api } = useApi()

    const { data, isLoading } = useQuery({
        queryKey: computed(() => QUERY_KEYS.person(id.value)), // Reactively compute the query key
        queryFn: () => api.people.getOnePerson({ id: id.value }).then(({ data }) => data), // Query function with reactive id
        select: data => plainToInstance(PersonDetailResource, data),
        staleTime: TIME.Minute(5),
        enabled: computed(() => (enabled ? enabled.value ?? true : true))
    })

    return { data, isLoading }
}

export default usePerson
