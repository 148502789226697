/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SongLibraryExportService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Handle the incoming request.
     * @returns binary OK
     * @throws ApiError
     */
    public exportSongLibrary({
        template,
    }: {
        /**
         * Export the template
         */
        template?: string,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/song-library/export',
            query: {
                'template': template,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

}
