<script lang="ts" setup>
import { computed, ref } from 'vue'
import { replace } from 'lodash'
import ExtensionDetails from '@/components/extensions/ExtensionDetails.vue'
import type { Nullable } from '@/types/utility'
import type IntegrationResource from '@/services/api/transformers/IntegrationResource'
import useExtensions from '@/queries/extensions'
import { useMe } from '@/queries/me'

const { data: me } = useMe()
const { isLoading: loadingExtensions } = useExtensions()
const isDisabled = computed(() => loadingStatus.value || loadingExtensions.value)

const detailsModal = ref<Nullable<InstanceType<typeof ExtensionDetails>>>(null)
const loadingStatus = ref(false)

const install = async (extension: IntegrationResource) => {
    window.open(`${window.location.origin}/extensions?name=${replace(extension?.name ?? ``, ``)}`, `_self`)
}

const open = async (extensionResource: IntegrationResource) => {
    // Check if tithely account is linked
    extensionResource.tithely && !me.value?.organization.tithelyOrganizationId
        ? install(extensionResource)
        : detailsModal.value?.open(extensionResource)
}

defineExpose({
    open
})
</script>

<template>
    <ExtensionDetails
        ref="detailsModal"
        :is-disabled="isDisabled"
        @install="install($event)"
        @manage="install($event)"
        @install-tithely="install($event)" />
</template>
