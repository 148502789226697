<script setup lang="ts">
import { ref } from 'vue'
import { SpButton } from '@tithely/sproutkit-vue'
import BaseModal from '@/components/base/BaseModal.vue'
import BaseModalHeader from '@/components/base/BaseModalHeader.vue'
import UserMfaAppAuth from '@/components/modals/UserMfaAppAuth.vue'
import UserMfaEmailAuth from '@/components/modals/UserMfaEmailAuth.vue'
import useApi from '@/composables/api'
import LoadingSpinner from '@/components/LoadingSpinner.vue'

const { api } = useApi()
const modal = ref<InstanceType<typeof BaseModal>>()
const userMfaAppAuth = ref<InstanceType<typeof UserMfaAppAuth>>()
const userMfaEmailAuth = ref<InstanceType<typeof UserMfaEmailAuth>>()
const loading = ref(false)

const close = () => {
    modal.value?.close()
}

const open = () => {
    modal.value?.open()
}

const mfaAppFlow = () => {
    loading.value = true

    api.authorizationLoggedInUser
        .requestTotpmfa()
        .then(response => {
            loading.value = false
            userMfaAppAuth.value?.open(response?.data?.uri)
        })
        .finally(() => {
            loading.value = false
        })

    close()
}

const mfaEmailFlow = () => {
    loading.value = true
    api.authorizationLoggedInUser
        .requestEmailMfa()
        .then(() => {
            loading.value = false
            userMfaEmailAuth.value?.open()
        })
        .finally(() => {
            loading.value = false
        })

    close()
}

defineExpose({
    open,
    close
})
</script>

<template>
    <BaseModal
        ref="modal"
        class="flex max-w-[420px] flex-col gap-4 !p-4">
        <BaseModalHeader @close="close">
            <h2 class="text-2xl font-medium">{{ $t(`user.mfa.modal.mfaSetup`) }}</h2>
        </BaseModalHeader>

        <p class="text-base">{{ $t(`user.mfa.modal.setup`) }}</p>
        <div class="align-center mt-7 flex justify-center gap-2">
            <SpButton
                v-if="!loading"
                type="button"
                @click="mfaEmailFlow"
                >{{ $t(`user.mfa.modal.enableEmailAuth`) }}</SpButton
            >

            <SpButton
                v-if="!loading"
                type="button"
                @click="mfaAppFlow"
                >{{ $t(`user.mfa.modal.enableAppAuth`) }}</SpButton
            >

            <LoadingSpinner
                v-if="loading"
                class="h-6" />
        </div>
    </BaseModal>

    <UserMfaAppAuth
        ref="userMfaAppAuth"
        @close="close" />

    <UserMfaEmailAuth
        ref="userMfaEmailAuth"
        @close="close" />
</template>
