/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type StripeCard = {
    /**
     * The ID of the card
     */
    id: string;
    /**
     * The type of object, always "card"
     */
    object: string;
    /**
     * City/District/Suburb/Town/Village
     */
    address_city: string | null;
    /**
     * Billing address country, if provided when creating card
     */
    address_country: string | null;
    /**
     * Address line 1 (Street address/PO Box/Company name)
     */
    address_line1: string | null;
    /**
     * If address_line1 was checked.
     */
    address_line1_check: StripeCard.address_line1_check | null;
    /**
     * Address line 2 (Apartment/Suite/Unit/Building)
     */
    address_line2: string | null;
    /**
     * State/County/Province/Region
     */
    address_state: string | null;
    /**
     * ZIP or postal code
     */
    address_zip: string | null;
    /**
     * If address_zip was checked
     */
    address_zip_check: StripeCard.address_zip_check | null;
    /**
     * Card brand.
     */
    brand: StripeCard.brand | null;
    /**
     * Two-letter ISO code representing the country of the card.
     */
    country: string;
    /**
     * The customer the card belongs to.
     */
    customer: string;
    /**
     * If a CVC was provided, results of the check.
     */
    cvc_check: StripeCard.cvc_check;
    /**
     * (For tokenized numbers only.) The last four digits of the device account number.
     */
    dynamic_last4: string | null;
    /**
     * Two-digit number representing the card's expiration month.
     */
    exp_month: number;
    /**
     * Four-digit number representing the card's expiration year.
     */
    exp_year: number;
    /**
     * Uniquely identifies this particular card number.
     */
    fingerprint: string;
    /**
     * Card funding type.
     */
    funding: StripeCard.funding;
    /**
     * The last four digits of the card.
     */
    last4: string;
    /**
     * Set of key-value pairs that you can attach to an object.
     */
    metadata: Record<string, any>;
    /**
     * Cardholder name.
     */
    name: string | null;
    /**
     * If the card number is tokenized, this is the method that was used.
     */
    tokenization_method: string | null;
    /**
     * The card's type, e.g. Apple Pay, Google Pay, or Samsung Pay.
     */
    wallet: string | null;
};

export namespace StripeCard {

    /**
     * If address_line1 was checked.
     */
    export enum address_line1_check {
        PASS = 'pass',
        FAIL = 'fail',
        UNAVAILABLE = 'unavailable',
        UNCHECKED = 'unchecked',
    }

    /**
     * If address_zip was checked
     */
    export enum address_zip_check {
        PASS = 'pass',
        FAIL = 'fail',
        UNAVAILABLE = 'unavailable',
        UNCHECKED = 'unchecked',
    }

    /**
     * Card brand.
     */
    export enum brand {
        AMERICAN_EXPRESS = 'American Express',
        DINERS_CLUB = 'Diners Club',
        DISCOVER = 'Discover',
        JCB = 'JCB',
        MASTER_CARD = 'MasterCard',
        UNION_PAY = 'UnionPay',
        VISA = 'Visa',
        UNKNOWN = 'Unknown',
    }

    /**
     * If a CVC was provided, results of the check.
     */
    export enum cvc_check {
        PASS = 'pass',
        FAIL = 'fail',
        UNAVAILABLE = 'unavailable',
        UNCHECKED = 'unchecked',
    }

    /**
     * Card funding type.
     */
    export enum funding {
        CREDIT = 'credit',
        DEBIT = 'debit',
        PREPAID = 'prepaid',
        UNKNOWN = 'unknown',
    }


}

