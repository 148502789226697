/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CombinedSearchResultResource } from '../models/CombinedSearchResultResource';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SongLibraryCombinedSearchService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Combined search
     * Return combined search results from Song Select and local songs.
     * @returns any OK
     * @throws ApiError
     */
    public combinedSearch({
        searchTerm,
        source,
    }: {
        /**
         * Search term, can be a song name or CCLI number.
         */
        searchTerm: string,
        /**
         * Filter by source. song_select or song_library. Blank for both
         */
        source?: 'song_select' | 'song_library',
    }): CancelablePromise<{
        data: Array<CombinedSearchResultResource>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/song-library/search',
            query: {
                'searchTerm': searchTerm,
                'source': source,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

}
