<script lang="ts" setup>
import { computed, type PropType, ref } from 'vue'
import { filter, find } from 'lodash'
import BIcon from '@/components/BIcon.vue'
import type { SideNavItem } from '@/types/sideNav'
import type { Nullable } from '@/types/utility'

const props = defineProps({
    nav: {
        type: Array as PropType<SideNavItem[]>
    }
})

const expanded = ref(false)

const current = (itemRoute: string) => itemRoute === window.location.pathname

const getCurrent = computed((): Nullable<SideNavItem> => find(props.nav, item => current(item.route)) || null)
const mobileNav = computed((): SideNavItem[] =>
    getCurrent.value
        ? filter(props.nav, (item: SideNavItem) => !current(item.route)) as SideNavItem[] || []
        : props.nav as SideNavItem[]
)
const expandedIconClasses = computed(() => {
    const classList = [`h-6`, `w-6`, `!ml-auto`, `origin-center`, `transition-transform`]

    if (expanded.value) {
        classList.push(`rotate-180`)
    }

    return classList
})

const itemClasses = [
    `flex`,
    `flex-row`,
    `items-center`,
    `gap-4`,
    `h-[50px]`,
    `!px-[22px]`,
    `text-primary`,
    `text-base/none`,
    `font-bold`
]

const linkClasses = [...itemClasses, `opacity-40`, `hover:opacity-100`, `transition-opacity`]
</script>

<template>
    <div class="z-50 flex flex-col bg-secondary-50 print:hidden lg:hidden">
        <button
            :class="itemClasses"
            @click="expanded = !expanded">
            <BIcon
                v-if="getCurrent"
                :icon="getCurrent.icon"
                class="h-6 w-6" />

            {{ getCurrent ? getCurrent.label : `Menu` }}

            <BIcon
                icon="chevron-down"
                :class="expandedIconClasses" />
        </button>

        <Transition
            enter-active-class="transition-all"
            leave-active-class="transition-all"
            enter-from-class="-translate-y-4 opacity-0"
            leave-to-class="-translate-y-4 opacity-0">
            <div
                v-if="expanded"
                class="relative w-full">
                <ul class="absolute top-0 w-full bg-secondary-50 shadow-lg">
                    <li
                        v-for="item in mobileNav"
                        :key="item.route">
                        <a
                            :href="item.route"
                            :class="linkClasses">
                            <BIcon
                                :icon="item.icon"
                                class="h-6 w-6" />
                            {{ item.label }}
                        </a>
                    </li>
                </ul>
            </div>
        </Transition>
    </div>
</template>
