/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IntegrationExtendedResource } from '../models/IntegrationExtendedResource';
import type { IntegrationResource } from '../models/IntegrationResource';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class IntegrationsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get available integrations.
     * @returns any OK
     * @throws ApiError
     */
    public getIntegrations(): CancelablePromise<{
        data: Array<IntegrationResource>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/integrations',
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Display the specified resource.
     * @returns any OK
     * @throws ApiError
     */
    public getIntegration({
        id,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
    }): CancelablePromise<{
        data: IntegrationExtendedResource;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/integrations/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Run an action on the specified integration.
     * @returns void
     * @throws ApiError
     */
    public runIntegrationAction({
        id,
        action,
        requestBody,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
        /**
         * The action to run. Always can be enable or disable.
         */
        action: string,
        requestBody?: Record<string, any>,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/integrations/{id}/{action}',
            path: {
                'id': id,
                'action': action,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

}
