import showEmailEditor from '@/functions/emailEditor'

export default [
    {
        path: `/history`,
        component: () => import('@/pages/history/index.vue'),
        meta: {
            isVue: true,
            sideNav: `history`
        },
        children: [
            {
                path: 'people',
                component: () => import('@/pages/history/People.vue'),
                meta: {
                    isVue: true,
                    isLegacyRoute: true,
                    feature: `vue-history-people`,
                    sideNav: `history`
                }
            },
            {
                path: 'tags',
                component: () => import('@/pages/history/Tags.vue'),
                meta: {
                    isVue: true,
                    isLegacyRoute: true,
                    feature: `vue-history-tags`,
                    sideNav: `history`
                }
            },
            {
                path: 'events',
                component: () => import('@/pages/history/Events.vue'),
                meta: {
                    isVue: true,
                    isLegacyRoute: true,
                    feature: `vue-history-events`
                }
            },
            {
                path: 'email',
                component: () => import('@/pages/history/Email.vue'),
                meta: {
                    isVue: true,
                    isLegacyRoute: true,
                    feature: `vue-history-email`
                }
            },
            {
                path: 'text',
                component: () => import('@/pages/history/Text.vue'),
                meta: {
                    isVue: true,
                    isLegacyRoute: true,
                    feature: `vue-history-text`
                }
            }
        ]
    },
    ...(showEmailEditor()
        ? [
              {
                  path: `/history/email`,
                  component: () => import('@/pages/history/Email.vue')
              }
          ]
        : [])
]
