/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LoginResponse } from '../models/LoginResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AuthorizationTokensService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Refreshes the user's token.
     * @returns LoginResponse OK
     * @throws ApiError
     */
    public refreshToken(): CancelablePromise<LoginResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/refresh',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Get user's access token using session key.
     * Convert a session key into a JWT token.
     * Called by UI when access token is missing but session key is not.
     * @returns LoginResponse OK
     * @throws ApiError
     */
    public getTokenFromSessionKey({
        requestBody,
    }: {
        /**
         * Return user's access token.
         */
        requestBody: {
            session_key: string;
        },
    }): CancelablePromise<LoginResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/sessionkey',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
            },
        });
    }

}
