<script setup lang="ts">
import { ref } from 'vue'
import QRCode from 'qrcode'
import { useI18n } from 'vue-i18n'
import { map } from 'lodash'
import { SpButton } from '@tithely/sproutkit-vue'
import BaseModal from '@/components/base/BaseModal.vue'
import BaseModalHeader from '@/components/base/BaseModalHeader.vue'
import VerificationInput from '@/pages/login/components/VerificationInput.vue'
import useApi from '@/composables/api'
import { useMe } from '@/queries/me'

const { refetch } = useMe()
const i18n = useI18n()
const { api } = useApi()
const modal = ref<InstanceType<typeof BaseModal>>()
const qrCodeImg = ref(``)
const disabled = ref(false)
const error = ref(``)
const recoveryCodes = ref<Array<string | undefined>>([])
const code = ref(``)
const secret = ref(``)
const displaySecret = ref(false)

const verify = () => {
    disabled.value = true

    api.authorizationLoggedInUser
        .enableTotpmfa({ requestBody: { code: code.value } })
        .then(response => {
            refetch()
            recoveryCodes.value = map(response?.data?.recovery_codes, ({ code }) => {
                return code
            })
        })
        .catch(err => {
            error.value =
                err?.response?.data?.data?.message ||
                err?.response?.data?.error ||
                i18n.t(`user.mfa.modal.errorProcessing`)

            disabled.value = false
        })
}

const close = () => {
    modal.value?.close()
}

const open = (uri: string) => {
    recoveryCodes.value = []

    // convert uri to QRcode to scan
    QRCode.toDataURL(uri).then(url => {
        qrCodeImg.value = url
    })

    secret.value = new URLSearchParams(uri).get('secret') || ``
    modal.value?.open()
}

const downloadRecoveryCode = () => {
    const link = document.createElement('a')
    const blob = new Blob([recoveryCodes.value.join(`\n`)], { type: `text/plain` })

    link.href = URL.createObjectURL(blob)
    link.download = 'MFA-Recovery-Code.txt'
    link.click()
    URL.revokeObjectURL(link.href)
}

defineExpose({
    open,
    close
})
</script>

<template>
    <BaseModal
        ref="modal"
        size="md"
        class="flex flex-col gap-4 !p-4">
        <BaseModalHeader @close="close">
            <h2 class="text-2xl font-medium">{{ $t(`user.mfa.modal.mfaSetup`) }}</h2>
        </BaseModalHeader>

        <div
            v-if="recoveryCodes.length === 0"
            class="align-center flex flex-col content-center gap-2 text-center">
            <p class="text-base">{{ $t(`user.mfa.modal.scanQrCode`) }}</p>

            <img
                :src="qrCodeImg"
                v-if="!displaySecret"
                class="m-auto max-w-[300px]"
                alt="QR Code" />

            <div
                class="align-center flex h-[260px]"
                v-else>
                {{ secret }}
            </div>

            <SpButton
                type="button"
                variant="secondary"
                size="xs"
                @click="displaySecret = !displaySecret"
                >{{ displaySecret ? $t(`user.mfa.modal.hideSecret`) : $t(`user.mfa.modal.showSecret`) }}</SpButton
            >

            <p class="text-base">{{ $t(`user.mfa.modal.enterCode`) }}</p>

            <VerificationInput
                ref="verificationInput"
                :disabled="disabled"
                @submit="verify"
                @complete="(newCode: string) => (code = newCode)" />

            <div
                v-if="error"
                class="text-center text-danger-600">
                {{ error }}
            </div>

            <div class="align-center mt-4 flex justify-center gap-2">
                <SpButton
                    :disabled="disabled"
                    type="button"
                    @click="verify"
                    >{{ $t(`user.mfa.modal.verify`) }}</SpButton
                >
            </div>
        </div>

        <div v-else>
            <p class="text-base">{{ $t(`user.mfa.modal.recoveryCodes`) }}</p>
            <ul class="m-4 columns-2">
                <li
                    v-for="recoveryCode in recoveryCodes"
                    :key="recoveryCode"
                    class="text-center text-2xl">
                    {{ recoveryCode }}
                </li>
            </ul>

            <div class="align-center mt-12 flex justify-center gap-2">
                <SpButton
                    type="button"
                    block
                    class="m-auto"
                    @click="downloadRecoveryCode"
                    >{{ $t(`user.mfa.modal.downloadRecoveryCode`) }}</SpButton
                >
            </div>
        </div>
    </BaseModal>
</template>
