/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CalendarResource } from '../models/CalendarResource';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class EventsCalendarsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get all calendars.
     * @returns any OK
     * @throws ApiError
     */
    public getCalendars({
        filter,
    }: {
        /**
         *
         * Filtered fields:
         * | Field | Field Type | Description |
         * |--|--|--|
         * |name|text||
         *
         * See **Filters** above for more details about using filters.
         */
        filter?: string,
    }): CancelablePromise<{
        data: Array<CalendarResource>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/events/calendars',
            query: {
                'filter': filter,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Create a new calendar.
     * @returns any Created
     * @throws ApiError
     */
    public createCalendar({
        requestBody,
    }: {
        requestBody?: {
            /**
             * The name of the calendar
             */
            name: string;
            /**
             * The hexadecimal color of the calendar. # is not required.
             */
            color: string;
            /**
             * The address of the external calendar
             */
            address?: string;
        },
    }): CancelablePromise<{
        data: CalendarResource;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/events/calendars',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Get the embed hash for a calendar.
     * Encode a list of calendars into a decodable string.
     *
     * Required Param: 'calendars' either:
     * 1) a json encoded array of calendar ids to embed, ie '[1,2,4]'
     * 2) the string 'all'
     * @returns any OK
     * @throws ApiError
     */
    public getCalendarHash({
        calendarIds,
    }: {
        calendarIds: (string | 'all'),
    }): CancelablePromise<{
        data: string;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/events/calendars/{calendarIds}/embed',
            path: {
                'calendarIds': calendarIds,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Soft delete a calendar.
     * When you delete a calendar, it deletes the EventInstance, so
     * all the volunteers and other records are orphaned - which leaves them available
     * for specific reports but does not show them as conflicting when being scheduled
     * for new events.
     * @returns any Accepted
     * @throws ApiError
     */
    public deleteCalendar({
        id,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
    }): CancelablePromise<{
        data: CalendarResource;
    }> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/events/calendars/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Update a calendar.
     * @returns any OK
     * @throws ApiError
     */
    public updateCalendar({
        id,
        requestBody,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
        requestBody?: {
            /**
             * The name of the calendar
             */
            name: string;
            /**
             * The hexadecimal color of the calendar. # is not required.
             */
            color: string;
        },
    }): CancelablePromise<{
        data: CalendarResource;
    }> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/events/calendars/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Get the external events for a calendar.
     * @returns any OK
     * @throws ApiError
     */
    public ecc5Ef5487A3Bc51929C9C94160A869({
        id,
        filter,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
        /**
         *
         * Filtered fields:
         * | Field | Field Type | Description |
         * |--|--|--|
         * |start_datetime|date|Only supports `gt` and `lt`.|
         *
         * See **Filters** above for more details about using filters.
         */
        filter?: string,
    }): CancelablePromise<{
        /**
         * The events for the calendar
         */
        events?: Array<Record<string, any>>;
        calendar_id?: number;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/events/external/{id}',
            path: {
                'id': id,
            },
            query: {
                'filter': filter,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

}
