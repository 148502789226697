/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OAuth20Service {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Used by the UI to authorize a code.
     * Must be logged in.
     * Returns URI to redirect to.
     * @returns any OK
     * @throws ApiError
     */
    public oAuthAcceptRequest({
        code,
    }: {
        /**
         * The authorization code
         */
        code: string,
    }): CancelablePromise<{
        data: {
            /**
             * The URI to redirect to.
             */
            uri: string;
        };
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/oauth/accept',
            query: {
                'code': code,
            },
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * API Token Authorization.
     * The client app will open a browser to this endpoint.
     * Returns request_code and application [id] in query.
     * @returns void
     * @throws ApiError
     */
    public oAuthAuthorizeStart({
        responseType,
        clientId,
        redirectUri,
        subdomain,
        state,
        codeChallengeMethod,
        codeChallenge,
    }: {
        /**
         * The response type
         */
        responseType: 'code',
        /**
         * The application ID
         */
        clientId: string,
        /**
         * The URI to redirect to after authorization.
         */
        redirectUri: string,
        /**
         * The organization subdomain
         */
        subdomain: string,
        /**
         * The state token
         */
        state?: string,
        /**
         * The code challenge method (Required if code_challenge is used
         */
        codeChallengeMethod?: 'S256',
        /**
         * The code challenge
         */
        codeChallenge?: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/oauth/authorize',
            query: {
                'response_type': responseType,
                'client_id': clientId,
                'redirect_uri': redirectUri,
                'state': state,
                'subdomain': subdomain,
                'code_challenge_method': codeChallengeMethod,
                'code_challenge': codeChallenge,
            },
            errors: {
                302: `Redirect`,
            },
        });
    }

    /**
     * Client Callback. Will request the actual token from the provider
     * before redirecting back to the Breeze UI.
     * This is mainly used for integrations.
     * @returns void
     * @throws ApiError
     */
    public d51F22B46E8A2Eed3378C9E6Ff6B2823({
        state,
        code,
    }: {
        /**
         * The state token
         */
        state: string,
        /**
         * The authorization code
         */
        code: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/oauth/callback',
            query: {
                'state': state,
                'code': code,
            },
            errors: {
                302: `Redirect`,
            },
        });
    }

    /**
     * Convert Code to Token.
     * Called by the application to get the token.
     * Can use POST request as well.
     * @returns any OK
     * @throws ApiError
     */
    public oAuthGetToken({
        clientId,
        code,
        grantType,
        clientSecret,
        codeVerifier,
    }: {
        /**
         * The application ID
         */
        clientId: string,
        /**
         * The authorization code
         */
        code: string,
        /**
         * The grant type
         */
        grantType: 'authorization_code',
        /**
         * The application secret (for private apps)
         */
        clientSecret?: string,
        /**
         * The code verifier
         */
        codeVerifier?: string,
    }): CancelablePromise<{
        /**
         * The access token to use as the password
         */
        access_token: string;
        /**
         * The token type
         */
        token_type: 'access_token';
        /**
         * The time the token will expire
         */
        expires_in?: string;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/oauth/token',
            query: {
                'client_id': clientId,
                'client_secret': clientSecret,
                'code': code,
                'grant_type': grantType,
                'code_verifier': codeVerifier,
            },
        });
    }

}
