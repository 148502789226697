/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SongtagResource } from '../models/SongtagResource';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SongLibrarySongtagsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Display a listing of the resource.
     * @returns any OK
     * @throws ApiError
     */
    public getSongtags({
        search,
    }: {
        /**
         * Search for lookup
         */
        search?: string,
    }): CancelablePromise<{
        data: Array<SongtagResource>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/song-library/songtags',
            query: {
                'search': search,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

}
