import mitt from 'mitt'

const useEventBus = () => {
    if (!window.eventBus) {
        window.eventBus = mitt()
    }

    const { on, off, emit } = window.eventBus

    return { on, off, emit }
}

export default useEventBus
