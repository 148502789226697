/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GroupResource } from '../models/GroupResource';
import type { StoreGroupMembershipRequest } from '../models/StoreGroupMembershipRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class GroupsMembershipService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Establish a new member in the group.
     * @returns any Created
     * @throws ApiError
     */
    public createGroupMembership({
        group,
        person,
        requestBody,
    }: {
        /**
         * ID of the group.
         */
        group: number,
        /**
         * ID of the person.
         */
        person: number,
        /**
         * Group membership details
         */
        requestBody: StoreGroupMembershipRequest,
    }): CancelablePromise<{
        data: GroupResource;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/groups/{group}/members/{person}',
            path: {
                'group': group,
                'person': person,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Remove the specified member from the group
     * @returns void
     * @throws ApiError
     */
    public deleteGroupMembership({
        group,
        person,
    }: {
        /**
         * ID of the group.
         */
        group: number,
        /**
         * ID of the person.
         */
        person: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/groups/{group}/members/{person}',
            path: {
                'group': group,
                'person': person,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Change the status and leader flag for a member of a group.
     * @returns any OK
     * @throws ApiError
     */
    public updateGroupMembership({
        group,
        person,
        requestBody,
    }: {
        /**
         * ID of the group.
         */
        group: number,
        /**
         * ID of the person.
         */
        person: number,
        /**
         * Group membership details
         */
        requestBody: StoreGroupMembershipRequest,
    }): CancelablePromise<{
        data: GroupResource;
    }> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/groups/{group}/members/{person}',
            path: {
                'group': group,
                'person': person,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

}
