import { Expose } from 'class-transformer'
import type { IntegrationResource as Model } from '@/services/api/client'
import type { CamelCaseKeys } from '@/types/utility'

export type SerializedIntegrationResource = Model

export default class IntegrationResource implements CamelCaseKeys<SerializedIntegrationResource> {
    id!: Model['id']

    name!: Model['name']

    @Expose({ name: 'display_name' })
    displayName!: Model['display_name']

    description!: Model['description']

    enabled!: Model['enabled']

    @Expose({ name: 'builder_io_id' })
    builderIoId!: Model['builder_io_id']

    tithely!: Model['tithely']

    sort?: Model['sort']

    category!: Model['category']

    @Expose({ name: 'short_name' })
    shortName!: Model['short_name']

    @Expose({ name: 'third_party_url' })
    thirdPartyUrl!: Model['third_party_url']

    constructor(values: IntegrationResource) {
        Object.assign(this, values)
    }
}
