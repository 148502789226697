const FILESIZE = {
    Byte(multiplier = 1) {
        return multiplier
    },
    Kilobyte(multiplier = 1) {
        return this.Byte() * 1024 * multiplier
    },
    Megabyte(multiplier = 1) {
        return this.Kilobyte() * 1024 * multiplier
    },
    Gigabyte(multiplier = 1) {
        return this.Megabyte() * 1024 * multiplier
    },
    Terabyte(multiplier = 1) {
        return this.Gigabyte() * 1024 * multiplier
    }
}

export default FILESIZE
