/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { HistoryResource } from '../models/HistoryResource';
import type { StoreHistoryRequest } from '../models/StoreHistoryRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class HistoryService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Creates a new history item.
     * @returns any OK
     * @throws ApiError
     */
    public storeHistory({
        requestBody,
    }: {
        /**
         * Email payload
         */
        requestBody: StoreHistoryRequest,
    }): CancelablePromise<{
        data: HistoryResource;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/history',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Display all history of type.
     * @returns any OK
     * @throws ApiError
     */
    public getHistory({
        type,
        limit,
    }: {
        /**
         * Type of history items to return
         */
        type: string,
        /**
         * The number of history items to return.
         */
        limit?: number,
    }): CancelablePromise<{
        data: Array<HistoryResource>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/history/{type}',
            path: {
                'type': type,
            },
            query: {
                'limit': limit,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

}
