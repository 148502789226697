/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type MessageTemplateIndexResource = {
    /**
     * Transform the resource into an array.
     */
    id?: number;
    name?: string;
    subject?: string;
    content?: string;
    tags?: Array<string>;
    type?: MessageTemplateIndexResource.type;
    /**
     * Only returned with unlayer templates
     */
    thumbnail?: string;
    /**
     * Only returned with unlayer templates, scope of the template
     */
    scope?: string;
};

export namespace MessageTemplateIndexResource {

    export enum type {
        SMS = 'sms',
        UNLAYER = 'unlayer',
        HTML = 'html',
        TEXT = 'text',
    }


}

