/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type MessageRequestBase = {
    /**
     * Content of the message.
     */
    content?: string;
    /**
     * The message template to be used to generate email.
     */
    message_template_id?: number | null;
    /**
     * The address from.
     */
    from_address?: string;
    /**
     * The person id from.
     */
    from_person_id?: string | null;
    /**
     * Private message.
     */
    private?: boolean | null;
    /**
     * The .
     */
    send_at?: string | null;
    /**
     * The name of the message template.
     */
    status?: MessageRequestBase.status;
    /**
     * Subject of the message template.
     */
    subject?: string;
    /**
     * Tags of the message template.
     */
    tags?: Array<string>;
    /**
     * Type of the message template, ie unlayer, sms, html.
     */
    type?: MessageRequestBase.type;
};

export namespace MessageRequestBase {

    /**
     * The name of the message template.
     */
    export enum status {
        DRAFT = 'draft',
        READY = 'ready',
        RENDERING = 'rendering',
        PROCESSED = 'processed',
        FAILED = 'failed',
        BLOCKED = 'blocked',
        STALE = 'stale',
    }

    /**
     * Type of the message template, ie unlayer, sms, html.
     */
    export enum type {
        SMS = 'sms',
        UNLAYER = 'unlayer',
        HTML = 'html',
        TEXT = 'text',
    }


}

