<script lang="ts" setup>
import { computed, type PropType } from 'vue'
import { useRoute } from 'vue-router'
import { filter, intersection, map, replace, size } from 'lodash'
import SIDE_NAV from '@/constants/nav/side'
import { useMe } from '@/queries/me'
import SideNavMobile from '@/layouts/components/SideNavMobile.vue'
import SideNavMenu from '@/layouts/components/SideNavMenu.vue'
import type { SideNavItem, SideNavLocation } from '@/types/sideNav'

const route = useRoute()
const { data: me } = useMe()

const features = computed(() => me.value?.features ?? [])
const roles = computed(() => me.value?.roles ?? [])

const props = defineProps({
    menu: {
        type: String as PropType<SideNavLocation>,
        required: true
    }
})

const filterRoles = computed(() =>
    filter(SIDE_NAV[props.menu], (item: SideNavItem) =>
        item.roles ? Boolean(size(intersection(item.roles, roles.value))) : true
    )
)

const filterFeatures = computed(() =>
    filter(filterRoles.value, (item: SideNavItem) =>
        item.features ? Boolean(size(intersection(item.features, features.value))) : true
    )
)

const sideNav = computed(() =>
    map(filterFeatures.value, item => {
        switch (props.menu) {
            case `events`:
                return {
                    ...item,
                    route: replace(item.route, `{instanceId}`, route.params?.instanceId as string || ``)
                }
            default:
                return item
        }
    })
)
</script>

<template>
    <SideNavMobile :nav="sideNav" />
    <SideNavMenu :nav="sideNav" />
</template>
