import { ref, type Ref } from 'vue'

type Filter = null | string | number | boolean | string[] | number[] | boolean[]

interface Filters {
    [key: string]: Filter | Filter[] | Filters | Filters[]
}

const createQueryKeyFactory = (key: string[]) => ({
    all: [...key] as const,
    lists: () => [...key, 'list'] as const,
    list: (filters: Ref<Filters> = ref({})) => [...key, 'list', filters] as const,
    details: () => [...key, 'detail'] as const,
    detail: (id?: Ref<string | number>) => [...key, 'detail', id] as const
})

export { createQueryKeyFactory }

export type { Filters }
