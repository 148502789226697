/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PreferenceResource } from '../models/PreferenceResource';
import type { StorePreferenceRequest } from '../models/StorePreferenceRequest';
import type { UpdatePreferenceRequest } from '../models/UpdatePreferenceRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PreferencesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getPreferences({
        search,
        preferenceType,
        name,
    }: {
        /**
         * Search preferences by type and name.
         */
        search?: string,
        /**
         * Preference Type
         */
        preferenceType?: string,
        /**
         * Preference Name
         */
        name?: string,
    }): CancelablePromise<{
        data: Array<PreferenceResource>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/preferences',
            query: {
                'search': search,
                'preference-type': preferenceType,
                'name': name,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public createPreference({
        requestBody,
    }: {
        /**
         * Preference details
         */
        requestBody: StorePreferenceRequest,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/preferences',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public getPreference({
        identifier,
    }: {
        /**
         * The preference identifier, can be either an ID (integer) or a slug (string)
         */
        identifier: (number | string),
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/preferences/{identifier}',
            path: {
                'identifier': identifier,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public updatePreference({
        identifier,
        requestBody,
    }: {
        /**
         * The preference identifier, can be either an ID (integer) or a slug (string)
         */
        identifier: (number | string),
        /**
         * Preference details
         */
        requestBody: UpdatePreferenceRequest,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/preferences/{identifier}',
            path: {
                'identifier': identifier,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deletePreference({
        id,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/preferences/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

}
