export default {
    mfa: {
        modal: {
            mfaTitle: `Multi-Factor Authentication`,
            codeSentEmail: `Please enter the code that was sent to your email: %{email}. This code will expire in 30 minutes.`,
            codeCheckAuthenticator: `Please enter the code from your authenticator app`,
            verify: `Verify`,
            resendEmail: `Resend email`,
            trustedDevice: `Skip 2FA for 14 days on this device`,
            useRecoveryCode: `Use recovery code`,
            invalidCode: `Invalid code please check your code.`,
            error: {
                invalidCode: `Invalid code. Try Again.`,
                expired: `This code has expired. Try again.`
            }
        },
        recovery: {
            title: `Multi-Factor Recovery Code`,
            description: `Enter one of the recovery codes that were generated when setting up multi-factor authentication. Note: Each recovery code can only be used once.`,
            login: `Login`
        },
        forgot: {
            resetLink: `The password reset link is invalid or has expired. Please request a new link.`,
            errorChangingPassword: `An error occurred while changing your password. If the issue persists, please contact support.`,
            passwordRequirements: `Check that the password meets all our requirements.`,
            mfaCodeInvalid: `Code invalid or has expired.`
        }
    }
}
