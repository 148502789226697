/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ScoredTithelyOrgInfo } from '../models/ScoredTithelyOrgInfo';
import type { TithelySubscription } from '../models/TithelySubscription';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TithelyService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get offer details for bundle.
     * @returns any OK
     * @throws ApiError
     */
    public getBundleOffer(): CancelablePromise<{
        data: {
            /**
             * The monthly fee of the integration.
             */
            monthlyFee?: number;
            /**
             * The setup fee of the integration.
             */
            setupFee?: number | null;
            /**
             * Whether or not the setup fee is waived.
             */
            setupFeeWaived?: boolean;
            /**
             * The currency code of the integration.
             */
            currencyCode?: string;
        };
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/tithely/bundle',
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Apply the bundle offer to the current subscription.
     * @returns void
     * @throws ApiError
     */
    public addBundleOffer(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/tithely/bundle',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Create a new Tithe.ly Organization linked to the current organization.
     * @returns void
     * @throws ApiError
     */
    public createTithelyOrganization(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/tithely/create',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
                409: `Already linked`,
            },
        });
    }

    /**
     * Fetches list of tithe.ly organizations that may match the current organization.
     * Location is not included, as that will be taken care of by the link() method.
     * @returns any OK
     * @throws ApiError
     */
    public findTithelyOrgByScore(): CancelablePromise<{
        data: Array<ScoredTithelyOrgInfo>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/tithely/find',
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Check if linked
     * @returns any OK
     * @throws ApiError
     */
    public checkTithelyLink(): CancelablePromise<{
        data: {
            /**
             * Whether or not the organization is linked
             */
            linked: boolean;
        };
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/tithely/link',
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Start the linking process
     * @returns any OK
     * @throws ApiError
     */
    public startTithelyLink({
        requestBody,
    }: {
        requestBody?: {
            /**
             * The target URL. Will be appended with ?success or ?failure=message, with message being the failure message
             */
            target: string;
        },
    }): CancelablePromise<{
        data: {
            /**
             * The link to the Tithely login page
             */
            link: string;
        };
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/tithely/link',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
                409: `Already linked`,
            },
        });
    }

    /**
     * Update link
     * @returns any OK
     * @throws ApiError
     */
    public updateTithelyLink(): CancelablePromise<{
        data: {
            /**
             * Whether or not the organization was linked
             */
            linked: boolean;
            /**
             * Error Message
             */
            message?: string;
        };
    }> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/tithely/link',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
                409: `Already linked`,
            },
        });
    }

    /**
     * Migrate billing to Tithe.ly
     * @returns void
     * @throws ApiError
     */
    public migrateTithelyBilling(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/tithely/migrate',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
                409: `Already linked`,
            },
        });
    }

    /**
     * Get subscription info from Tithe.ly
     * @returns any OK
     * @throws ApiError
     */
    public getTithelySubscription(): CancelablePromise<{
        data: TithelySubscription;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/tithely/subscription',
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Subscription does not exist.`,
            },
        });
    }

    /**
     * Synchronize billing with Tithe.ly
     * @returns void
     * @throws ApiError
     */
    public syncTithelyBilling(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/tithely/sync',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

}
