/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SongVersionResource } from '../models/SongVersionResource';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SongLibrarySongsVersionsByIdsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Send a collection of just the song versions requested.
     * @returns any OK
     * @throws ApiError
     */
    public getSongVersionsByIds({
        ids,
    }: {
        /**
         * Array of song version IDs.
         */
        ids: Array<number>,
    }): CancelablePromise<{
        data: SongVersionResource;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/song-library/song-versions',
            query: {
                'ids': ids,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

}
