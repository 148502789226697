import type { RouteLocationNormalized } from 'vue-router'
import { includes, isArray, some, startsWith } from 'lodash'
import useApi from '@/composables/api'
import queryClient from '@/plugins/query'
import { QUERY_KEYS } from '@/constants/query'

const { api } = useApi()

export default async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    if (to.meta.feature) {
        const { data } = await queryClient.ensureQueryData({
            queryKey: QUERY_KEYS.me,
            queryFn: () => api.authorizationLoggedInUser.getMe()
        })
        const features = isArray(to.meta.feature) ? to.meta.feature : [to.meta.feature]

        // Check if user has ANY of the features enabled for the route
        // also check for optional negation "!" prefix to exclude a feature
        to.meta.hasFeature = some(features, (feature: string) =>
            startsWith(feature, '!') ? !includes(data?.features, feature.slice(1)) : includes(data?.features, feature)
        )

        if (!to.meta.hasFeature && to.meta.isLegacyRoute !== true) {
            return from
        }
    } else {
        to.meta.hasFeature = undefined
    }
}
