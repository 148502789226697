/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserFieldResource } from '../models/UserFieldResource';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserFieldsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get all user fields for the authenticated user
     * @returns any OK
     * @throws ApiError
     */
    public getUserFields(): CancelablePromise<{
        data: Array<UserFieldResource>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/fields',
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Create a new user field for the authenticated user
     * @returns UserFieldResource Created
     * @throws ApiError
     */
    public storeUserField({
        requestBody,
    }: {
        /**
         * Array of Tags to add to the group
         */
        requestBody: {
            field_id?: number;
            section?: string;
        },
    }): CancelablePromise<UserFieldResource> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/fields',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Delete a user field for the authenticated user
     * @returns UserFieldResource OK
     * @throws ApiError
     */
    public deleteUserFields({
        fieldId,
    }: {
        fieldId: number,
    }): CancelablePromise<UserFieldResource> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/users/fields/{fieldId}',
            path: {
                'fieldId': fieldId,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

}
