/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type EmailSendRequest = {
    /**
     * Attachments
     */
    attachments?: Array<(string | Record<string, string>)>;
    /**
     * Name of the email
     */
    name?: string | null;
    /**
     * Subject of email
     */
    subject?: string;
    /**
     * Status of email
     */
    status?: string;
    /**
     * Type of message
     */
    type?: EmailSendRequest.type;
    /**
     * BCC or Group
     */
    sent_as?: EmailSendRequest.sent_as;
    /**
     * Unlayer JSON
     */
    unlayer?: string;
    /**
     * Unlayer template id
     */
    unlayer_template?: number;
    /**
     * Recipients
     */
    recipients?: Array<string>;
    /**
     * Content of email
     */
    content?: string;
    /**
     * Timestamp to send email
     */
    send?: number;
};

export namespace EmailSendRequest {

    /**
     * Type of message
     */
    export enum type {
        EMAIL = 'email',
    }

    /**
     * BCC or Group
     */
    export enum sent_as {
        GROUP = 'group',
        BCC = 'bcc',
    }


}

