/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TagFolderCreateRequest } from '../models/TagFolderCreateRequest';
import type { TagFolderResource } from '../models/TagFolderResource';
import type { TagFolderResourceWithBreadcrumbs } from '../models/TagFolderResourceWithBreadcrumbs';
import type { TagFolderUpdateRequest } from '../models/TagFolderUpdateRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TagsFoldersService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Retrieve all tag folders.
     * @returns any OK
     * @throws ApiError
     */
    public getTagFoldersCollection({
        filter,
        sort,
    }: {
        /**
         *
         * Filtered fields:
         * | Field | Field Type | Description |
         * |--|--|--|
         * |parent_id|number||
         * |name|text||
         *
         * See **Filters** above for more details about using filters.
         */
        filter?: string,
        /**
         * Sortable fields
         * * name
         * * created_on
         *
         *
         * See **Sorting** above for more details about sorting.
         */
        sort?: string,
    }): CancelablePromise<{
        data: Array<TagFolderResource>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/tags/folders',
            query: {
                'filter': filter,
                'sort': sort,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Create a new tag folder.
     * @returns any Created
     * @throws ApiError
     */
    public createTagFolder({
        requestBody,
    }: {
        /**
         * Tag folder resource to create.
         */
        requestBody: TagFolderCreateRequest,
    }): CancelablePromise<{
        data: TagFolderResource;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/tags/folders',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Retrieve one tag folder.
     * @returns any OK
     * @throws ApiError
     */
    public getTagFolder({
        id,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
    }): CancelablePromise<{
        data: TagFolderResourceWithBreadcrumbs;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/tags/folders/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Delete a tag.
     * @returns void
     * @throws ApiError
     */
    public deleteTagFolder({
        id,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/tags/folders/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Update a tag folder.
     * @returns void
     * @throws ApiError
     */
    public updateTagFolder({
        id,
        requestBody,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
        /**
         * Tag folder resource updates
         */
        requestBody: TagFolderUpdateRequest,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/tags/folders/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

}
