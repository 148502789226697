<script setup lang="ts">
import { computed, ref } from 'vue'
import copyText from 'copy-to-clipboard'
import { TYPE, useToast } from 'vue-toastification'
import { SpButton, SpInput } from '@tithely/sproutkit-vue'
import BaseModal from '@/components/base/BaseModal.vue'
import { useMe } from '@/queries/me'
import ENV from '@/constants/env'
import ResponsiveCenterCol from '@/components/ResponsiveCenterCol.vue'
import BaseModalCloseButton from '@/components/base/BaseModalCloseButton.vue'

const modal = ref<InstanceType<typeof BaseModal>>()

const { data: me } = useMe()
const toast = useToast()

const close = () => {
    modal.value?.close()
}

const referralAvailable = computed(() => Boolean(me.value?.referrer))

const referralUrl = computed(() =>
    referralAvailable.value ? `${ENV.breezeWeb}/invite?referrer=${me.value?.referrer}` : `Not Available`
)

const copy = () => {
    copyText(referralUrl.value)

    toast(`Referral Link Copied to Clipboard`, {
        id: `referral-code`,
        type: TYPE.SUCCESS
    })
}
</script>

<template>
    <BaseModal
        ref="modal"
        size="md"
        class="flex flex-col gap-4 !p-4 text-center">
        <ResponsiveCenterCol>
            <h1 class="whitespace-nowrap text-4xl font-medium">Love Breeze?</h1>

            <template #end>
                <BaseModalCloseButton @close="close" />
            </template>
        </ResponsiveCenterCol>

        <p class="text-base">
            When someone signs up using your referral link, we’ll give them a free month of Breeze and send you a
            <strong>$200 Amazon gift card</strong>.
        </p>

        <SpInput
            :model-value="referralUrl"
            size="xl"
            readonly
            class="w-full" />

        <SpButton
            size="xl"
            :disabled="!referralAvailable"
            class="w-full"
            @click="copy">
            Copy Your Link
        </SpButton>

        <p
            v-if="referralAvailable"
            class="text-base">
            Share your link on:<br />

            <span class="inline-flex gap-2">
                <a
                    :href="`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(referralUrl)}`"
                    target="_blank"
                    title="Share on Facebook"
                    class="hover:underline"
                    >Facebook</a
                >
                |
                <a
                    :href="`https://twitter.com/share?url=${encodeURI(referralUrl)}&text==${encodeURI(
                        'Our church uses @breezechms and we love it! It’s simple to use and web-based. Sign up using this link and your church will receive your first month of Breeze for free!'
                    )}`"
                    target="_blank"
                    title="Share on Twitter"
                    class="hover:underline"
                    >Twitter</a
                >
                |
                <a
                    :href="`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURI(
                        referralUrl
                    )}&source=LinkedIn`"
                    target="_blank"
                    title="Share on LinkedIn"
                    class="hover:underline"
                    >LinkedIn</a
                >
            </span>
        </p>

        <p class="text-sm text-secondary-300">
            <a
                href="https://www.breezechms.com/referral-program-terms-of-service"
                target="_blank"
                class="hover:underline"
                >Terms & Conditions</a
            >
        </p>
    </BaseModal>
</template>
