/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FileResource } from '../models/FileResource';
import type { FileUploadRequest } from '../models/FileUploadRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AttachmentsFilesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get file attachments.
     * @returns any OK
     * @throws ApiError
     */
    public getFiles(): CancelablePromise<{
        data: Array<FileResource>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/file',
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Delete file attachments.
     * @returns void
     * @throws ApiError
     */
    public deleteFiles({
        fileIds,
    }: {
        /**
         * The files to delete
         */
        fileIds: Array<number>,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/file',
            query: {
                'file_ids': fileIds,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Upload a file attachment.
     * @returns any Created
     * @throws ApiError
     */
    public uploadFile({
        requestBody,
    }: {
        /**
         * The file to upload
         */
        requestBody: FileUploadRequest,
    }): CancelablePromise<{
        data: FileResource;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/file/upload',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

}
