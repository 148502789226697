const TIME = {
    Millisecond(multiplier = 1) {
        return multiplier
    },
    Second(multiplier = 1) {
        return this.Millisecond() * 1000 * multiplier
    },
    Minute(multiplier = 1) {
        return this.Second() * 60 * multiplier
    },
    Hour(multiplier = 1) {
        return this.Minute() * 60 * multiplier
    },
    Day(multiplier = 1) {
        return this.Hour() * 24 * multiplier
    },
    Week(multiplier = 1) {
        return this.Day() * 7 * multiplier
    }
}

const TIME_IN_SECONDS = {
    Second(multiplier = 1) {
        return multiplier
    },
    Minute(multiplier = 1) {
        return this.Second() * 60 * multiplier
    },
    Hour(multiplier = 1) {
        return this.Minute() * 60 * multiplier
    },
    Day(multiplier = 1) {
        return this.Hour() * 24 * multiplier
    },
    Week(multiplier = 1) {
        return this.Day() * 7 * multiplier
    }
}

export { TIME, TIME_IN_SECONDS }
