/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Country } from './Country';
import type { OrganizationStatus } from './OrganizationStatus';
import type { Preference } from './Preference';

export type Organization = {
    /**
     * The name of the organization.
     */
    name?: string;
    /**
     * Subdomain used to access the organization's website.
     */
    subdomain?: string;
    country_id?: number;
    language?: Organization.language;
    status?: OrganizationStatus;
    customer_id?: string;
    created_on?: string;
    recentGiving?: boolean;
    country?: Country;
    preferences?: Array<Preference>;
};

export namespace Organization {

    export enum language {
        ENGLISH = 'english',
    }


}

