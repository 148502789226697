/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ServicePlanCreateAsTemplateRequest } from './ServicePlanCreateAsTemplateRequest';
import type { ServicePlanCreateFromTemplateRequest } from './ServicePlanCreateFromTemplateRequest';
import type { ServicePlanCreateRequest } from './ServicePlanCreateRequest';

export type StoreServicePlanRequest = (ServicePlanCreateRequest | ServicePlanCreateFromTemplateRequest | ServicePlanCreateAsTemplateRequest | {
    /**
     * The action to take when creating the service plan.
     */
    action?: StoreServicePlanRequest.action;
});

export namespace StoreServicePlanRequest {

    /**
     * The action to take when creating the service plan.
     */
    export enum action {
        CREATE = 'create',
        CREATE_FROM_TEMPLATE = 'create_from_template',
        CREATE_AS_TEMPLATE = 'create_as_template',
    }


}

