import type { RouteLocationNormalized } from 'vue-router'
import useTithelySSO from '@/composables/sso'

const { redirect } = useTithelySSO()

export default [
    {
        path: `/account/account_settings`,
        component: () => import('@/pages/Blank.vue')
    },
    {
        path: `/r/account/bills-and-payment`,
        component: () => import('@/pages/Blank.vue')
    },
    {
        path: `/account/log`,
        component: () => import('@/pages/Blank.vue')
    },
    {
        path: `/account/api`,
        component: () => import('@/pages/Blank.vue')
    },
    {
        path: `/account/backups`,
        component: () => import('@/pages/Blank.vue')
    },
    {
        path: `/account/factory_reset`,
        component: () => import('@/pages/Blank.vue')
    },
    {
        path: `/account/restore`,
        component: () => import('@/pages/Blank.vue')
    },
    {
        path: `/account/export`,
        component: () => import('@/pages/Blank.vue')
    },
    {
        path: `/account/cancel`,
        component: () => import('@/pages/account/Cancel.vue'),
        meta: {
            isVue: true,
            sideNav: `account`
        }
    },
    {
        path: `/account/tithely`,
        component: () => import('@/pages/account/Tithely.vue'),
        meta: {
            isVue: true,
            sideNav: `account`
        }
    },
    {
        path: `/account/tithely/sso`,
        component: () => undefined,
        beforeEnter: (to: RouteLocationNormalized) => redirect((to.query.redirect as string) ?? '')
    }
]
