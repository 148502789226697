/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RequestUserAccountRequest } from '../models/RequestUserAccountRequest';
import type { UserResource } from '../models/UserResource';
import type { ValidateInviteKeyRequest } from '../models/ValidateInviteKeyRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserInviteService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Creates user from user invite key
     * @returns any OK
     * @throws ApiError
     */
    public createInvitedUser({
        requestBody,
    }: {
        requestBody: {
            subdomain: string;
            key: string;
            username: string;
            password: string;
            password_confirmation: string;
        },
    }): CancelablePromise<{
        data: UserResource;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/invite-create-user',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
            },
        });
    }

    /**
     * Validates the invite key
     * @returns void
     * @throws ApiError
     */
    public validateInviteKey({
        requestBody,
    }: {
        requestBody: ValidateInviteKeyRequest,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/invite-validate',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
            },
        });
    }

    /**
     * Returns the token of the user who began impersonation
     * @returns any OK
     * @throws ApiError
     */
    public leaveImpersonation(): CancelablePromise<{
        data: {
            access_token: string;
        };
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/leaveImpersonation',
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Send out an invite to a user
     * @returns void
     * @throws ApiError
     */
    public requestUserAccount({
        requestBody,
    }: {
        requestBody: RequestUserAccountRequest,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/request-user-account',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
            },
        });
    }

}
