import { Expose } from 'class-transformer'
import type { NotificationResource as Model } from '@/services/api/client'
import type { CamelCaseKeys } from '@/types/utility'

export type SerializedNotificationResource = Model

export default class NotificationResource implements CamelCaseKeys<SerializedNotificationResource> {
    id!: Model['id']

    description!: Model['description']

    @Expose({ name: 'run_weekday' })
    runWeekday!: Model['run_weekday']

    @Expose({ name: 'run_day' })
    runDay!: Model['run_day']

    checked!: Model['checked']

    @Expose({ name: 'has_permission' })
    hasPermission!: Model['has_permission']

    @Expose({ name: 'permission_description' })
    permissionDescription!: Model['permission_description']

    @Expose({ name: 'anniversary_field_id' })
    anniversaryFieldId: Model['anniversary_field_id']

    @Expose({ name: 'anniversary_fields' })
    anniversaryFields: Model['anniversary_fields']

    constructor(values: NotificationResource) {
        Object.assign(this, values)
    }
}
