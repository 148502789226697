export default {
    authorizeButton: `Authorize`,
    cancelButton: `Cancel`,
    authorizeHeader: `Authorize Application`,
    authorizeAsUser: `Do you authorize this application to access Breeze as you?`,
    authorizeAsOrg: `Do you authorize this application to access the following data?`,
    access: `Access to {name}`,
    notUser: `Not {first} {last}?`,
    loginAsAnotherUser: `Login as another user`,
    appWarning: `If the application name above does not match the application you came from, do not click authorize and contact support.`,
    names: {
        people: 'People',
        groups: 'Groups',
        tags: 'Tags',
        events: 'Events',
        checkIn: 'Check-In',
        giving: 'Giving',
        followups: 'Follow-Ups',
        forms: 'Forms',
        account: `Account Settings`
    },
    abilities: {
        main: `Basic Access`, // Default when no other permissions are set
        read: `Read`,
        write: `Write`,
        pledgeWrite: `Pledge Write`,
        pledgeRead: `Pledge Read`,
        settings: `Settings`,
        usersRoles: `Users/Roles`,
        history: `History`,
        volunteer: `Volunteer`,
        checkIn: `Check-In`,
        servicePlanRead: `Service Plan Read`,
        servicePlanWrite: `Service Plan Write`,
        assign: `Assign`,
        profileField: `Profile Field`
    },
    list: {
        one: `{permission} Permission`,
        many: `{first} and {last} Permissions`
    }
}
