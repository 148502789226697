import tithelySitesIcon from '@/assets/images/extensions/tithelySites.svg'
import churchAppsIcon from '@/assets/images/extensions/churchApps.svg'
import PCOIcon from '@/assets/images/extensions/pco.svg'
import quickBooksIcon from '@/assets/images/extensions/quickBooks.svg'
import servicePlan from '@/assets/images/extensions/servicePlan.svg'
import mailchimpIcon from '@/assets/images/extensions/mailchimp.svg'
import songSelect from '@/assets/images/extensions/songSelect.svg'
import songSelectFull from '@/assets/images/extensions/SongSelectFull.svg'
import breezeIcon from '@/assets/images/extensions/breeze.svg'
import noteBirdIcon from '@/assets/images/extensions/notebird.png'
import churchAppsImage from '@/assets/images/extensions/bundle/churchApps.png'
import sitesImage from '@/assets/images/extensions/bundle/sites.png'
import songSelectImage from '@/assets/images/extensions/bundle/songSelect.png'
import songLibraryImage from '@/assets/images/extensions/bundle/songLibrary.png'
import type { Nullable } from '@/types/utility'

interface IntegrationBranding {
    color: string
    icon: Nullable<string>
}

export type IntegrationName =
    | `breeze`
    | `sites_integration`
    | `church_apps_integration`
    | `service_plan_integration`
    | `quickbooks_integration`
    | `mailchimp_integration`
    | `songselect_integration`
    | `pco_integration`
    | `notebird_integration`
    | `default`

interface IntegrationAction {
    icon?: 'PhCalendarCheck'
    title: string
    text: string
    button: string
    subtext?: string
    actionLabel?: string
    action?: string
    type?: string
}

interface IntegrationContent {
    title?: string
    altTitle?: string
    body?: string[]
    altConfirm?: string
    subtext?: string
    confirm?: string
    cancel?: string
    icon?: string
    buttonText?: string
    completeAction?: IntegrationAction
    existsAction?: IntegrationAction
    subText?: string
}

interface IntegrationModals {
    start?: IntegrationContent
    success?: IntegrationContent
    exists?: IntegrationContent
    confirm?: IntegrationContent
    eula?: IntegrationContent
    preinstall?: IntegrationContent
    followUpExtension?: IntegrationName
}

interface BundleConfirmed {
    displayName: string
    dropdownTitle: string
    description: string
    image: Nullable<string>
}

const integrationDetailsMap: Partial<Record<IntegrationName, string>> = {
    sites_integration: 'No thanks, I just need a Site',
    church_apps_integration: 'No thanks, I just need an App',
    service_plan_integration: 'No thanks, I just need Planning'
}

const integrationStyleMap: Record<IntegrationName, IntegrationBranding> = {
    sites_integration: {
        color: `bg-sky-500`,
        icon: tithelySitesIcon
    },
    church_apps_integration: {
        color: `bg-blue-700`,
        icon: churchAppsIcon
    },
    service_plan_integration: {
        color: `bg-blue-700`,
        icon: servicePlan
    },
    pco_integration: {
        color: `bg-blue-600`,
        icon: PCOIcon
    },
    quickbooks_integration: {
        color: `bg-[#249014]`,
        icon: quickBooksIcon
    },
    mailchimp_integration: {
        color: `bg-[#FFE01B]`,
        icon: mailchimpIcon
    },
    songselect_integration: {
        color: `bg-white`,
        icon: songSelect
    },
    breeze: {
        color: `bg-[#2E7D32]`,
        icon: breezeIcon
    },
    notebird_integration: {
        color: `bg-blue-600`,
        icon: noteBirdIcon
    },
    default: {
        color: `bg-sky-500`,
        icon: null
    }
}

const integrationBundleMap: Partial<Record<IntegrationName, BundleConfirmed>> = {
    sites_integration: {
        displayName: 'Let’s build my site',
        dropdownTitle: 'Sites Extension...',
        description: 'You can now access your site builder from the main navigation menu. ',
        image: sitesImage
    },
    church_apps_integration: {
        displayName: 'Let’s build my App',
        dropdownTitle: 'Let’s build my App',
        description: 'You can now access your app builder from the main navigation menu.',
        image: churchAppsImage
    },
    service_plan_integration: {
        displayName: 'Let’s add songs to my library',
        dropdownTitle: 'Set up my Service Plan...',
        description:
            'You can now access your Song Library from the manage account gear icon in the upper right side of the main menu',
        image: songLibraryImage
    },
    songselect_integration: {
        displayName: 'Let’s set up SongSelect ©',
        dropdownTitle: 'Enabling SongSelect...',
        description:
            'To enable the SongSelect Integration, you can do that now or it can be done at any time from the extensions page',
        image: songSelectImage
    }
}

const integrationWizardMap: Partial<Record<IntegrationName, IntegrationModals>> = {
    songselect_integration: {
        start: {
            body: [
                'SongSelect is the definitive source for discovering lyrics, transposable sheet music, audio samples and so much more.',
                'By linking your SongSelect account with Breeze, you are able to import music directly from SongSelect into your Song Library and onto your Service Plans.'
            ],
            confirm: 'Link',
            cancel: 'Cancel',
            icon: songSelectFull
        },
        success: {
            title: 'Success! Your SongSelect® account has been connected!',
            body: ['You can now easily add music to your Song Library and Service Plans'],
            confirm: 'Got it!'
        },
        confirm: {
            body: [
                'SongSelect is the definitive source for discovering lyrics, transposable sheet music, audio samples and so much more. By linking your SongSelect account with Breeze, you are able to import music directly from SongSelect into your Song Library and onto your Service Plans. You can also ensure you are accurately tracking usage for licensing purposes by checking the “Enable Auto Reporting” box below.'
            ],
            confirm: 'Link'
        },
        eula: {
            title: 'SongSelect® End User License Agreement',
            body: [
                'All Content and the Application are provided solely and exclusively for End Users who are active subscribers to SongSelect;',
                'All Content is the intellectual property of a third party, is protected by law, and no ownership, distribution rights, or other proprietary rights in the Content are transferred to the Partner and/or any End User.',
                'CCLI and/or Content Owners may remove Content from the API from time-to-time without notice;',
                'End Users must agree to comply with all SongSelect license terms and all applicable laws, including copyright law in their use of the Content and shall protect CCLI’s and the Content Owners’ rights;',
                'The usage rights granted to End User are nonexclusive, are subject to revocation or termination at any time, and may not be transferred, assigned, sublicensed, distributed, or conveyed in any manner whatsoever.',
                'CCLI and the Content Owners reserve the right to modify the provisions contained in the EULA at any time.',
                'NEITHER CCLI NOR CONTENT OWNERS WILL BE LIABLE TO END USER FOR ANY INDIRECT, PUNITIVE, INCIDENTAL, CONSEQUENTIAL, OR SPECIAL DAMAGES OF ANY KIND RESULTING FROM THE USE OF THE SONGSELECT, THE SONGSELECT API, OR FROM CONTENT MADE AVAILABLE THROUGH THE SONGSELECT API, OR FOR ANY OTHER DAMAGES OF ANY TYPE, NATURE OR DESCRIPTION ARISING OUT OF ANY LEGAL THEORY, AND END USER HEREBY FOREVER RELEASES AND DISCHARGES CCLI AND CONTENT OWNERS FROM ANY AND ALL LIABILITY RELATED TO END USER’S USE OF OR ACCESS TO SONGSELECT, THE SONGSELECT API, OR CONTENT MADE AVAILABLE THROUGH THE SONGSELECT API.',
                'BREEZE AND CCLI DISCLAIM ANY AND ALL EXPRESS OR IMPLIED WARRANTIES, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE (BREEZE WILL DISPLAY THESE WARRANTY DISCLAIMERS AND EXCLUSIONS IN BOLD, CAPITALIZED TYPE IN COMPLIANCE WITH LAWS WHICH REQUIRE SUCH DISCLAIMERS BE CONSPICUOUS).'
            ],
            confirm: 'I agree, continue',
            cancel: 'Cancel'
        }
    },
    sites_integration: {
        exists: {
            title: 'Looks like you already have a subscription for Tithely Sites.',
            confirm: 'Great! Take me to my Site!'
        },
        success: {
            body: [
                'Your payment was complete. You can install Tithely Sites',
                'and it will automatically connect to Breeze'
            ]
        }
    },
    church_apps_integration: {
        exists: {
            title: 'Looks like you already have a subscription for Church Apps.',
            confirm: 'Great! Take me to my App!'
        },
        start: {
            body: ["It's not required, but it can make setting up your App easier!"]
        },
        success: {
            body: [
                'Success! Church Apps is now connected to Breeze! You can also link your Breeze Event calendars to show events in the app.'
            ],
            completeAction: {
                icon: 'PhCalendarCheck',
                title: 'Link Breeze Calendar',
                text: 'Success! Church Apps is now connected to Breeze! You can also link your Breeze Event calendars to show events in the app.',
                subtext: 'To link other calendar events, navigate to the specific calendar and edit the preferences.',
                type: 'checkbox',
                actionLabel: 'Show Main Calendar in App',
                action: 'showMainCalendar',
                button: 'Save and take me to Church Apps'
            },
            existsAction: {
                icon: 'PhCalendarCheck',
                title: 'Link Breeze Calendar',
                text: 'Success! Church Apps is now connected to Breeze! You can also link your Breeze Event calendars to show events in the app.',
                subtext: 'To link other calendar events, navigate to the specific calendar and edit the preferences.',
                type: 'checkbox',
                actionLabel: 'Show Main Calendar in App',
                action: 'showMainCalendar',
                button: 'Save and take me to Church Apps'
            }
        }
    },
    service_plan_integration: {
        exists: {
            title: 'Looks like you already have a subscription for Service Planning.',
            altTitle:
                'Looks like you already have a subscription to Service Planning. Would you like to enable SongSelect?',
            altConfirm: 'Yes, enable',
            confirm: 'Great! Take me to the Song Library!',
            cancel: 'Skip for now'
        },
        start: {
            body: ["It's not required, but it can make setting up your service plan easier!"]
        },
        success: {
            body: [
                'You can now access the Song Library, download the Worship App and enable the SongSelect® Integration.'
            ],
            subtext: 'Would you like to connect your SongSelect account now?',
            confirm: 'Enable SongSelect®',
            cancel: 'Skip this for now'
        },
        followUpExtension: 'songselect_integration'
    }
}

export { integrationStyleMap, integrationWizardMap, integrationDetailsMap, integrationBundleMap }
