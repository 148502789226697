/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SongVersionResource } from '../models/SongVersionResource';
import type { StoreSongVersionRequest } from '../models/StoreSongVersionRequest';
import type { UpdateSongVersionRequest } from '../models/UpdateSongVersionRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SongLibrarySongsVersionsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Store a new song version.
     * @returns any Created
     * @throws ApiError
     */
    public createSongVersion({
        songId,
        requestBody,
    }: {
        /**
         * ID of the song.
         */
        songId: number,
        /**
         * Song version details
         */
        requestBody: StoreSongVersionRequest,
    }): CancelablePromise<{
        data: SongVersionResource;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/song-library/songs/{songId}/versions',
            path: {
                'songId': songId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Display a specific song version.
     * @returns any OK
     * @throws ApiError
     */
    public getSongVersion({
        songId,
        id,
    }: {
        /**
         * ID of the song.
         */
        songId: number,
        /**
         * ID of the retrieved resource.
         */
        id: number,
    }): CancelablePromise<{
        data: SongVersionResource;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/song-library/songs/{songId}/versions/{id}',
            path: {
                'songId': songId,
                'id': id,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Remove a specific song version.
     * @returns void
     * @throws ApiError
     */
    public deleteSongVersion({
        songId,
        id,
    }: {
        /**
         * ID of the song.
         */
        songId: number,
        /**
         * ID of the retrieved resource.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/song-library/songs/{songId}/versions/{id}',
            path: {
                'songId': songId,
                'id': id,
            },
            errors: {
                400: `Cannot delete the only song version, please delete the song instead.`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Update a specific song version.
     * @returns any OK
     * @throws ApiError
     */
    public updateSongVersion({
        songId,
        id,
        requestBody,
    }: {
        /**
         * ID of the song.
         */
        songId: number,
        /**
         * ID of the retrieved resource.
         */
        id: number,
        /**
         * Song version details
         */
        requestBody: UpdateSongVersionRequest,
    }): CancelablePromise<{
        data: SongVersionResource;
    }> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/song-library/songs/{songId}/versions/{id}',
            path: {
                'songId': songId,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public detachSongVersionFiles({
        songId,
        id,
        attachmentId,
    }: {
        /**
         * ID of the song.
         */
        songId: number,
        /**
         * ID of the retrieved resource.
         */
        id: number,
        /**
         * ID of the attachment.
         */
        attachmentId: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/song-library/songs/{songId}/versions/{id}/attachments/{attachmentId}',
            path: {
                'songId': songId,
                'id': id,
                'attachmentId': attachmentId,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

}
