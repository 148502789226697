export default {
    noChurch: {
        header: `Wrong Address`,
        mainText: `There is no church at this web address. Please check the web address you typed to ensure it was typed correctly.`,
        ifNeedHelp: `If you need help, feel free to`,
        contactSupport: `contact the Breeze support team`,
        goHome: `go to our home page`
    },
    pageNotFound: {
        mainText: `Hmmmm... we're not sure where you're at as we couldn't find a page at this web address. Check to ensure the address is correct and try again.`,
        goBack: `Go Back`,
        goHome: `Go to the Breeze Homepage`
    },
    oAuth: {
        token_not_found: {
            header: `Token Not Found`,
            mainText: `The token required to authenticate your request is either expired or invalid. Please try again.`
        },
        workflow_missing: {
            header: `Workflow Missing`,
            mainText: `The workflow required to authenticate your request was not found. Please try again.`
        },
        default: {
            header: `Error`,
            mainText: `There was an error with your request. Please try again.`
        }
    },
    disabledChurch: {
        churchDisabled: `This church has been disabled.`,
        notActive: `Sorry, this account is no longer active.`,
        contact: `Please contact us if you would like to reactivate your account.`,
        goHome: `Go to Breeze Home`
    },
    oAuthAuthorize: {
        invalidRequest: `Invalid Request`,
        tryAgain: `Please go back and try again.`,
        appNotFound: `Application not found`,
        unknown: `Unknown Error`,
        noUserInfo: `Unable to get user info. Please try again later`,
        error: `Error`,
        contactDev: `Please go back and try again or contact the application developer.`
    },
    generic: {
        text: `An error occurred.`
    }
}
