<script setup lang="ts">
import type { QueryStatus } from '@tanstack/vue-query'
import { toRaw, watch } from 'vue'
import { isArray } from 'lodash'
import { useMe } from '@/queries/me'
import ENV from '@/constants/env'

const { data: currentUserProfile, status } = useMe()

// Sync Current User Profile with GTM
if (ENV.googleTagManager) {
    const updateDataLayer = () => {
        if (isArray(window.dataLayer) && currentUserProfile.value) {
            window.dataLayer.push(toRaw(currentUserProfile.value))
        }
    }

    updateDataLayer()

    watch<QueryStatus>(status, value => {
        if (value === 'success') {
            updateDataLayer()
        }
    })
}
</script>

<template>
    <div />
</template>
