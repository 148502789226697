/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TeamMemberResource } from '../models/TeamMemberResource';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SongLibraryTeamService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Returns a collection of Users that have at least one of the $accessPermissions
     * @returns any OK
     * @throws ApiError
     */
    public getSongLibraryTeam(): CancelablePromise<{
        data: TeamMemberResource;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/song-library/team',
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

}
