/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TextingService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getTextingLimit(): CancelablePromise<{
        data: number;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/texts/limit',
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getTextPricing(): CancelablePromise<{
        data: number;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/texts/price',
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getMonthlyTextsSent(): CancelablePromise<{
        data: number;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/texts/sent',
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

}
