import { defineStore } from 'pinia'
import { ref } from 'vue'
import { get, map } from 'lodash'
import { useQueryClient } from '@tanstack/vue-query'
import { useCookies } from '@vueuse/integrations/useCookies'
import useApi from '@/composables/api'
import type { Nullable } from '@/types/utility'
import type { User } from '@/types/support'
import { COOKIE_OPTIONS } from '@/constants/cookies'
import { QUERY_KEYS } from '@/constants/query'

const useSupport = defineStore(`support`, () => {
    const cookies = useCookies()
    const api = useApi()
    const queryClient = useQueryClient()

    const supportUserCookie = cookies.get(`support_user_r`)
    const supportUser = ref<Nullable<string>>(supportUserCookie ? atob(supportUserCookie) : null)
    const users = ref<User[]>([])

    const updateAccessToken = (response: any) => {
        const accessToken = get(response, `data.access_token`)
        cookies.set(`access_token`, accessToken, COOKIE_OPTIONS)
    }

    const impersonate = (id: string) => {
        if (id !== supportUser.value) {
            supportUser.value = id
            cookies.set(`support_user_r`, btoa(id), COOKIE_OPTIONS)
        }

        api.call(`GET`, `/users/leaveImpersonation`)
            .then(response => {
                updateAccessToken(response)

                return api.call(`GET`, `/users/impersonate/${id}`)
            })
            .then(response => {
                updateAccessToken(response)

                users.value = map(get(response, `data.users`, []), (user: any) => ({
                    id: user.id,
                    firstName: user.first_name,
                    lastName: user.last_name,
                    username: user.username
                }))
            })
            .finally(() => queryClient.invalidateQueries({ queryKey: QUERY_KEYS.me }))
    }

    if (supportUser.value) {
        impersonate(supportUser.value)
    }

    return {
        supportUser,
        users,
        impersonate
    }
})

export default useSupport
