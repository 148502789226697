/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Phone number metadata.
 */
export type ProfilePhoneNumber = {
    /**
     * The phone number.
     */
    value: string;
    /**
     * The phone number type.
     */
    type: ProfilePhoneNumber.type;
    do_not_text?: boolean;
    is_private?: boolean;
};

export namespace ProfilePhoneNumber {

    /**
     * The phone number type.
     */
    export enum type {
        HOME = 'home',
        WORK = 'work',
        MOBILE = 'mobile',
    }


}

