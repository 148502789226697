export default {
    addSong: `Add song`,
    addItem: `Add item`,
    noSongsAdded: `You haven't added any songs yet. Click "Add Song" to get started!`,
    noSongsFound: `No songs matching "%{searchValue}" in your library.`,
    segments: {
        general: `General`,
        note: `Note`,
        scripture: `Scripture`,
        section: `Section`,
        song: `Song`
    },
    addSongSearchModal: {
        addManually: `Add Manually`,
        searchNoSongFound: `Uh oh! Looks like we don’t have any results for that title in SongSelect® or your library.`,
        manuallyCreated: `Manually Created`,
        ccliNumberNum: 'CCLI® Number %{number}',
        inLibrary: 'In library ✓',
        placeholder: `Search CCLI® #, artist or song name`
    },
    songPage: {
        addSong: `Add Song`,
        bulkUpload: `Bulk Upload`,
        clearSearch: `Clear Search`,
        generalUploadFile: `General Upload File`,
        planningCenterExport: `From Planning Center Export`
    },
    bulkImportModal: {
        importFromGeneral: `Import from General Upload File`,
        importFromPlanning: `Import from Planning Center Export`,
        importFromGeneralSubtitle: `Upload a CSV in the exact format provided in the`,
        importFromGeneralLink: `General Import Template`,
        importFromPlanningSubtitle: `Upload your Planning Center song library export directly into Breeze `,
        importOverrideExisting: `Override any existing duplicate songs (note: there can only be one copy of a CCLI® song)`,
        pullAttachmentsCCLI: `Pull any available song details and attachments from CCLI®?`
    },
    bulkImportConfirmModal: {
        uploadSuccessful: `Upload successful, import underway!`,
        uploadSuccessfulDescription: `Your Song Library import file has been successful. Songs will now be imported to Breeze including details from SongSelect® if applicable. Please note this process may take awhile to complete.`,
        done: `Done`
    }
}
