/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FileResource } from '../models/FileResource';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SongLibrarySongsVersionsSongSelectImportService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create Chord Sheet for a Song Select Song on demand
     * @returns any OK
     * @throws ApiError
     */
    public getSongVersionCapoSheet({
        songId,
        id,
        style,
        columns,
        attach,
        capoKey,
    }: {
        /**
         * ID of the song.
         */
        songId: number,
        /**
         * ID of the retrieved resource.
         */
        id: number,
        /**
         * Chart style as "Standard", "Number" "DoReMiFixed", or "DoReMiMovable"
         */
        style?: 'Standard' | 'Number' | 'DoReMiFixed' | 'DoReMiMovable',
        /**
         * Number of columns = 1 or 2. Default is 1.
         */
        columns?: 1 | 2,
        /**
         * Attach the chord sheet file to the version.
         */
        attach?: boolean,
        /**
         * Capo key.
         */
        capoKey?: string,
    }): CancelablePromise<{
        data: FileResource;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/song-library/songs/{songId}/versions/{id}/capo',
            path: {
                'songId': songId,
                'id': id,
            },
            query: {
                'style': style,
                'columns': columns,
                'attach': attach,
                'capo-key': capoKey,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Create ChordPro for a Song Select Song on demand
     * @returns any OK
     * @throws ApiError
     */
    public getSongVersionChordPro({
        songId,
        id,
        style,
        attach,
    }: {
        /**
         * ID of the song.
         */
        songId: number,
        /**
         * ID of the retrieved resource.
         */
        id: number,
        /**
         * Chart style as "Standard", "Number" "DoReMiFixed", or "DoReMiMovable"
         */
        style?: 'Standard' | 'Number' | 'DoReMiFixed' | 'DoReMiMovable',
        /**
         * Attach the ChordPro file to the version.
         */
        attach?: boolean,
    }): CancelablePromise<{
        data: FileResource;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/song-library/songs/{songId}/versions/{id}/chord-pro',
            path: {
                'songId': songId,
                'id': id,
            },
            query: {
                'style': style,
                'attach': attach,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Create Chord Sheet for a Song Select Song on demand
     * @returns any OK
     * @throws ApiError
     */
    public getSongVersionChordSheet({
        songId,
        id,
        style,
        columns,
        attach,
    }: {
        /**
         * ID of the song.
         */
        songId: number,
        /**
         * ID of the retrieved resource.
         */
        id: number,
        /**
         * Chart style as "Standard", "Number" "DoReMiFixed", or "DoReMiMovable"
         */
        style?: 'Standard' | 'Number' | 'DoReMiFixed' | 'DoReMiMovable',
        /**
         * Number of columns = 1 or 2. Default is 1.
         */
        columns?: 1 | 2,
        /**
         * Attach the chord sheet file to the version.
         */
        attach?: boolean,
    }): CancelablePromise<{
        data: FileResource;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/song-library/songs/{songId}/versions/{id}/chord-sheet',
            path: {
                'songId': songId,
                'id': id,
            },
            query: {
                'style': style,
                'columns': columns,
                'attach': attach,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Create Lyrics for a Song Select Song on demand
     * @returns any OK
     * @throws ApiError
     */
    public getSongVersionLyrics({
        songId,
        id,
        attach,
    }: {
        /**
         * ID of the song.
         */
        songId: number,
        /**
         * ID of the retrieved resource.
         */
        id: number,
        /**
         * Attach the lyrics file to the version.
         */
        attach?: boolean,
    }): CancelablePromise<{
        data: FileResource;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/song-library/songs/{songId}/versions/{id}/lyrics',
            path: {
                'songId': songId,
                'id': id,
            },
            query: {
                'attach': attach,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

}
