<script lang="ts" setup>
import { type PropType } from 'vue'
import BIcon from '@/components/BIcon.vue'
import type { SideNavItem } from '@/types/sideNav'

const props = defineProps({
    nav: {
        type: Array as PropType<SideNavItem[]>
    }
})

const current = (itemRoute: string) => itemRoute === window.location.pathname

const listClasses = (itemRoute: string) => {
    const classList = [`relative`]

    if (current(itemRoute)) {
        classList.push(
            `before:absolute`,
            `before:top-0`,
            `before:left-0`,
            `before:h-full`,
            `before:w-1.5`,
            `before:bg-primary`,
            `before:rounded-r-[20px]`
        )
    }

    return classList
}

const linkClasses = (itemRoute: string) => {
    const classList = [
        `flex`,
        `items-center`,
        `gap-4`,
        `h-[38px]`,
        `!px-[22px]`,
        `text-primary`,
        `font-bold`,
        `transition-opacity`
    ]

    if (current(itemRoute)) {
        classList.push(`pointer-events-none`)
    } else {
        classList.push(`opacity-40`, `hover:opacity-100`)
    }

    return classList
}
</script>

<template>
    <div class="relative hidden shrink-0 grow-0 bg-secondary-50 !pt-[30px] lg:block lg:w-[260px] lg:print:hidden">
        <ul class="flex flex-col gap-4">
            <li
                v-for="item in props.nav"
                :key="item.route"
                :class="listClasses(item.route)">
                <a
                    :href="item.route"
                    :class="linkClasses(item.route)">
                    <BIcon
                        :icon="item.icon"
                        class="h-6 w-6" />

                    {{ item.label }}
                </a>
            </li>
        </ul>
    </div>
</template>
