<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue'
import { each, slice } from 'lodash'
import type { Sizes } from '@/types/theme'

const code = ref<string[]>([])
const digitRefs = ref<Array<HTMLInputElement | null>>([])

const emit = defineEmits<{
    complete: [string]
    submit: [void]
}>()

interface Props {
    size?: Sizes
    disabled?: boolean
    maxDigits?: number
}

const props = withDefaults(defineProps<Props>(), {
    size: `md`,
    disabled: false,
    maxDigits: 6
})

const classes = computed(() => {
    let size = [`w-24`, `h-24`]
    let fontSize = [`text-8xl`]

    if (props.size === `md`) {
        size = [`w-16`, `h-16`]
        fontSize = [`text-4xl`]
    }

    return [
        ...size,
        ...fontSize,
        `outline-0`,
        `border`,
        `border-form-border`,
        `text-center`,
        `rounded-xl`,
        `font-medium`,
        `drop-shadow-sm`
    ]
})

onMounted(() => {
    digitRefs.value[0]?.focus()
})

const clearDigit = (digit: number) => {
    const newCode = [...code.value]
    newCode[digit - 1] = ``
    code.value = newCode
}

const digitChanged = (digit: number, event: Event) => {
    const inputEvent = event as InputEvent
    const target: HTMLInputElement = event.target as HTMLInputElement
    const newCode = [...code.value]
    const pasted: string[] = target?.value.split(``)
    let element = event.target as HTMLInputElement

    switch (inputEvent.inputType) {
        case `insertText`:
            newCode[digit - 1] = inputEvent.data ?? ''
            if (target?.nextElementSibling) {
                ;(target?.nextElementSibling as HTMLInputElement).focus()
            } else {
                emit(`complete`, newCode.join(``))
            }
            break

        case `deleteContentBackward`:
            if (digit > 0) {
                newCode[digit] = ``
            }
            break

        case `insertFromPaste`:
            each(slice(pasted, 0, props.maxDigits), (d, index: number) => {
                newCode[index] = d

                if (index >= digit - 1) {
                    element = element.nextElementSibling as HTMLInputElement
                }
            })

            if (element) {
                element.focus()
            } else {
                emit(`complete`, newCode.join(``))
            }
            break
    }

    code.value = newCode
}

const keyDown = (event: KeyboardEvent) => {
    if (event.key === `Backspace` || event.key === `Delete`) {
        code.value = []
        digitRefs.value[0]?.focus()
    }
}

const clear = () => {
    code.value = []
    digitRefs.value[0]?.focus()
}

const submit = () => {
    if (code.value.length < props.maxDigits) {
        return
    }

    emit(`complete`, [...code.value].join(``))
    emit(`submit`)
}

defineExpose({
    clear
})
</script>

<template>
    <div class="flex flex-shrink justify-center gap-2">
        <input
            v-for="digit in props.maxDigits"
            :key="digit"
            :ref="el => (digitRefs[digit - 1] = el as HTMLInputElement)"
            :class="classes"
            :value="code[digit - 1]"
            :disabled="disabled"
            @keydown="keyDown($event)"
            @focus="clearDigit(digit)"
            @keyup.enter="submit"
            @input="digitChanged(digit, $event)" />
    </div>
</template>
