export default [
    {
        path: `/extensions`,
        component: () => import('@/pages/extensions/index.vue'),
        meta: {
            isVue: true
        }
    },
    {
        path: `/extensions/search`,
        redirect: `/extensions`
    },
    {
        path: `/extensions/installed`,
        redirect: `/extensions`
    }
]
