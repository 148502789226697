/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiApplicationInfoResource } from '../models/ApiApplicationInfoResource';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ApiApplicationsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get info about requested application.
     * @returns any OK
     * @throws ApiError
     */
    public getApiApplicationInfo({
        uuid,
    }: {
        /**
         * UUID of the retrieved resource.
         */
        uuid: string,
    }): CancelablePromise<{
        data: ApiApplicationInfoResource;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api-auth/applications/{uuid}',
            path: {
                'uuid': uuid,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

}
