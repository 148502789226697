<script setup lang="ts">
import InlineSvg from 'vue-inline-svg'
import logo from '@/assets/images/logo.svg'
import useLoginConfig from '@/queries/login'
import { watch } from 'vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import { useRouter } from 'vue-router'
import { isPublicSubdomain } from '@/functions/utils'
import { ApiError } from '@/services/api/client'

const router = useRouter()
const { data: loginConfig, isPending, error } = useLoginConfig()

watch(error, newError => {
    if (newError instanceof ApiError && newError?.status === 404 && !loginConfig?.value?.cancelled) {
        router.push(isPublicSubdomain() ? `errors/404?path=${router.currentRoute.value.fullPath}` : `/errors/subdomain`)
    }
})

if (loginConfig?.value?.cancelled) {
    window.open(`/disabled`, `_self`)
}
</script>

<template>
    <div class="absolute inset-0 flex flex-col items-center justify-center gap-2 bg-[#eee] p-4">
        <img
            v-if="loginConfig?.logo"
            :src="'/' + loginConfig?.logo"
            class="h-28" />

        <InlineSvg
            v-else
            :src="logo"
            class="h-16 text-neutral-300" />

        <LoadingSpinner
            class="max-h-8"
            v-if="isPending" />

        <RouterView v-else />
    </div>
</template>
