import { Expose } from 'class-transformer'
import type { IntegrationExtendedResource as Model } from '@/services/api/client'
import type { CamelCaseKeys } from '@/types/utility'

export type SerializedIntegrationExtendedResource = Model

export default class IntegrationExtendedResource implements CamelCaseKeys<SerializedIntegrationExtendedResource> {
    id!: Model['id']

    name!: Model['name']

    @Expose({ name: 'display_name' })
    displayName!: Model['display_name']

    description!: Model['description']

    enabled!: Model['enabled']

    @Expose({ name: 'builder_io_id' })
    builderIoId!: Model['builder_io_id']

    tithely!: Model['tithely']

    monthlyFee: Model['monthlyFee']

    setupFee: Model['setupFee']

    setupFeeWaived: Model['setupFeeWaived']

    currencyCode: Model['currencyCode']

    constructor(values: IntegrationExtendedResource) {
        Object.assign(this, values)
    }
}
