/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type MessageTemplateRequestBase = {
    /**
     * The name of the message template.
     */
    name?: string;
    /**
     * Content of the message template.
     */
    content?: string;
    /**
     * Type of the message template, ie unlayer, sms, html.
     */
    type?: MessageTemplateRequestBase.type;
    /**
     * Subject of the message template.
     */
    subject?: string;
    /**
     * Tags of the message template.
     */
    tags?: Array<string>;
};

export namespace MessageTemplateRequestBase {

    /**
     * Type of the message template, ie unlayer, sms, html.
     */
    export enum type {
        SMS = 'sms',
        UNLAYER = 'unlayer',
        HTML = 'html',
        TEXT = 'text',
    }


}

