import type { TithelySubscriptionPaymentMethod as Model } from '@/services/api/client'
import type { CamelCaseKeys } from '@/types/utility'

export type SerializedTithelySubscriptionPaymentMethod = Model

export default class TithelySubscriptionPaymentMethod
    implements CamelCaseKeys<SerializedTithelySubscriptionPaymentMethod>
{
    id!: Model['id']

    brand!: Model['brand']

    digits!: Model['digits']

    expiry!: Model['expiry']

    constructor(values: TithelySubscriptionPaymentMethod) {
        Object.assign(this, values)
    }
}
